import { Timestamp, addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

// Helper function to generate the summary
const generateSummary = (
  initiatorName,
  receiverName,
  interactionType,
  madeContact
) => {
  // Format the interaction types into natural language
  const interactionSummary = formatInteractionTypes(interactionType);

  // Use ternary operator to determine whether contact was made
  return `${initiatorName} ${interactionSummary} ${receiverName} ${
    madeContact ? "and had a conversation." : "with no response."
  }`;
};

// Helper function to convert interaction types into natural language
const formatInteractionTypes = (interactionType) => {
  const formattedTypes = interactionType.map((type) => {
    switch (type) {
      case "call":
        return "called";
      case "text":
        return "texted";
      case "email":
        return "emailed";
      case "voicemail":
        return "left a voicemail";
      case "in-person":
        return "was in-person with";
      case "automated email":
        return "sent an automated email to";
      case "automated text":
        return "sent an automated text to";
      default:
        return type;
    }
  });

  // Join the interaction types into a natural language sentence
  if (formattedTypes.length === 0) {
    return ""; // Return an empty string if no types are present
  } else if (formattedTypes.length === 1) {
    return formattedTypes[0]; // Return the single item if there's only one
  } else if (formattedTypes.length === 2) {
    return formattedTypes.join(" and "); // Join with "and" for exactly two items
  } else {
    // For three or more items
    return (
      formattedTypes.slice(0, -1).join(", ") +
      ", and " +
      formattedTypes[formattedTypes.length - 1]
    );
  }
};

// Function to create a communication log
export const createCommunicationLog = async ({
  madeContact, // bool TRUE or FALSE
  collectionName, // e.g., "members" or "leads"
  idsArr, // Array of related IDs, e.g., ["memberId456", "employee123", "visit789"]
  description, // Brief summary of the communication
  interactionType, // ["Call", "Email", "Text", ...]
  initiator, // { type: "employee" || "member" || "system", id: "employee123", displayName: "Alex R.", avatarUrl: "" }
  receiver, // { type: "employee" || "member" || "system", id: "member123", displayName: "John Doe", avatarUrl: "" }
  tags = [], // Optional tags for the log (e.g., ["mailbox_full", "call_failed"])
  sentiment = 3, // Default sentiment rating is neutral
  interest = 3, // Default interest rating is neutral
  priority = 1, // Default priority level
  flags = [], // Optional flag, e.g., ["payment", "urgent"]
  location = {}, // Optional location field, default to empty object
  communicationDate,
  followUpDate,
}) => {
  try {
    // Generate the summary based on the initiator, receiver, and interaction
    const summary = generateSummary(
      initiator.displayName,
      receiver.displayName,
      interactionType,
      madeContact
    );

    // Core log structure
    const log = {
      // Identifiers
      collection: collectionName, // "members" or "leads"
      idsArr, // Array of related IDs
      accessLevel: "private", // "public", "private", or "admin"
      logType: "communication", // Type is "communication" for communication logs
      flags, // Optional flags

      // Core log fields
      summary, // Brief summary of the communication
      created: Timestamp.now(), // Created timestamp
      edited: null, // Edited timestamp (same as created for new logs)

      // Optional location
      location, // If location is provided, it will be included

      // Communication-specific fields (communication details)
      communication: {
        interactionType, // e.g., ["Call", "Email"]
        description, // A description of what was discussed or what occurred
        communicationDate: Timestamp.fromDate(communicationDate.toDate()), // Convert to Firestore Timestamp
        followUpDate: Timestamp.fromDate(followUpDate.toDate()), // Convert to Firestore Timestamp
        tags, // Tags like "mailbox_full", "call_failed", etc.
        sentiment, // Sentiment rating (1-5)
        interest, // Interest rating (1-5)
        priority, // Priority level
        initiator, // Details of the person/system who initiated the interaction
        receiver, // Details of the person/system who received the interaction
        madeContact,
      },
    };

    // Add the log to the Firestore "logs" collection
    const logRef = collection(db, "logs"); // Referencing the "logs" collection in Firestore
    await addDoc(logRef, log); // Adding the log document to Firestore

    console.log("Communication log created successfully!");
  } catch (error) {
    console.error("Error creating communication log:", error);
  }
};

// Function to create a basic log
export const createLog = async ({
  collectionName, // e.g., "members" or "leads"
  idsArr, // Array of related IDs, e.g., ["memberId456", "employee123", "visit789"]
  summary, // Brief summary of the log
  flags = [], // Optional flag, e.g., ["payment", "urgent"]
  location = {}, // Optional location field, default to empty object
  accessLevel = "private",
  logType = "basic",
}) => {
  try {
    // Core log structure
    const log = {
      // Identifiers
      collection: collectionName, // "members" or "leads"
      idsArr, // Array of related IDs
      accessLevel, // "public", "private", or "admin"
      logType, // Type is "communication" for communication logs
      flags, // Optional flags

      // Core log fields
      summary, // Single line log summary
      created: Timestamp.now(), // Created timestamp
      edited: null, // Edited timestamp (same as created for new logs)

      // Optional location
      location, // If location is provided, it will be included
    };

    // Add the log to the Firestore "logs" collection
    const logRef = collection(db, "logs"); // Referencing the "logs" collection in Firestore
    await addDoc(logRef, log); // Adding the log document to Firestore

    console.log("Communication log created successfully!");
  } catch (error) {
    console.error("Error creating communication log:", error);
  }
};

// Function to create a communication log
export const createVisitLog = async ({
  collectionName, // e.g., "members" or "leads"
  idsArr, // Array of related IDs, e.g., ["memberId456", "employee123", "visit789"]
  summary, // Brief summary of the log
  flags = [], // Optional flag, e.g., ["payment", "urgent"]
  location = {}, // Optional location field, default to empty object
  accessLevel = "private",
  logType = "visit",
  visitId,
}) => {
  try {
    // Core log structure
    const log = {
      // Identifiers
      collection: collectionName, // "members" or "leads"
      idsArr, // Array of related IDs
      accessLevel, // "public", "private", or "admin"
      logType, // Type is "communication" for communication logs
      flags, // Optional flags

      // Core log fields
      summary, // Single line log summary
      created: Timestamp.now(), // Created timestamp
      edited: null, // Edited timestamp (same as created for new logs)

      // Optional location
      location, // If location is provided, it will be included
      visit : {
        visitId,
      }
    };

    // Add the log to the Firestore "logs" collection
    const logRef = collection(db, "logs"); // Referencing the "logs" collection in Firestore
    await addDoc(logRef, log); // Adding the log document to Firestore

    console.log("Communication log created successfully!");
  } catch (error) {
    console.error("Error creating communication log:", error);
  }
};