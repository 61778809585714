import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { doc, updateDoc } from "firebase/firestore";
import moment from "moment";
import React, { useState } from "react";
import { db } from "../../firebase";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";

const EditDateTimeDialog = ({
  open,
  setOpen,
  visitId,
  start,
  end,
  timeZoneId,
}) => {
  const [loading, setLoading] = useState(false);

  // Convert Firebase Timestamps to Moment.js with timeZoneId
  const [startDate, setStartDate] = useState(
    moment.tz(start.toDate(), timeZoneId)
  );
  const [startTime, setStartTime] = useState(
    moment.tz(start.toDate(), timeZoneId)
  );
  const [duration, setDuration] = useState(
    moment
      .duration(
        moment
          .tz(end.toDate(), timeZoneId)
          .diff(moment.tz(start.toDate(), timeZoneId))
      )
      .asMinutes()
  );

  const handleSave = async () => {
    setLoading(true);

    try {
      // Set time zone for start and end times
      const newStart = moment.tz(startDate, timeZoneId).set({
        hour: startTime.hours(),
        minute: startTime.minutes(),
      });

      const newEnd = moment.tz(newStart, timeZoneId).add(duration, "minutes");

      // Update Firestore document with the new start and end times
      await updateDoc(doc(db, "visits", visitId), {
        start: newStart.toDate(),
        end: newEnd.toDate(),
      });

      setOpen(false); // Close dialog after saving
    } catch (error) {
      console.error("Error updating visit: ", error);
    } finally {
      setLoading(false);
    }
  };

  const incrementDuration = (minutes) => {
    setDuration((prevDuration) => Math.max(15, prevDuration + minutes));
  };

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours > 0 ? `${hours}h ` : ""}${mins}m`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ResponsiveDialog
        open={open}
        onClose={() => setOpen(false)}
        title={`Day and Time`}
        width="400px"
        dialogActions={
          <>
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="text"
              disabled={loading}
              sx={{
                minWidth: { xs: "50%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              disabled={loading}
              sx={{
                minWidth: { xs: "50%", sm: "150px" },
                height: { xs: "60px", sm: "auto" },
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </>
        }
      >
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            Adjust the date, start, and duration of the visit.
          </Typography>
          <Box
            sx={{
              p: 2,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
            }}
          >
            <MobileDatePicker
              label="Visit Date"
              inputFormat="MM/DD/YYYY"
              value={startDate}
              onChange={(newDate) => setStartDate(newDate)}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mb: 2 }} />
              )}
              sx={{ maxWidth: "200px" }}
            />

            <TimePicker
              label="Start Time"
              minutesStep={5}
              value={startTime}
              onChange={(newTime) => setStartTime(newTime)}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mb: 2 }} />
              )}
              sx={{ maxWidth: "200px" }}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <IconButton
                onClick={() => incrementDuration(-15)}
                color="primary"
                disabled={duration <= 15} // Prevent going below 15 minutes
              >
                <RemoveIcon />
              </IconButton>

              <TextField
                label="Duration"
                value={formatDuration(duration)} // Use formatted duration string
                fullWidth
                InputProps={{
                  readOnly: true, // Make it read-only to avoid raw number input
                }}
                sx={{ maxWidth: "200px" }}
              />

              <IconButton onClick={() => incrementDuration(15)} color="primary">
                <AddIcon />
              </IconButton>
            </div>
          </Box>
        </DialogContent>
      </ResponsiveDialog>
    </LocalizationProvider>
  );
};

export default EditDateTimeDialog;
