import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import checkmark icon
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import ColorAvatar from "../../../components/ColorAvatar";
import EmployeeAcceptVisit from "../../../components/EmployeeAcceptVisit";
import EmployeePickupVisit from "../../../components/EmployeePickupVisit";
import EmployeeVisitCard from "../../../components/EmployeeVisitCard";
import ExpirationChip from "../../../components/ExpirationChip";
import PushDrawer from "../../../components/PushDrawer";
import ReviewVisitEmployee from "../../../components/ReviewVisitEmployee";
import { db } from "../../../firebase";
import { useEmployee } from "../../../providers/EmployeeProvider";
import { useSnackbar } from "../../../providers/SnackbarProvider";
import {
  pickupVisit,
  addAndMergeWindows,
  onAcceptVisit,
  onPickupVisit,
} from "../../../services/employeeServices";
import ResponsiveDialog from "../../../components/dialogs/ResponsiveDialog";
import { formatTimeRangeCompactListItem } from "../../../services/dateServices";

const VisitsNeedAction = ({
  visitsProp,
  type = "visit",
  noResultsMessage = "There are no visits that need action.",
}) => {
  const { data } = useEmployee();
  const currentEmployeeId = data?.auth?.uid;
  const theme = useTheme();
  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false);
  const [pickupDialogOpen, setPickupDialogOpen] = useState(false);

  const { showSnackbar } = useSnackbar();

  const [visits, setVisits] = useState(visitsProp);

  const [selectedVisit, setSelectedVisit] = useState();
  const [showSelectedVisit, setShowSelectedVisit] = useState();
  const [acceptedVisits, setAcceptedVisits] = useState({}); // State to track accepted visits
  const [toRemove, setToRemove] = useState({}); // State to track visits to remove
  const [loadingStatus, setLoadingStatus] = useState({});
  const [loading, setLoading] = useState(false); // used for dialogs

  useEffect(() => {
    // Add new visits and mark removed ones
    const updatedVisits = { ...visits };
    const updatedToRemove = { ...toRemove };

    for (const visitId in updatedVisits) {
      if (!visitsProp[visitId]) {
        // Mark for removal if not in the updated props
        updatedToRemove[visitId] = true;

        // Also mark the visit as accepted when it's removed
        setAcceptedVisits((prev) => ({ ...prev, [visitId]: true }));

        // Delay actual removal to allow for the collapse animation
        setTimeout(() => {
          setVisits((prev) => {
            const updated = { ...prev };
            delete updated[visitId];
            return updated;
          });

          setToRemove((prev) => {
            const updated = { ...prev };
            delete updated[visitId];
            return updated;
          });
        }, 500); // Delay should match the Collapse timeout
      }
    }

    // Add new visits from props
    for (const visitId in visitsProp) {
      updatedVisits[visitId] = visitsProp[visitId];
    }

    // update the local selected visit
    if (selectedVisit) {
      // Check if the currently selected visit is still present in the updated visits array
      const updatedVisit = visitsProp[selectedVisit?.id];

      if (updatedVisit) {
        // If found, update the selectedVisit with the new data
        setSelectedVisit({ id: selectedVisit?.id, ...updatedVisit });
      } else {
        // If not found, handle accordingly (e.g., reset to null or select another visit)
        setSelectedVisit(null);
      }
    }

    setVisits(updatedVisits);
    setToRemove(updatedToRemove);
  }, [visitsProp]);

  if (!data.members || Object.keys(data.members).length === 0) {
    return;
  }

  const handleOpenVisit = (visitId) => {
    setSelectedVisit({ id: visitId, ...visits[visitId] });
    setShowSelectedVisit(true);
    console.log("open visit", visitId);
  };

  const handleAccept = async (e, visitId) => {
    e.preventDefault();
    e.stopPropagation(); // Prevents event from bubbling up to the ListItemButton
    // Delay the collapse

    setSelectedVisit({ id: visitId, ...visits[visitId] });

    try {
      if (visits[visitId]?.recurrence?.frequency > 0) {
        setAcceptDialogOpen(true);
      } else {
        setLoadingStatus((prev) => ({ ...prev, [visitId]: true })); // Set loading status for this visit
        await onAcceptVisit({
          confirmationType: "this_visit",
          currentEmployeeId,
          visit: visits[visitId],
          visitId: visitId, // Assuming each visit has an id property
          visits: data?.visits,
          setLoading,
          showSnackbar,
        });
      }
    } catch (error) {
      console.error("Error in handling acceptance:", error);
    } finally {
    }
  };

  const handlePickup = async (e, visitId) => {
    e.preventDefault();
    e.stopPropagation(); // Prevents event from bubbling up to the ListItemButton
    // Delay the collapse

    setSelectedVisit({ id: visitId, ...visits[visitId] });

    try {
      if (visits[visitId]?.seriesEmployeesNeeded > 0) {
        setPickupDialogOpen(true);
      } else {
        setLoadingStatus((prev) => ({ ...prev, [visitId]: true })); // Set loading status for this visit
        onPickupVisit({
          confirmationType: "this_visit",
          currentEmployeeId,
          visit: visits[visitId],
          visitId: visitId, // Assuming each visit has an id property
          employeeData: data?.employee,
          openVisits: data?.openVisits,
          setLoading,
          availabilityWindows: data?.employee?.public?.availability?.windows,
          showSnackbar,
        });
      }

      setLoadingStatus((prev) => ({ ...prev, [visitId]: false })); // Reset loading status

      // Mark the visit for removal but keep in state for collapse animation
      // setToRemove((prev) => ({ ...prev, [visitId]: true }));
    } catch (error) {
      console.error("Error in handling acceptance:", error);
    } finally {
    }
  };

  const onClaimOpenVisit = async (visitId) => {
    try {
      console.log(
        "current windows: ",
        data?.employee?.public?.availability?.windows
      );

      const visitData = data?.openVisits[visitId];

      const updatedWindows = addAndMergeWindows({
        availability: data?.employee?.public?.availability?.windows,
        addWindow: {
          start: visitData.start.toDate(),
          end: visitData.end.toDate(),
        },
      });

      const employeeAvailabilityDataRef = doc(
        db,
        "employees",
        currentEmployeeId,
        "public",
        "availability"
      );

      await updateDoc(employeeAvailabilityDataRef, {
        windows: updatedWindows, // This line now overwrites the entire 'windows' field
      });

      await pickupVisit({
        employeeId: currentEmployeeId,
        employeeData: data?.employee,
        availability: data?.employee?.public?.availability?.windows,
        visitId,
        visitData,
      });
    } catch (error) {
      console.error("Error running accept open visit: ", error);
    }
  };

  if (Object.keys(visits).length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%", // Adjust the height as needed
        }}
      >
        <Typography>{noResultsMessage}</Typography>
      </Box>
    );
  }

  const handleVisitAction = (visitId) => {
    // Close the dialog
    setShowSelectedVisit(false);

    // Update the loading status and mark the visit as processed
    setLoadingStatus((prev) => ({ ...prev, [visitId]: true })); // Start loading
  };

  console.log("selectedVisit: ", selectedVisit);

  return (
    <>
      <EmployeeAcceptVisit
        open={acceptDialogOpen}
        setOpen={setAcceptDialogOpen}
        visit={selectedVisit}
        visitId={selectedVisit?.id}
        loading={loading}
        setLoading={setLoading}
      />

      <EmployeePickupVisit
        open={pickupDialogOpen}
        setOpen={setPickupDialogOpen}
        visit={selectedVisit}
        visitId={selectedVisit?.id}
        loading={loading}
        setLoading={setLoading}
      />

      <ResponsiveDialog
        open={showSelectedVisit}
        onClose={() => setShowSelectedVisit(false)}
        title={type === "review" ? "Visit Feedback" : "Visit Details"}
        width="600px"
        fullHeight={true}
        anchor={"right"}
        desktopAnchor={"right"}
        showBackdrop={false}
      >
        {type === "review" ? (
          <ReviewVisitEmployee
            visit={selectedVisit}
            visitId={selectedVisit?.id}
            member={
              data?.members && selectedVisit?.member
                ? data.members[selectedVisit.member]
                : undefined
            }
            employeeId={currentEmployeeId}
            handleClose={() => setShowSelectedVisit(false)}
            confirmationScreen={false}
          />
        ) : (
          <EmployeeVisitCard
            visit={selectedVisit}
            visitId={selectedVisit?.id}
            type={type}
            handleAccept={() => handleVisitAction(selectedVisit?.id)}
            handleDecline={() => handleVisitAction(selectedVisit?.id)}
            handlePickup={() => handleVisitAction(selectedVisit?.id)}
          />
        )}
      </ResponsiveDialog>

      <DialogContent sx={{ p: 1 }}>
        <List sx={{ py: 0 }}>
          {Object.entries(visits)
            .sort((a, b) => {
              const startTimeA = moment(a[1].start.toDate()).tz(
                a[1].timeZoneId
              );
              const startTimeB = moment(b[1].start.toDate()).tz(
                b[1].timeZoneId
              );
              return startTimeA.diff(startTimeB);
            })
            .map(([key, visit]) => (
              <Collapse in={!toRemove[key]} timeout={500} key={key}>
                <ListItem divider sx={{ px: 1 }}>
                  <ListItemButton
                    onClick={() => handleOpenVisit(key)}
                    sx={{
                      borderRadius: "15px",
                      pl: 0,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <ColorAvatar
                        name={`${data?.members[visit?.member]?.firstName} ${
                          data?.members[visit?.member]?.lastName
                        }`}
                        avatarUrl={
                          data.members[visit?.member]?.avatarUrl ||
                          visit?.avatarUrl
                        }
                      />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <ListItemText
                          sx={{
                            maxWidth: "120px",
                            "& .MuiListItemText-primary": {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            },
                          }}
                          primary={
                            `${data?.members[visit?.member]?.firstName || ""} ${
                              data?.members[visit?.member]?.lastName || ""
                            }`.trim() || visit?.displayName
                          }
                          secondary={
                            <>
                              <Typography component="span">
                                {moment(visit?.start.toDate())
                                  .tz(visit?.timeZoneId)
                                  .format("ddd, MMM D")}
                              </Typography>
                              <br />
                              <Typography component="span">
                                {formatTimeRangeCompactListItem({
                                  start: visit.start,
                                  end: visit.end,
                                  timeZoneId: visit.timeZoneId,
                                })}
                              </Typography>
                            </>
                          }
                        />
                        <ExpirationChip
                          expirationDate={visit?.expiration}
                          timeZoneId={visit?.timeZoneId}
                        />
                      </Box>
                    </div>

                    {type === "visit" && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => handleAccept(e, key)}
                        disabled={acceptedVisits[key] || loadingStatus[key]}
                        sx={{
                          minWidth: "90px", // Ensure enough space for 'Accepted' text
                          height: { xs: "60px", sm: "auto" },
                        }}
                      >
                        {loadingStatus[key] ? (
                          <CircularProgress size={24} /> // Loading indicator
                        ) : acceptedVisits[key] ? (
                          <CheckCircleIcon />
                        ) : (
                          "Accept"
                        )}
                      </Button>
                    )}
                    {type === "openVisit" && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => handlePickup(e, key)}
                        disabled={acceptedVisits[key] || loadingStatus[key]}
                        sx={{
                          minWidth: "90px", // Ensure enough space for 'Accepted' text
                          height: { xs: "60px", sm: "auto" },
                        }}
                      >
                        {loadingStatus[key] ? (
                          <CircularProgress size={24} /> // Loading indicator
                        ) : acceptedVisits[key] ? (
                          <CheckCircleIcon />
                        ) : (
                          "Pick-up"
                        )}
                      </Button>
                    )}
                  </ListItemButton>
                  <IconButton
                    edge="end"
                    aria-label="go"
                    onClick={() => handleOpenVisit(key)}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </ListItem>
              </Collapse>
            ))}
        </List>
      </DialogContent>
    </>
  );
};

export default VisitsNeedAction;
