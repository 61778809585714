import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Card,
  Stack,
  CircularProgress,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useSnackbar } from "../providers/SnackbarProvider";
import ResponsiveDialog from "./dialogs/ResponsiveDialog";

const AddEmployeeDialog = ({ open, setOpen, visitId, employeesNeeded = 0 }) => {
  const [employeeCount, setEmployeeCount] = useState(employeesNeeded);
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleIncrement = () => {
    if (employeeCount < 4) setEmployeeCount(employeeCount + 1);
  };

  const handleDecrement = () => {
    if (employeeCount > 0) setEmployeeCount(employeeCount - 1);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const visitRef = doc(db, "visits", visitId);
      await updateDoc(visitRef, {
        employeesNeeded: employeeCount,
        isOpenVisit: employeeCount > 0,
      });
      showSnackbar("Employee request updated", "success");
    } catch (error) {
      console.error("Error updating employee request: ", error);
      showSnackbar("Failed to update employee request", "error");
      setLoading(false);
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={() => setOpen(false)}
      title={`Request Employees`}
      width="400px"
      dialogActions={
        <>
          <Button
            onClick={() => setOpen(false)}
            color="primary"
            variant="text"
            disabled={loading}
            sx={{
              minWidth: { xs: "50%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={loading}
            sx={{
              minWidth: { xs: "50%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
          </Button>
        </>
      }
    >
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          Request additional employees for this visit. All local employees will
          see this request and will have the opportunity to join this visit but
          it is not guaranteed.
        </Typography>
        <Card
          variant="outlined"
          sx={{
            p: 2,
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography align="center" variant="body1" gutterBottom>Employees requested</Typography>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              onClick={handleDecrement}
              disabled={employeeCount === 0}
            >
              <Remove />
            </IconButton>
            <Card
              elevation={0}
              sx={{
                width: "50px",
                p: 1,
                textAlign: "center",
                backgroundColor: "#e0e0e0",
              }}
            >
              {employeeCount}
            </Card>
            <IconButton
              onClick={handleIncrement}
              disabled={employeeCount === 4}
            >
              <Add />
            </IconButton>
          </Stack>
        </Card>
      </DialogContent>
    </ResponsiveDialog>
  );
};

export default AddEmployeeDialog;
