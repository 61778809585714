import React, { useState, useEffect } from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  Chip,
  Avatar,
  TextField,
  InputAdornment,
  IconButton,
  Skeleton,
  DialogContent,
} from "@mui/material";
import { Search, Cancel, Autorenew, Schedule } from "@mui/icons-material";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import _ from "lodash";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import EmployeeCard from "./EmployeeCard";
import ChangeEmployeeStatus from "./ChangeEmployeeStatus";
import moment from "moment-timezone";
import WeekView from "../calendar/WeekView";
import MapDisplay from "../MapDisplay";

const EmployeeSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [sessionCache, setSessionCache] = useState({});

  // for dialogs
  const [openEmployeeCard, setOpenEmployeeCard] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openViewSchedule, setOpenViewSchedule] = useState(false);

  // for calendar view
  const [calendarDate, setCalendarDate] = useState();
  const [unsubVisits, setUnsubVisits] = useState(null); // State to hold the unsubscribe function

  ////////////////////////////////////////////////////////////////////////////////

  // This is to set the employee Data

  const [employeeData, setEmployeeData] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [privateData, setPrivateData] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);
  const [defaultAvailabilityData, setDefaultAvailabilityData] = useState(null);
  const [visitsData, setVisitsData] = useState(null);

  useEffect(() => {
    if (!selectedEmployee?.id) return;

    setLoading(true);

    // Employee data listener
    const unsubEmployee = onSnapshot(
      doc(db, "employees", selectedEmployee?.id),
      (doc) => {
        setEmployeeData(doc.data());
      }
    );

    // Admin data listener
    const unsubAdmin = onSnapshot(
      doc(db, "employees", selectedEmployee?.id, "admin", "data"),
      (doc) => {
        setAdminData(doc.data());
      }
    );

    // Private data listener
    const unsubPrivate = onSnapshot(
      doc(db, "employees", selectedEmployee?.id, "private", "data"),
      (doc) => {
        setPrivateData(doc.data());
      }
    );

    // Availability data listeners
    const unsubAvailability = onSnapshot(
      doc(db, "employees", selectedEmployee?.id, "public", "availability"),
      (doc) => {
        setAvailabilityData(doc.data());
      }
    );

    const unsubDefaultAvailability = onSnapshot(
      doc(
        db,
        "employees",
        selectedEmployee?.id,
        "public",
        "defaultAvailability"
      ),
      (doc) => {
        setDefaultAvailabilityData(doc.data());
      }
    );

    setLoading(false);

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubEmployee();
      unsubAdmin();
      unsubPrivate();
      unsubAvailability();
      unsubDefaultAvailability();
    };
  }, [selectedEmployee?.id]);

  useEffect(() => {
    if (!openEmployeeCard) {
      // When the Employee Card dialog closes, clean up the listener
      if (unsubVisits) {
        unsubVisits();
        setUnsubVisits(null);
        console.log("Unsubscribing from visits listener");
      }
      return;
    }

    if (unsubVisits) {
      // If there's already a listener, no need to set up another
      console.log("Listener already set up");
      return;
    }

    if (!selectedEmployee?.id || !openViewSchedule) return;

    setLoading(true);

    // Set up the listener only when the dialog opens
    const visitsQuery = query(
      collection(db, "visits"),
      where("employeeArr", "array-contains", selectedEmployee.id)
    );

    const unsubscribe = onSnapshot(visitsQuery, (snapshot) => {
      const visitsData = snapshot.docs.map((doc) => doc.data());
      console.log("Subscribing to visit data");
      setVisitsData(visitsData);
      setLoading(false);
    });

    setUnsubVisits(() => unsubscribe); // Store the unsubscribe function
  }, [openViewSchedule, openEmployeeCard]);

  ////////////////////////////////////////////////////////////////////////////////

  const handleChange = (event) => {
    const inputVal =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
    setSearchTerm(inputVal);
  };

  console.log("refreshing component EmployeeSearch");

  useEffect(() => {
    console.log("running useEffect");
    if (searchTerm === "") {
      // Aggregate all unique employees from the cache.
      const allCachedEmployees = Object.values(sessionCache).flat();
      const uniqueCachedEmployeesMap = {};

      allCachedEmployees.forEach((employee) => {
        uniqueCachedEmployeesMap[employee.id] = employee;
      });

      const uniqueCachedEmployees = Object.values(uniqueCachedEmployeesMap);

      setEmployees(uniqueCachedEmployees);
      return;
    }

    if (sessionCache[searchTerm]) {
      // If results for the current term are cached, use them!
      setEmployees(sessionCache[searchTerm]);
      return;
    }

    setLoading(true);
    const debouncedSearch = _.debounce(searchEmployees, 500); // Debouncing to minimize the number of requests

    debouncedSearch(searchTerm);

    // Cleanup the debounce
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, sessionCache]);

  const searchEmployees = async (searchText) => {
    console.log("running searchEmployees");

    if (!searchText.trim()) {
      setEmployees([]); // or set it to default employees list if you have it.
      return;
    }

    setLoading(true);

    // Queries for each field
    const firstNameQuery = query(
      collection(db, "employees"),
      where("firstName", ">=", searchTerm),
      where("firstName", "<=", searchTerm + "\uf8ff")
    );
    const lastNameQuery = query(
      collection(db, "employees"),
      where("lastName", ">=", searchTerm),
      where("lastName", "<=", searchTerm + "\uf8ff")
    );

    try {
      // Fetch data separately
      const [firstNameSnapshot, lastNameSnapshot] = await Promise.all([
        getDocs(firstNameQuery),
        getDocs(lastNameQuery),
      ]);

      const resultSet = {}; // Using an object to store employees with unique IDs

      // Process each snapshot and add it to the object
      const processSnapshot = (snapshot) => {
        snapshot.forEach((doc) => {
          const employeeData = { id: doc.id, ...doc.data() };
          resultSet[employeeData.id] = employeeData; // uniqueness is maintained based on employee IDs
        });
      };

      processSnapshot(firstNameSnapshot);
      processSnapshot(lastNameSnapshot);

      // Convert the object values to an array
      const uniqueEmployees = Object.values(resultSet);

      setEmployees(uniqueEmployees);
      setLoading(false);

      // Storing data in session cache
      setSessionCache((prevState) => {
        return { ...prevState, [searchTerm]: uniqueEmployees };
      });
    } catch (error) {
      console.error("Error fetching employees: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleListItemClick = (employee) => {
    setCalendarDate(moment().tz(employee?.timeZoneId));
    setSelectedEmployee(employee);
    setOpenEmployeeCard(true);
  };

  const renderEmployeeList = () => {
    if (loading) {
      return (
        <div>
          <ListItemButton>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ marginRight: 2 }}
            />
            <ListItemText
              primary={<Skeleton variant="text" width={100} />}
              secondary={<Skeleton variant="text" width={200} />}
            />
          </ListItemButton>
        </div>
      );
    }
    return employees.map((employee) => (
      <ListItemButton
        key={employee.id}
        onClick={() => handleListItemClick(employee)}
        sx={{ borderRadius: "15px" }}
      >
        <Avatar sx={{ marginRight: 2 }}></Avatar>
        <ListItemText
          primary={`${employee.firstName} ${employee.lastName}`}
          secondary={`${employee.location.city}, ${employee.location.state}`}
        />
        {/* <Chip label={employee.status} /> */}
      </ListItemButton>
    ));
  };

  ////////////
  const handleChangeStatus = () => {
    setOpenChangeStatus(true);
    console.log("handle change status");
  };

  const handleViewSchedule = () => {
    setOpenViewSchedule(true);
    console.log("handle view schedule");
  };

  const actions = [
    {
      icon: <Autorenew color="primary" />,
      primary: "Change Status",
      secondary: "Update the employee's current status.",
      action: handleChangeStatus,
      chip: {
        label: selectedEmployee?.status,
      },
    },
    {
      icon: <Schedule color="primary" />,
      primary: "View Schedule",
      secondary: "See the employee's schedule details.",
      action: handleViewSchedule,
    },
  ];

  return (
    <div>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search for an employee..."
          value={searchTerm}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm && (
                  <IconButton
                    aria-label="clear search"
                    onClick={() => setSearchTerm("")}
                    edge="end"
                  >
                    <Cancel />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <List>{renderEmployeeList()}</List>
      </DialogContent>
      {selectedEmployee && (
        <>
          <ResponsiveDialog
            open={openEmployeeCard}
            onClose={() => setOpenEmployeeCard(false)}
            title={`${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`}
            desktopAnchor={"right"}
            anchor={"right"}
            width={"600px"}
            fullHeight={true}
          >
            <EmployeeCard
              employeeData={employeeData}
              actions={actions}
              adminData={adminData}
              privateData={privateData}
              availabilityData={availabilityData}
              defaultAvailabilityData={defaultAvailabilityData}
            />
          </ResponsiveDialog>
          <ResponsiveDialog
            open={openChangeStatus}
            onClose={() => setOpenChangeStatus(false)}
            title={"Change status"}
            desktopAnchor={"right"}
            anchor={"right"}
            width={"600px"}
            showBackdrop={false}
            zIndex={1300}
            fullHeight={true}
          >
            <ChangeEmployeeStatus
              currentStatus={selectedEmployee?.status}
              employeeId={selectedEmployee?.id}
              handleClose={() => setOpenChangeStatus(false)}
            />
          </ResponsiveDialog>
          <ResponsiveDialog
            open={openViewSchedule}
            onClose={() => setOpenViewSchedule(false)}
            title={"View schedule"}
            desktopAnchor={"right"}
            anchor={"right"}
            fullHeight={true}
            width={"600px"}
            showBackdrop={false}
            zIndex={1300}
          >
            {openViewSchedule && (
              <WeekView
                initialDay={calendarDate}
                calendarDate={calendarDate}
                setCalendarDate={setCalendarDate}
                visits={visitsData}
                employeeId={selectedEmployee?.id}
                timeZoneId={selectedEmployee?.timeZoneId}
                availability={availabilityData?.windows}
                // isMobile={false}
                // height={boxHeight - 48}
              />
            )}
          </ResponsiveDialog>
        </>
      )}
    </div>
  );
};

export default EmployeeSearch;
