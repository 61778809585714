import {
  Cake,
  CreditCard,
  Edit as EditIcon,
  Email,
  FileCopyOutlined,
  Home,
  Phone,
} from "@mui/icons-material";
import {
  Box,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useState } from "react";
import AddressTab from "../../../dashboard/member/tabs/AddressTab";
import ResponsiveDialog from "../../dialogs/ResponsiveDialog";
import ChangeMembership from "../../ChangeMembership";

const MemberDetails = ({ memberData, privateData, accountData, adminData }) => {
  // State to control address editing dialog
  const [openManageAddress, setOpenManageAddress] = useState(false);
  const [openChangeMembership, setOpenChangeMembership] = useState(false);

  // Function to format the address
  const formatAddress = (address) => {
    const line1 = address?.line1 ? `${address?.line1}` : "";
    const line2 = address?.line2 ? `, ${address?.line2}` : "";
    const city = address?.city ? `, ${address?.city}` : "";
    const state = address?.state ? ` ${address?.state}` : "";
    const zipCode = address?.zipCode ? ` ${address?.zipCode}` : "";

    return `${line1}${line2}${city}${state}${zipCode}`;
  };

  const timeZoneId = memberData?.timeZoneId;
  const nextMembershipDate = memberData?.billing?.nextMembershipFeeDate;
  const membershipTier = adminData?.membershipTier;
  const defaultAddressKey = privateData?.defaultAddress;
  const address = accountData?.addresses?.[defaultAddressKey];
  const hourlyRate = adminData?.hourlyRate;
  const membershipFee = adminData?.membershipFee;

  // Function to handle copying text to the clipboard
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Copied to clipboard");
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  // Function to handle address editing
  const handleEditAddress = () => {
    setOpenManageAddress(true);
  };

  // Close address editing dialog
  const handleCloseAddressEditor = () => {
    setOpenManageAddress(false);
  };

  // DRY: Store member details in a structure to iterate over
  const memberDetails = [
    {
      label: "Phone",
      data: privateData?.phone,
      icon: <Phone />,
      actions: [
        {
          type: "copy",
          handler: () => handleCopy(privateData?.phone),
          icon: <FileCopyOutlined />,
        },
      ],
    },
    {
      label: "Email",
      data: privateData?.email,
      icon: <Email />,
      actions: [
        {
          type: "copy",
          handler: () => handleCopy(privateData?.email),
          icon: <FileCopyOutlined />,
        },
      ],
    },
    {
      label: "Address",
      data: formatAddress(address),
      icon: <Home />,
      actions: [
        {
          type: "edit",
          handler: handleEditAddress,
          icon: <EditIcon />,
          },
            {
              type: "copy",
              handler: () => handleCopy(formatAddress(address)),
              icon: <FileCopyOutlined />,
            },
      ],
    },
    {
      label: "Joined",
      data: moment(
        memberData?.admin?.created
          ? memberData?.admin?.created.toDate()
          : adminData?.created.toDate()
      ).format("MMMM D, YYYY"),
      icon: <Cake />,
      actions: [
        {
          type: "copy",
          handler: () => handleCopy(
            moment(memberData?.admin?.created?.toDate() || adminData?.created?.toDate())
              .format("MMMM D, YYYY")
          ),
          icon: <FileCopyOutlined />,
        },
      ],
    },
    {
      label: membershipTier === 0 ? "Basic Plan" : "Premium Plan",
      data: membershipTier === 0
        ? `$${hourlyRate}/hr, no monthly fees`
        : `$${hourlyRate}/hr + $${membershipFee}/mo`,
      secondary: nextMembershipDate
        ? `Next cycle: ${moment(nextMembershipDate.toDate())
            .tz(timeZoneId)
            .format("MMM Do")}`
        : "",
      icon: <CreditCard />,
      actions: [
        {
          type: "edit",
          handler: () => setOpenChangeMembership(true),
          icon: <EditIcon />,
        },
      ],
    },
  ];

  return (
    <>
      <DialogContent>
        <Stack spacing={2} alignItems="center">
          <List sx={{ width: "100%" }}>
            {memberDetails.map(({ label, data, icon, actions = [] }) => (
              <ListItem key={label} divider>
                {/* <ListItemIcon>
                  {icon}
                </ListItemIcon> */}
                <ListItemText
                  primary={label}
                  secondary={data ? data : <Skeleton width="150px" />}
                />
                <ListItemSecondaryAction>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    {actions.map((action, index) => (
                      <IconButton
                        key={index}
                        edge="end"
                        onClick={action.handler}
                        disabled={!data && action.type === "copy"}
                      >
                        {action.icon}
                      </IconButton>
                    ))}
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Stack>
      </DialogContent>
      {/* Responsive Dialog for Address Edit */}
      <ResponsiveDialog
        open={openManageAddress}
        onClose={handleCloseAddressEditor}
        title={"Manage Address"}
        width={"600px"}
        desktopAnchor={"right"}
        anchor={"right"}
        showBackdrop={false}
        fullHeight={true}
      >
        {openManageAddress && (
          <AddressTab
            accountId={adminData?.accountId}
            memberId={memberData?.id}
            addresses={accountData?.addresses}
            defaultAddress={defaultAddressKey}
            firstName={memberData?.firstName}
            lastName={memberData?.lastName}
            stripeId={adminData?.stripeId}
            phone={privateData?.phone}
            email={privateData?.email}
            memberView={false}
          />
        )}
      </ResponsiveDialog>
      {/* Responsive Dialog for Changing Membership */}
      <ResponsiveDialog
        open={openChangeMembership}
        onClose={() => setOpenChangeMembership(false)}
        title={"Change Plan"}
        width={"800px"}
      >
        <ChangeMembership
          memberId={memberData?.id}
          timeZoneId={memberData?.timeZoneId}
          zipCode={memberData?.location?.zipCode}
          membershipTier={adminData?.membershipTier}
          setOpenPricingCards={setOpenChangeMembership}
        />
      </ResponsiveDialog>
    </>
  );
};

export default MemberDetails;