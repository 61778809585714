import { Box, Typography } from "@mui/material";
import useTrackVisibility from "../../../hooks/useTrackVisibility";
import { generalFaq } from "../../../services/memberServices";
import FAQAccordion from "./FAQAccordion";

const FAQSection = ({ pageName = "" }) => {
  const ref = useTrackVisibility(() => {}, `${pageName}/"FAQ section"`); // Pass the logMessage here

  return (
    <Box
      ref={ref}
      sx={{
        mb: { xs: 1, sm: 4 },
        mt: { xs: 4, sm: 10 },
        mx: { xs: 1, sm: "auto" },
        maxWidth: "800px",
        display: "block",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: { xs: 1, sm: 0 },
          pt: { xs: 4, sm: 0 },
        }}
      >
        <Typography
          align="center"
          sx={{
            fontSize: { xs: "h3.fontSize", sm: "h2.fontSize" }, // Responsive font sizes
            fontWeight: { xs: "h2.fontWeight", sm: "h2.fontWeight" }, // Responsive font weights
            lineHeight: "inherit",
            mb: 1,
            mx: "auto",
            mt: { xs: 1, sm: 0 }, // Adjusts margin top based on screen size
            maxWidth: { xs: "320px", sm: "650px" },
          }}
        >
          Common questions
        </Typography>
        <Typography
          align="center"
          variant="h6"
          fontWeight="400"
          sx={{ mt: 1, mb: { xs: 0, sm: 2 }, mx: "auto" }}
        >
          From common tasks, to payment details, to booking instructions, find
          answers to your top questions.
        </Typography>
      </Box>
      <FAQAccordion
        data={generalFaq}
        stickySearch={false}
        pageName={pageName}
      />
    </Box>
  );
};

export default FAQSection;
