import { LocalActivity } from "@mui/icons-material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"; // Example for dollars
import LocalActivityIcon from "@mui/icons-material/LocalActivity"; // Example icon, replace with appropriate ones
import PercentIcon from "@mui/icons-material/Percent"; // Example for percentage
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import { useMember } from "../providers/MemberProvider";

const DiscountsBanner = () => {
  const { data } = useMember();
  const discounts = data?.member?.admin?.data?.discounts;
  const showDiscounts = discounts && Object.keys(discounts).length > 0;
  const timeZoneId = data?.member?.timeZoneId || "America/Los_Angeles";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const gradient =
    "linear-gradient(to right, hsl(150,100%,30%), hsl(191,100%,30%))";

  // Function to get the appropriate icon for each discount type
  const getIconForDiscount = (discount) => {
    switch (discount.type) {
      case "hours":
        return <LocalActivity sx={{ color: "white" }} />;
      case "percent":
        return <PercentIcon sx={{ color: "white" }} />;
      case "dollars":
        return <AttachMoneyIcon sx={{ color: "white" }} />;
      default:
        return <LocalActivityIcon sx={{ color: "white" }} />; // Default icon
    }
  };

  return (
    <>
      {showDiscounts &&
        Object.entries(discounts)
          .filter(
            ([, discount]) =>
              !discount.used &&
              (!discount.expiration ||
                (discount?.expiration &&
                  moment(discount?.expiration?.toDate()).tz(timeZoneId) >
                    moment().tz(timeZoneId)))
          )
          .map(([key, discount]) => (
            <Box
              key={key}
              sx={{
                borderRadius: { xs: "0px", sm: "15px" },
                mb: "16px",
                background: gradient,
                color: "white",
                mt: { xs: "-16px", sm: -2 },
                mx: { xs: "-16px", sm: 0 },
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "60px",
                alignItems: "center",
                px: {xs: 2, sm: 4},
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                {getIconForDiscount(discount)}
                <Typography variant="body1" fontWeight={"600"}>
                  {discount.description}
                </Typography>
              </Box>
              {discount.expiration && (
                <Typography variant="body2">
                  {isMobile ? "Exp. " : "Expires "}
                  <strong>
                    {moment(discount.expiration.toDate())
                      .tz(timeZoneId)
                      .format("MMM D")}
                  </strong>
                </Typography>
              )}
            </Box>
          ))}
    </>
  );
};

export default DiscountsBanner;
