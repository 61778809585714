import AddCreditCard from "../../../components/AddCreditCard";
import ListCreditCards from "../../../components/ListCreditCards";
import React, { useState, useEffect } from "react";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import {
  Switch,
  Box,
  Typography,
  Grid,
  Divider,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  DialogContent,
  Button,
  Chip,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useMember } from "../../../providers/MemberProvider";
import {
  setMembershipPlan,
  updateMemberBillingCycle,
} from "../../../services/memberServices";
import ResponsiveDialog from "../../../components/dialogs/ResponsiveDialog";
import PricingCards from "../../../pages/public/sections/PricingCards";
import { Explore } from "@mui/icons-material";
import moment from "moment-timezone";
import CustomSwitch from "../../../components/CustomSwitch";

const BillingTab = () => {
  const { data, isServiceArea } = useMember();

  const [autoPay, setAutoPay] = useState(false);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [loading, setLoading] = useState(false);
  const [openPricingCards, setOpenPricingCards] = useState(false);

  // For the membership plan selection
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const membershipTier = data?.member?.admin?.data?.membershipTier;
  const membershipData = data?.member?.admin?.data;
  const nextMembershipDate = data?.member?.billing?.nextMembershipFeeDate;

  console.log("nextMembershipDate, ", nextMembershipDate);

  const timeZoneId = data?.member?.timeZoneId;

  const hourlyRate = membershipData?.hourlyRate;
  const membershipFee = membershipData?.membershipFee;

  let planLabel, planTitle, chipColor;
  if (membershipTier === 0) {
    planTitle = "Basic";
    planLabel = `$${hourlyRate} / hour, no monthly fees`;
    chipColor = "success"; // Green for Basic
  } else if (membershipTier === 1) {
    planTitle = "Premium";
    planLabel = `$${hourlyRate} / hour + $${membershipFee} / month`;
    chipColor = "primary"; // Blue for Premium
  }

  const handlePlanClick = async (plan) => {
    setIsLoading(true); // Start loading
    setSelectedPlan(plan.tier); // Set the selected plan

    try {
      // if (plan.tier === 0) {
      // Update Firestore document for the member if plan is 0
      // const memberPrivateDataRef = doc(
      //   db,
      //   "members",
      //   data?.auth?.uid,
      //   "private",
      //   "data"
      // );
      // await updateDoc(memberPrivateDataRef, {
      //   "billing.membershipChange": plan.tier,
      // });
      // console.log(
      //   `Plan Tier: ${plan.tier} update for membership change set successfully`
      // );
      // } else if (plan.tier === 1) {
      // Call the cloud function with the necessary data if plan is 1
      await setMembershipPlan({
        plan: plan.tier,
        hourlyRate: plan.hourlyRate,
        membershipFee: plan.membershipFee,
        timeZoneId: data?.member?.timeZoneId,
      });
      console.log(`Plan Tier: ${plan.tier} set successfully`);
      // }

      setOpenPricingCards(false);
    } catch (error) {
      console.error("Error setting membership plan:", error);
    } finally {
      setIsLoading(false); // Stop loading after the operation is complete
    }
  };

  // Update local state when the data changes
  useEffect(() => {
    if (data?.member?.private?.data) {
      setAutoPay(data.member.private.data.autoPay || false);
      setBillingCycle(data.member.admin.data.billingCycle || "monthly");
    }
  }, []);

  const memberId = data?.auth?.uid;

  console.log("memberId: ", memberId);

  const handleAutoPayToggle = async (event) => {
    setAutoPay(event.target.checked);
    setLoading(true);
    const privateMemberDataRef = doc(
      db,
      "members",
      memberId,
      "private",
      "data"
    );
    console.log("privateMemberDataRef: ", privateMemberDataRef);

    try {
      await updateDoc(privateMemberDataRef, {
        autoPay: event.target.checked,
      });
    } catch (error) {
      console.error("Error updating billing settings", error);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleBillingCycleChange = async (event) => {
    setBillingCycle(event.target.value);
    setLoading(true);
    try {
      await updateMemberBillingCycle({
        billingCycle: event.target.value,
        nextMembershipFeeDate: moment(nextMembershipDate.toDate())
          .tz(timeZoneId)
          .toISOString(),
        timeZoneId,
      });
    } catch (error) {
      console.error("Error updating billing settings", error);
      setLoading(false);
    }
    setLoading(false);
  };

  const isStripeCustomer = data?.member?.admin?.data?.stripeId !== undefined;
  console.log(
    "data?.member?.admin?.data?.stripeId: ",
    data?.member?.admin?.data?.stripeId
  );

  return (
    <>
      <DialogContent>
        <Typography variant="h6" sx={{ pb: 2 }}>
          Billing Settings
        </Typography>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
            {planTitle && (
              <Alert
                icon={false}
                color={chipColor}
                sx={{ borderRadius: "10px" }}
              >
                {/* <AlertTitle>{planTitle} Plan</AlertTitle> */}
                <AlertTitle>Lifetime membership</AlertTitle>

                {planLabel}
                {nextMembershipDate && (
                  <>
                    <Typography variant="subtitle2">
                      {`Next cycle: ${moment(nextMembershipDate.toDate())
                        .tz(timeZoneId)
                        .format("MMM Do")}`}
                    </Typography>
                  </>
                )}
              </Alert>
            )}
          </Grid>
          <Divider sx={{ marginBottom: 2 }} />
          {/* ---------- Membership Plan ---------- */}

          {/* <Grid item xs={6}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Membership Plan
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Switch your plan at any time for whatever suits you best.
            </Typography>
          </Grid> */}

          {/* <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setOpenPricingCards(true)}
              sx={{ height: "56px", maxWidth: "150px" }}
            >
              Change Plan
            </Button>
          </Grid> */}

          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* ---------- AutoPay ---------- */}
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                AutoPay
              </Typography>
              <Alert
                icon={false}
                sx={{ ml: 1, bgcolor: autoPay ? "success" : "#e0e0e0", py: 0 }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: autoPay ? "success.dark" : "text.secondary", // Assuming these are theme colors
                  }}
                >
                  {autoPay ? "ON" : "OFF"}
                </Typography>
              </Alert>
            </Box>
            <Typography variant="body2">
              {autoPay
                ? "Your credit card will be charged automatically on the due date."
                : "You will receive an emailed invoice for services."}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <CustomSwitch checked={autoPay} onChange={handleAutoPayToggle} />
            {/* {loading && <CircularProgress size={24} />} */}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* ---------- Cycle preference ---------- */}
          {membershipTier === 1 && (
            <>
              <Grid item xs={6}>
                <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                  Billing Frequency
                </Typography>
                <Typography variant="body2">
                  Receive bills monthly or after receiving services.
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="billing-cycle"
                    name="billing-cycle"
                    value={billingCycle}
                    onChange={handleBillingCycleChange}
                  >
                    <FormControlLabel
                      value="monthly"
                      control={<Radio />}
                      label="Monthly"
                    />
                    <FormControlLabel
                      value="daily"
                      control={<Radio />}
                      label="Per Service"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {isStripeCustomer && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                Payment Methods
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Add, remove, or designate your default payment method below.
              </Typography>
              <ListCreditCards />
            </Grid>
          )}
        </Grid>
        {/* ---------- Credit Cards ---------- */}

        {/* <AddCreditCard /> */}
      </DialogContent>
      <ResponsiveDialog
        open={openPricingCards}
        onClose={() => setOpenPricingCards(false)}
        title={"Change Your Plan"}
        width={"800px"}
      >
        <DialogContent>
          {isServiceArea ? (
            <>
              <Typography align="center" sx={{ mb: 1 }}>
                Select the plan that works best for you.
              </Typography>
              <PricingCards
                onClick={handlePlanClick}
                dense={true}
                buttonText="Select"
                loading={isLoading}
                loadingPlan={selectedPlan}
                currentPlan={data?.member?.admin?.data?.membershipTier}
                zipCode={data?.member?.location?.zipCode}
              />
            </>
          ) : (
            <Box textAlign="center" sx={{ my: 2 }}>
              {/* Icon */}
              <Explore color="primary" sx={{ fontSize: 40, mb: 1 }} />
              {/* Header Text */}
              <Typography variant="h6" sx={{ mb: 1 }}>
                Hang Tight!
              </Typography>

              {/* Message */}
              <Typography sx={{ mb: 10 }}>
                We haven't reached your town quite yet. Once service begins, we
                will let you know and you will be able to select a plan.
              </Typography>
            </Box>
          )}
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
};

export default BillingTab;
