import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Avatar,
  Box,
  Chip,
  DialogContent,
  Grid,
  ListItemButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { convertStateNameToAbbreviation } from "../../services/locationServices";
import MapDisplay from "../MapDisplay";
import GridButtons from "../buttons/GridButtons";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import GoogleMapsDisplay from "../../dashboard/employee/tabs/GoogleMapsDisplay";

const EmployeeCard = ({
  employeeData,
  actions,
  adminData,
  privateData,
  availabilityData,
  defaultAvailabilityData,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openMapView, setOpenMapView] = useState(false);

  const renderAvailabilityChip = () => {
    if (employeeData.isAvailableToday) {
      return <Chip label="Available Today" color="success" />;
    }
  };

  if (!employeeData) {
    return <p>No data available</p>;
  }

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <Avatar
              alt={`${employeeData.firstName} ${employeeData.lastName}`}
              src={employeeData.avatarUrl}
              sx={{ width: 100, height: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="h5">{`${employeeData.firstName} ${employeeData.lastName}`}</Typography>
              {renderAvailabilityChip()}
            </Box>
            <Box
              sx={{
                borderRadius: "15px",
                backgroundColor: "white",
                // boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <ListItemButton
                onClick={() => setOpenMapView(true)}
                sx={{
                  display: "flex",
                  gap: 1,
                  mx: -2,
                  borderRadius: 1,
                  // border: `1px solid ${theme.palette.primary.main}`,
                  transition: "border-color 0.3s ease",
                  "&:hover": {
                    borderColor: theme.palette.grey[500],
                  },
                }}
              >
                <LocationOnIcon fontSize="small" />
                <Typography variant="subtitle1" color="textSecondary">
                  {employeeData.location.city},{" "}
                  {convertStateNameToAbbreviation(employeeData.location.state)}
                </Typography>
              </ListItemButton>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <CalendarTodayIcon fontSize="small" />
              <Typography variant="subtitle1" color="textSecondary">
                Available {employeeData.bookingDaysInAdvance}{" "}
                {employeeData.bookingDaysInAdvance > 1 ? "days" : "day"} in
                advance
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" fontStyle={"italic"}>
                {employeeData.age}, {employeeData.gender}
              </Typography>
            </Box>
            <Typography variant="body2" paragraph>
              {employeeData.bio}
            </Typography>
          </Grid>
        </Grid>
        {actions && <GridButtons actions={actions} />}
      </DialogContent>
      <ResponsiveDialog
        open={openMapView}
        onClose={() => setOpenMapView(false)}
        title={"View service area"}
        desktopAnchor={"right"}
        anchor={"bottom"}
        width={"600px"}
        showBackdrop={false}
        zIndex={1300}
      >
        <DialogContent>
          {/* <MapDisplay
            geohash={employeeData?.location?.geohash}
            savedBounds={employeeData?.location?.bounds}
            width={"100%"}
            height={"400px"}
          /> */}
          <GoogleMapsDisplay
            // handleEdit={() => ()}
            buttonText={"Save"}
            employeeGeohash={employeeData?.location?.geohash}
            initialBounds={employeeData?.location?.bounds || []}
            initialCenter={{
              lat: employeeData?.location?.lat || "",
              lng: employeeData?.location?.lng || "",
            }}
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
};

export default EmployeeCard;
