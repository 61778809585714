import { Cancel, Search } from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
} from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import _ from "lodash"; // Ensure lodash is installed for debounce functionality
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";

function EmployeeSearchBar({ clearOnSelect = false, onEmployeeSelect = () => {} }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessionCache, setSessionCache] = useState({});

  const handleChange = (event) => {
    setSearchTerm(
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
    );
  };

  useEffect(() => {
    console.log("running useEffect");
    if (searchTerm === "") {
      // Aggregate all unique employees from the cache.
      const allCachedEmployees = Object.values(sessionCache).flat();
      const uniqueCachedEmployeesMap = {};

      allCachedEmployees.forEach((employee) => {
        uniqueCachedEmployeesMap[employee.id] = employee;
      });

      const uniqueCachedEmployees = Object.values(uniqueCachedEmployeesMap);

      setEmployees(uniqueCachedEmployees);
      return;
    }

    if (sessionCache[searchTerm]) {
      // If results for the current term are cached, use them!
      setEmployees(sessionCache[searchTerm]);
      return;
    }

    setLoading(true);
    const debouncedSearch = _.debounce(searchEmployees, 500); // Debouncing to minimize the number of requests

    debouncedSearch(searchTerm);

    // Cleanup the debounce
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, sessionCache]);

  const searchEmployees = async (searchText) => {
    console.log("running searchEmployees");

    if (!searchText.trim()) {
      setEmployees([]); // or set it to default employees list if you have it.
      return;
    }

    setLoading(true);

    // Queries for each field
    const firstNameQuery = query(
      collection(db, "employees"),
      where("firstName", ">=", searchTerm),
      where("firstName", "<=", searchTerm + "\uf8ff")
    );
    const lastNameQuery = query(
      collection(db, "employees"),
      where("lastName", ">=", searchTerm),
      where("lastName", "<=", searchTerm + "\uf8ff")
    );

    try {
      // Fetch data separately
      const [firstNameSnapshot, lastNameSnapshot] = await Promise.all([
        getDocs(firstNameQuery),
        getDocs(lastNameQuery),
      ]);

      const resultSet = {}; // Using an object to store employees with unique IDs

      // Process each snapshot and add it to the object
      const processSnapshot = (snapshot) => {
        snapshot.forEach((doc) => {
          const employeeData = { id: doc.id, ...doc.data() };
          resultSet[employeeData.id] = employeeData; // uniqueness is maintained based on employee IDs
        });
      };

      processSnapshot(firstNameSnapshot);
      processSnapshot(lastNameSnapshot);

      // Convert the object values to an array
      const uniqueEmployees = Object.values(resultSet);

      setEmployees(uniqueEmployees);
      setLoading(false);

      // Storing data in session cache
      setSessionCache((prevState) => {
        return { ...prevState, [searchTerm]: uniqueEmployees };
      });
    } catch (error) {
      console.error("Error fetching employees: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleListItemClick = (employee) => {
    // Call the callback function passed through props
    onEmployeeSelect(employee);
  if (clearOnSelect) {
    setSearchTerm("")
    setEmployees([])
    setSessionCache({})
  }
  };

  const renderEmployeeList = () => {
    if (loading) {
      return (
        <div>
          <ListItemButton>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ marginRight: 2 }}
            />
            <ListItemText
              primary={<Skeleton variant="text" width={100} />}
              secondary={<Skeleton variant="text" width={200} />}
            />
          </ListItemButton>
        </div>
      );
    }
    return employees.map((employee) => (
      <ListItemButton
        key={employee.id}
        onClick={() => handleListItemClick(employee)}
        sx={{ borderRadius: "15px" }}
      >
        <Avatar sx={{ marginRight: 2 }} src={employee.avatarUrl}></Avatar>
        <ListItemText
          primary={`${employee.firstName} ${employee.lastName}`}
          secondary={`${employee.location.city}, ${employee.location.state}`}
        />
        {/* <Chip label={employee.status} /> */}
      </ListItemButton>
    ));
  };

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search for a employee..."
        value={searchTerm}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm && (
                <IconButton
                  aria-label="clear search"
                  onClick={() => setSearchTerm("")}
                  edge="end"
                >
                  <Cancel />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <List>{renderEmployeeList()}</List>
    </>
  );
}

export default EmployeeSearchBar;
