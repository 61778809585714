import {
  AccountBalance,
  ArrowForwardIosRounded,
  Event,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  collection,
  doc,
  endAt,
  onSnapshot,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { distanceBetween, geohashQueryBounds } from "geofire-common"; // Import necessary geohash functions
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { useEmployee } from "../../../providers/EmployeeProvider";
import VisitProvider from "../../../providers/VisitProvider";
import {
  decodeGeoHash,
  isPointInPolygon,
} from "../../../services/locationServices";
import ApplyDiscountForm from "../../ApplyDiscountForm";
import DiscountsList from "../../DiscountsList";
import ScheduleVisitAdmin from "../../ScheduleVisitAdmin";
import SendLoginLink from "../../SendLoginLink";
import VisitList from "../../VisitList";
import ResponsiveDialog from "../../dialogs/ResponsiveDialog";
import SignupFeePayment from "../../payment/SignupFeePayment";
import VisitOverviewCard from "../../visit/VisitOverviewCard";
import LogCommunicationDialog from "../communication/LogCommunicationDialog";
import ViewLogs from "../logs/ViewLogs";
import CardTabs from "../tabs/CardTabs";
import MemberActions from "./MemberActions";
import MemberDetails from "./MemberDetails";
import MemberInfo from "./MemberInfo";
import MemberStats from "./MemberStats";

// Load your publishable key from the environment variables or configuration
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY
);

const MemberCard = ({ memberId, isAdmin = false, openMemberCard }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [loadingPrivate, setLoadingPrivate] = useState(true);
  const [loadingVisits, setLoadingVisits] = useState(false);

  // Dialogs
  const [openViewVisits, setOpenViewVisits] = useState(false);
  const [openScheduleVisit, setOpenScheduleVisit] = useState(false);
  const [openSendLoginLink, setOpenSendLoginLink] = useState(false);
  const [openViewDiscounts, setOpenViewDiscounts] = useState(false);
  const [openViewInformation, setOpenViewInformation] = useState(false);
  const [openPaySignupFee, setOpenPaySignupFee] = useState(false);
  const [openManageAddress, setOpenManageAddress] = useState(false);
  const [openLogCommunication, setOpenLogCommunication] = useState(false);
  const [openApplyDiscount, setOpenApplyDiscount] = useState(false);

  // employee vars
  const { data } = useEmployee();
  const eId = data?.auth?.uid;
  const eDisplayName = `${
    data?.employee?.firstName
  } ${data?.employee?.lastName.charAt(0)}.`;
  const eAvatarUrl = data?.employee?.avatarUrl;

  // for calendar view
  const [unsubVisits, setUnsubVisits] = useState(null); // State to hold the unsubscribe function

  // To hold member data
  const [memberData, setMemberData] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [privateData, setPrivateData] = useState(null);
  const [visitsData, setVisitsData] = useState({});
  const [employeesData, setEmployeesData] = useState(null);

  const defaultAddressKey = privateData?.defaultAddress;
  const address = accountData?.addresses?.[defaultAddressKey];

  const handleScheduleVisit = () => {
    setOpenScheduleVisit(true); // Assuming you have a state for this as well
    console.log("handle schedule visit");
  };

  const handleSendLoginLink = () => {
    setOpenSendLoginLink(true); // Assuming a state for sending login links
    console.log("handle send login link");
  };

  const handleViewDiscounts = () => {
    setOpenViewDiscounts(true); // Assuming a state for viewing discounts
    console.log("handle view discounts");
  };

  const handlePaySignupFee = () => {
    setOpenPaySignupFee(true); // Assuming a state for viewing discounts
    console.log("handle pay signup fee");
  };

  const handleManageAddress = () => {
    setOpenManageAddress(true); // Assuming a state for viewing discounts
    console.log("handle manage address");
  };

  const handleApplyDiscount = () => {
    setOpenApplyDiscount(true); // Assuming a state for viewing discounts
    console.log("handle manage discount");
  };

  const handleLogCommunication = () => {
    setOpenLogCommunication(true); // Assuming a state for viewing discounts
    console.log("handle manage address");
  };

  const actions = [
    {
      icon: <Event color="primary" />,
      primary: "Schedule",
      secondary: "Schedule a new visit.",
      action: handleScheduleVisit,
    },
    // {
    //   icon: <CalendarViewDayRounded color="primary" />,
    //   primary: "Visits",
    //   secondary: "See all past and present visits.",
    //   action: handleViewVisits,
    // },
    // {
    //   icon: <ContactPage color="primary" />,
    //   primary: "Details",
    //   secondary: "See all member account and contact information.",
    //   action: handleViewInformation,
    // },

    // {
    //   icon: <ExitToApp color="primary" />,
    //   primary: "Send Login",
    //   secondary: "Send member a one-click login link via text and email.",
    //   action: handleSendLoginLink,
    // },
  ];

  // Conditionally add actions for admin users
  if (isAdmin) {
    actions.push(
      // {
      //   icon: <Autorenew color="primary" />,
      //   primary: "Membership",
      //   secondary: "View and change membership plan.",
      //   action: handleChangeMembership,
      // },
      ...(privateData?.signupFeeStatus !== "paid"
        ? [
            {
              icon: <AccountBalance color="primary" />,
              primary: "Signup Fee",
              secondary: "Pay sign up fee.",
              action: handlePaySignupFee,
            },
          ]
        : [])
    );
  }

  useEffect(() => {
    if (!memberId) return;

    setLoading(true);

    // Member data listener
    const unsubMember = onSnapshot(
      doc(db, "members", memberId),
      (docSnapshot) => {
        // Add the document ID under .id and spread the rest of the document data
        const dataWithId = {
          id: docSnapshot.id,
          ...docSnapshot.data(),
        };
        setMemberData(dataWithId);
      }
    );

    // Admin data listener
    const unsubAdmin = onSnapshot(
      doc(db, "members", memberId, "admin", "data"),
      (doc) => {
        setAdminData(doc.data());
      }
    );

    // Start loading for private data
    setLoadingPrivate(true);

    // Private data listener
    const unsubPrivate = onSnapshot(
      doc(db, "members", memberId, "private", "data"),
      (doc) => {
        setPrivateData(doc.data());

        // Stop loading after private data is fetched
        setLoadingPrivate(false);
      },
      (error) => {
        console.error("Error fetching private data:", error);
        // Stop loading if an error occurs
        setLoadingPrivate(false);
      }
    );

    setLoading(false);

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubMember();
      unsubAdmin();
      unsubPrivate();
    };
  }, [memberId]);

  useEffect(() => {
    if (!memberData?.location?.geohash) return;

    // employeesData listener
    const memberCoordinates = decodeGeoHash(memberData?.location?.geohash);
    // console.log("memberCoordinates", memberCoordinates);

    // Define the radius within which you want to find employees, in meters.
    const radiusInM = 10 * 1609.34; // e.g., 10 miles to meters

    const bounds = geohashQueryBounds(memberCoordinates, radiusInM);

    // This Map helps avoid duplicate employees and easily manage the real-time data
    const allEmployees = new Map();

    const unsubscribe = bounds.map(([start, end]) => {
      const q = query(
        collection(db, "employees"),
        where("status", "==", "active"),
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      // console.log("Bounds: ", bounds);

      return onSnapshot(q, (querySnapshot) => {
        const changes = querySnapshot.docChanges();
        changes.forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const employeeData = change.doc.data();

            const employeeMaxTravelMiles = employeeData.maxTravelMiles;
            let employeeCoordinates = decodeGeoHash(
              employeeData?.location?.geohash
            );

            const employeeDistance =
              distanceBetween(memberCoordinates, employeeCoordinates) *
              0.621371; // convert to miles

            // Assuming eventData.location.bounds is an array of { lat, lng } points defining the polygon
            // Assuming memberCoordinates are a { lat, lng } pair
            if (employeeData?.location?.bounds !== undefined) {
              if (
                isPointInPolygon(
                  memberCoordinates,
                  employeeData.location.bounds
                )
              ) {
                allEmployees.set(change.doc.id, employeeData);
                // console.log("Employee inside: ", employeeData.firstName);
              }
            } else if (employeeDistance <= employeeMaxTravelMiles) {
              allEmployees.set(change.doc.id, employeeData);
              // console.log("Employee inside: ", employeeData.firstName);
            }
          } else if (change.type === "removed") {
            // Employee has been removed from the query results, remove them from the Map
            allEmployees.delete(change.doc.id);
          }
        });

        // Extract a plain object from the Map
        const employeesData = Object.fromEntries(allEmployees);

        // Update state directly with employeesData
        setEmployeesData(employeesData);
      });
    });

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubscribe.forEach((unsub) => unsub());
    };
  }, [memberData?.location?.geohash]);

  useEffect(() => {
    if (!adminData?.accountId) return;

    // Member data listener
    const unsubAccount = onSnapshot(
      doc(db, "accounts", adminData?.accountId),
      (doc) => {
        setAccountData(doc.data());
      }
    );

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubAccount();
    };
  }, [adminData?.accountId]);

  useEffect(() => {
    if (!openMemberCard) {
      // When the Member Card dialog closes, clean up the listener
      if (unsubVisits) {
        unsubVisits();
        setUnsubVisits(null);
        console.log("Unsubscribing from visits listener");
      }
      return;
    }

    if (unsubVisits) {
      // If there's already a listener, no need to set up another
      console.log("Listener already set up");
      return;
    }

    if (!memberId) return;

    setLoadingVisits(true);

    // Set up the listener only when the dialog opens
    const visitsQuery = query(
      collection(db, "visits"),
      where("member", "==", memberId)
    );

    const unsubscribe = onSnapshot(visitsQuery, (snapshot) => {
      const visitsData = snapshot.docs
        .filter((doc) => doc.data().status !== "cancelled") // Exclude cancelled visits
        .reduce((acc, doc) => {
          // Use the doc.id as the key and the doc data as the value
          acc[doc.id] = {
            ...doc.data(),
            id: doc.id, // Include the id inside the visit data as well
          };
          return acc;
        }, {});

      console.log("Subscribing to visit data");
      setVisitsData(visitsData); // Ensure setVisitsData can handle an object structure
      setLoadingVisits(false);
    });

    setUnsubVisits(() => unsubscribe); // Store the unsubscribe function
  }, [openMemberCard]);

  const upcomingVisit = Object.values(visitsData)
    .filter((visit) => visit.start.toDate() > new Date()) // Convert Firestore Timestamp to Date
    .sort((a, b) => a.start.toDate() - b.start.toDate())[0]; // Sort by start date

  // Define the structure to map tabs to components
  const tabs = [
    { label: "Logs", component: <ViewLogs id={memberId} employeeId={eId} /> },
    {
      label: "Visits",
      component: (
        <VisitList
          visits={visitsData}
          localEmployees={employeesData}
          adminView={true}
          eId={eId}
          eName={data?.employee?.firstName}
        />
      ),
    },
    {
      label: "Communication",
      component: (
        <ViewLogs id={memberId} employeeId={eId} filter={"communication"} />
      ),
    },
    {
      label: "Discounts",
      component: (
        <DiscountsList
          discounts={adminData?.discounts}
          memberId={memberData?.id}
          timeZoneId={memberData?.timeZoneId}
          isAdmin={true}
          memberPhone={privateData?.phone}
        />
      ),
    },
    {
      label: "Details",
      component: (
        <MemberDetails
          memberData={memberData}
          privateData={privateData}
          accountData={accountData}
          adminData={adminData}
        />
      ),
    },
  ];

  console.log("upcoming Visit: ", upcomingVisit);

  return (
    <>
      <DialogContent>
        <MemberInfo memberData={memberData} privateData={privateData} />
        <Collapse
          in={!loadingPrivate && privateData?.signupFeeStatus === undefined}
        >
          <Button
            color="warning"
            variant="outlined"
            onClick={handlePaySignupFee}
            sx={{
              mb: 2,
              p: 2,
              borderRadius: "15px",
              textTransform: "none",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AccountBalance
                  sx={{ color: theme.palette.warning.dark, mr: 1 }}
                />
                <Typography sx={{ color: theme.palette.warning.dark }}>
                  Pay sign up fee
                </Typography>
              </Box>
              <ArrowForwardIosRounded
                sx={{ color: theme.palette.warning.dark }}
              />
            </Box>
          </Button>
        </Collapse>
        <MemberStats
          memberData={memberData}
          visitsData={visitsData}
          adminData={adminData}
        />
        <VisitOverviewCard
          visit={upcomingVisit}
          loading={loadingVisits}
          localEmployees={employeesData}
          eId={eId}
          eName={data?.employee?.firstName}
        />
        <MemberActions
          phone={privateData?.phone}
          onScheduleVisit={handleScheduleVisit}
          onLogConvo={handleLogCommunication}
          onSendLoginLink={handleSendLoginLink}
          onApplyDiscount={handleApplyDiscount}
        />
        <CardTabs tabs={tabs} />
      </DialogContent>
      <ResponsiveDialog
        open={openViewVisits}
        onClose={() => setOpenViewVisits(false)}
        title={"Visits"}
        desktopAnchor={"right"}
        anchor={"right"}
        width={"600px"}
        showBackdrop={false}
        zIndex={1300}
        fullHeight={true}
      >
        {openViewVisits && (
          <DialogContent>
            <VisitList
              visits={visitsData}
              localEmployees={employeesData}
              adminView={true}
              eId={eId}
              eName={data?.employee?.firstName}
            />
          </DialogContent>
        )}
      </ResponsiveDialog>
      <ResponsiveDialog
        title={"Schedule a visit"}
        open={openScheduleVisit}
        onClose={() => setOpenScheduleVisit(false)}
        width="800px"
        fullHeight={true}
        maxHeight="583px"
      >
        {openScheduleVisit && (
          <VisitProvider
            employees={employeesData}
            member={{
              ...memberData,
              admin: { data: adminData },
              private: { data: privateData },
            }}
            memberId={memberData.id}
            address={address}
          >
            <ScheduleVisitAdmin
              handleClose={() => setOpenScheduleVisit(false)}
              adminMode={true}
              eId={eId}
              eName={data?.employee?.firstName}
            />
          </VisitProvider>
        )}
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openViewDiscounts}
        onClose={() => setOpenViewDiscounts(false)}
        title={"Discounts"}
        width={"600px"}
        desktopAnchor={"right"}
        anchor={"right"}
        fullHeight={true}
      >
        <DiscountsList
          discounts={adminData?.discounts}
          memberId={memberData?.id}
          timeZoneId={memberData?.timeZoneId}
          isAdmin={true}
          memberPhone={privateData?.phone}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openSendLoginLink}
        onClose={() => setOpenSendLoginLink(false)}
        title={"Send Login Link"}
        width={"600px"}
        desktopAnchor={"right"}
        anchor={"right"}
        fullHeight={true}
      >
        <SendLoginLink
          open={openSendLoginLink}
          onClose={() => setOpenSendLoginLink(false)}
          memberId={memberId}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openPaySignupFee}
        onClose={() => setOpenPaySignupFee(false)}
        title={"Signup Fee"}
        width={"600px"}
        desktopAnchor={"right"}
        anchor={"right"}
        fullHeight={true}
      >
        {openPaySignupFee && (
          <Elements stripe={stripePromise}>
            <SignupFeePayment
              customerId={adminData?.stripeId}
              memberId={memberData?.id}
              handleClose={() => setOpenPaySignupFee(false)}
              zipCode={memberData?.location?.zipCode}
              signupFeeStatus={privateData?.signupFeeStatus}
              locationStatus={memberData?.status}
              adminMode={true}
              mName={memberData?.firstName}
              eName={data?.employee?.firstName}
              eId={eId}
            />
          </Elements>
        )}
      </ResponsiveDialog>
      <LogCommunicationDialog
        open={openLogCommunication}
        handleClose={() => setOpenLogCommunication(false)}
        idsArr={[memberId, eId]}
        employee={{
          type: "employee",
          id: eId,
          displayName: eDisplayName,
          avatarUrl: eAvatarUrl,
        }}
        client={{
          type: "member",
          id: memberId,
          displayName: `${memberData?.firstName} ${memberData?.lastName.charAt(
            0
          )}.`,
          avatarUrl: memberData?.avatarUrl,
        }}
        collectionName={"members"}
        location={memberData?.location}
      />
      <ResponsiveDialog
        open={openApplyDiscount}
        onClose={() => setOpenApplyDiscount(false)}
        title={"Apply discount"}
        width={"600px"}
        desktopAnchor={"right"}
        anchor={"right"}
        fullHeight={true}
        showBackdrop={false}
      >
        <ApplyDiscountForm
          memberId={memberId}
          memberPhone={privateData?.phone}
        />
      </ResponsiveDialog>
    </>
  );
};

export default MemberCard;
