// -- components/employeeDashboard/EventsTab.js
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ResponsiveDrawer from "../../../components/ResponsiveDrawer";
import CreateEventForm from "../../../components/events/CreateEventForm";
import { useEmployee } from "../../../providers/EmployeeProvider";
import { planRoute } from "../../../services/eventServices";
import { formatDateRange } from "../../../services/dateServices";
import EventStatusChip from "../../../components/events/EventStatusChip";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import ResponsiveDialog from "../../../components/dialogs/ResponsiveDialog";
import ServiceOrderView from "../../../components/events/ServiceOrderView";

const EventsTab = () => {
  const { data } = useEmployee();
  const [loading, setLoading] = useState(false);
  const [openCreateEvent, setOpenCreateEvent] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [openEventDetails, setOpenEventDetails] = useState(false);

  const events = data?.events || {};
  const eventsEntries = Object.entries(events);
  const totalEvents = eventsEntries.length;
  const userId = data?.auth.uid;

  const handleEventClick = (eventId) => {
    setSelectedEventId(eventId);
    setOpenEventDetails(true);
  };

  return (
    <>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Events
        </Typography>
        <Typography variant="body1" marginBottom={2}>
          Manage local events, see sign up list and service order.
        </Typography>

        <List disablePadding disableGutters>
          {Object.entries(events).map(([eventId, event], index) => (
            <>
              {/* Only insert Divider if not the last item */}
              {index === totalEvents - 1 && <Divider />}
              <ListItemButton
                disableGutters
                key={event.id}
                onClick={() => handleEventClick(eventId)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "15px",
                  py: 2,
                }}
              >
                {event.mediaUrls && event.mediaUrls.length > 0 && (
                  <ListItemAvatar sx={{ mr: 2 }}>
                    <Avatar
                      src={event.mediaUrls[0]}
                      alt={event.title}
                      sx={{
                        width: 128,
                        height: 128,
                        borderRadius: "10px",
                      }} // Adjust width, height, and borderRadius as needed
                    />
                  </ListItemAvatar>
                )}
                <ListItemText
                  disableGutters
                  sx={{ my: 0 }}
                  primary={<Typography variant="h6">{event.title}</Typography>}
                  secondary={
                    <>
                      <Typography variant="body1" color="text.secondary">
                        {formatDateRange(event.eventDays)}
                      </Typography>
                      {/* <EventStatusChip
                        signUpList={event.signUpList}
                        maxSignUps={event.maxSignUps}
                        userId={userId}
                      /> */}
                    </>
                  }
                />
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <ArrowForwardIosRounded />
                </ListItemIcon>
              </ListItemButton>
            </>
          ))}
        </List>
      </DialogContent>

      <ResponsiveDrawer
        title={"Create an event"}
        width={"600px"}
        open={openCreateEvent}
        initialHeight="98vh"
        onOpen={() => setOpenCreateEvent(true)}
        onClose={() => {
          setOpenCreateEvent(false);
        }}
        hideBackdrop={false}
      >
        {openCreateEvent && (
          <CreateEventForm
            onClose={() => {
              setOpenCreateEvent(false);
            }}
          />
        )}
      </ResponsiveDrawer>

      <ResponsiveDialog
        open={openEventDetails}
        onClose={() => setOpenEventDetails(false)}
        title={"Event details"}
        fullHeight={true}
        width="600px"
        anchor={"right"}
      >
        <ServiceOrderView
          event={{ id: selectedEventId, ...events[selectedEventId] }}
          timeZoneId={data?.employee?.timeZoneId}
        />
      </ResponsiveDialog>

      <DialogActions
        sx={{
          justifyContent: { xs: "center", sm: "flex-start" },
          boxShadow: {
            xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            sm: "none",
          },
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenCreateEvent(true)}
          sx={{
            width: { xs: "100%", sm: "150px" },
            height: { xs: "60px", sm: "auto" },
          }}
        >
          Create Event
        </Button>
      </DialogActions>
    </>
  );
};

export default EventsTab;
