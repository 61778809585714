import {
  Button,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

import { GroupOutlined } from "@mui/icons-material";

import moment from "moment-timezone";
import AvatarStatusBadge from "../AvatarStatusBadge";
import EditHelpersDialog from "./EditHelpersDialog";

const HelpersListItem = ({
  localEmployees,
  visitEmployees,
  isOpenVisit,
  employeesNeeded,
  visitId,
  timeZoneId,
  start,
  end,
  handleClose = () => {},
  adminView = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);

  // Open edit dialog
  const handleOpen = () => {
    setOpen(true);
  };

  const isPastEvent = moment().isAfter(moment(start?.toDate()));

  const getResponseStatusText = (status) => {
    switch (status) {
      case "accepted":
        return "confirmed";
      case "declined":
        return "declined";
      case "needsAction":
        return "assigned"; // This is the special case you've shown above
      case "refunded":
        return "refunded"; // This is the special case you've shown above
      default:
        return "unknown status";
    }
  };

  // Function to compare visit window with employee windows
  const isAnyWindowDifferent = () => {
    if (!visitEmployees || !start || !end) {
      return false; // Return false if visit or necessary properties are missing
    }

    const visitStart = moment(start.toDate()).tz(timeZoneId);
    const visitEnd = moment(end.toDate()).tz(timeZoneId);

    // Check if any employee's window is different
    return Object.values(visitEmployees).some((employee) =>
      Object.values(employee.windows || {}).some((window) => {
        const windowStart = moment(window.start.toDate()).tz(timeZoneId);
        const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

        return !windowStart.isSame(visitStart) || !windowEnd.isSame(visitEnd);
      })
    );
  };

  // Determining how to display time format
  const employeeCount = visitEmployees && Object.keys(visitEmployees).length;
  const windowsAreDifferent = isAnyWindowDifferent();
  const shouldShowTimes = employeeCount > 1 && windowsAreDifferent;

  const renderEmployeePositions = (
    localEmployees,
    visitEmployees,
    isOpenVisit,
    employeesNeeded
  ) => {
    let positions = [];

    // If there are actual employees, we render them.
    if (visitEmployees) {
      positions = Object.entries(visitEmployees)
        .filter(
          ([, employee]) =>
            employee.responseStatus === "needsAction" ||
            employee.responseStatus === "accepted" ||
            employee.responseStatus === "refunded" ||
            (adminView && employee.responseStatus === "declined") // Show "declined" if adminView is true
        )
        .sort(([, employeeA], [, employeeB]) => {
          if (
            employeeA.responseStatus === "declined" &&
            employeeB.responseStatus !== "declined"
          ) {
            return 1; // employeeA (declined) goes after employeeB
          } else if (
            employeeA.responseStatus !== "declined" &&
            employeeB.responseStatus === "declined"
          ) {
            return -1; // employeeA goes before employeeB (declined)
          }
          // If both are in the same category, sort by displayName
          return employeeA.displayName.localeCompare(employeeB.displayName);
        })
        .map(([key, employee], index) => {
          return (
            <Stack key={key} direction="row" gap={1} alignItems="center">
              <ListItemAvatar>
                <AvatarStatusBadge
                  status={employee.responseStatus}
                  avatarUrl={
                    localEmployees[key]?.avatarUrl ||
                    visitEmployees[key]?.avatarUrl
                  }
                  displayName={localEmployees[key]?.firstName}
                  index={index}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${visitEmployees[key]?.displayName}`}
                secondary={`${getResponseStatusText(employee.responseStatus)}${
                  employee.declineReason ? ` - ${employee.declineReason}` : ""
                }`}
              />
              {shouldShowTimes && employee?.responseStatus !== "declined" && (
                <Typography
                  variant="body2"
                  sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "15px",
                    py: 1,
                    px: 2,
                    my: 1,
                  }}
                >
                  {employee.windows
                    ? Object.values(employee.windows).map(
                        (window, windowIndex) => (
                          <React.Fragment key={windowIndex}>
                            {formatTime(
                              moment(window.start.toDate()).tz(timeZoneId)
                            )}{" "}
                            -{" "}
                            {formatTime(
                              moment(window.end.toDate()).tz(timeZoneId)
                            )}
                            <br />
                          </React.Fragment>
                        )
                      )
                    : `${formatTime(moment(start?.toDate()).tz(timeZoneId))} - 
               ${formatTime(moment(end?.toDate()).tz(timeZoneId))}`}
                </Typography>
              )}
            </Stack>
          );
        });
    }

    // If the visit is open and it's still in the future, we add placeholders for the open positions.
    if (isOpenVisit && !isPastEvent) {
      positions = positions.concat(
        Array.from({ length: employeesNeeded }, (_, index) => (
          <Stack
            key={`open_position_${index}`}
            direction="row"
            gap={1}
            alignItems="center"
          >
            <ListItemAvatar>
              <AvatarStatusBadge
                status={"needsAction"}
                avatarUrl={""}
                displayName={""}
                index={-1}
              />
            </ListItemAvatar>
            <ListItemText primary={"Awaiting"} secondary={"open position"} />
          </Stack>
        ))
      );
    }

    return positions;
  };

  // Custom formatting function
  const formatTime = (momentObj) => {
    return momentObj.minute() === 0
      ? momentObj.format("ha").toLowerCase() // Formats as '3pm' if minutes are 00
      : momentObj.format("h:mma").toLowerCase(); // Formats as '3:30pm' otherwise
  };

  const renderContent = () => (
    <>
      <ListItemIcon sx={{ minWidth: "48px" }}>
        <GroupOutlined color="primary" />
      </ListItemIcon>

      <Stack direction="column" spacing={0}>
        {renderEmployeePositions(
          localEmployees || {},
          visitEmployees,
          isOpenVisit,
          employeesNeeded
        )}
      </Stack>
    </>
  );

  return (
    <>
      {/* Employees section */}

      {adminView ? (
        <>
          <ListItemButton sx={{ paddingX: "0px" }} onClick={handleOpen}>
            {renderContent()}
          </ListItemButton>
          
        </>
      ) : (
        <ListItem sx={{ paddingX: "0px" }}>{renderContent()}</ListItem>
      )}

      <EditHelpersDialog
        open={open}
        setOpen={setOpen}
        visitId={visitId}
        start={start}
        end={end}
        timeZoneId={timeZoneId}
        localEmployees={localEmployees}
        visitEmployees={visitEmployees}
      />
    </>
  );
};

export default HelpersListItem;
