import { Loader } from "@googlemaps/js-api-loader";
import { LocationOn } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Alert,
  AlertTitle,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ConfettiAnimation from "../../animations/ConfettiAnimation";
// import mapPng from "../../assets/search-location.png";
import mapPng from "../../assets/location-map.png";
import { addField } from "../../hooks/useLogPageVisit";
import useTrackVisibility from "../../hooks/useTrackVisibility";
import { fetchEmployeesInArea } from "../../services/locationServices";
import GetStartedButton from "../buttons/GetStartedButton";
import ContactUs from "../popup/ContactUs";

const ServiceLookup = ({ pageName = "" }) => {
  const [location, setLocation] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const ref = useTrackVisibility(() => {}, `${pageName}/"service lookup"`); // Pass the logMessage here

  const [address, setAddress] = useState("");
  const [latLng, setLatLng] = useState(null);
  const [statusMessage, setStatusMessage] = useState(""); // "Yes" or "Waitlist"
  const inputRef = useRef(null); // For referencing the input field
  const autoCompleteRef = useRef(null); // For storing the autocomplete instance
  const [employees, setEmployees] = useState(null); // Hold employees data from the query

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  // Initialize Google Autocomplete
  const loadAutoComplete = () => {
    const loader = new Loader({
      apiKey: apiKey,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      if (inputRef.current) {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            types: ["geocode"],
          }
        );

        // Event listener when user selects an address
        autoCompleteRef.current.addListener("place_changed", handlePlaceSelect);
      }
    });
  };

  // Handle place selection from autocomplete
  const handlePlaceSelect = () => {
    const place = autoCompleteRef.current.getPlace();

    console.log("place: ", place);

    if (place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      const addressComponents = {}; // Declare addressComponents here

      place.address_components.forEach((component) => {
        addressComponents[component.types[0]] = component.long_name;
      });

      const line1 = addressComponents.street_number
        ? `${addressComponents.street_number} ${addressComponents.route}`
        : addressComponents.route;
      const line2 = "";
      const city = addressComponents.locality;
      const state = addressComponents.administrative_area_level_1;
      const zipCode = addressComponents.postal_code;

      setLatLng({ lat, lng });
      setAddress(place.formatted_address); // Update address input to selected place
      // Store address components in the location state
      setLocation({
        line1: line1 || "",
        line2: line2 || "",
        city: city || "",
        state: state || "",
        zipCode: zipCode || "",
      });

      // Fetch employees using lat/lng and set the status message
      fetchEmployeesInArea(lat, lng)
        .then((employeesData) => {
          setEmployees(employeesData);
          // If employees are found, set the status message accordingly
          if (Object.keys(employeesData).length > 0) {
            setStatusMessage("Yes");
            // Add the address field
            addField(
              "_address",
              `${place.formatted_address}: ${Object.keys(employeesData).length} employees`
            );
          } else {
            setStatusMessage("Waitlist");
            // Add the address field
            addField("_address", `${place.formatted_address}: WAITLIST`);
          }
        })
        .catch((error) => {
          console.error("Error fetching employees:", error);
          setStatusMessage("Error");
        });
    }
  };

  console.log("location: ", location);

  // Clear the selected address and lat/lng
  const handleClear = () => {
    setAddress("");
    setLatLng(null);
    setLocation(null);
    setStatusMessage("");
    setEmployees(null);
  };

  // Load autocomplete when component mounts
  React.useEffect(() => {
    loadAutoComplete();
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        width: { xs: "auto", sm: "100%" },
        maxWidth: 500,
        margin: "auto",
        boxSizing: "border-box",
        mx: { xs: 2, sm: "auto" },
        pt: 10,
        pb: 10,
      }}
    >
      {statusMessage === "Yes" && <ConfettiAnimation />}{" "}
      {/* Render Confetti when service is available */}
      <Box
        display="flex" // Use flex display
        justifyContent="center" // Center child elements horizontally
        alignItems="center" // Optional: Center child elements vertically
        sx={{ height: "100%" }} // Set height if necessary
      >
        <Box
          component="img"
          src={mapPng}
          alt={"Map-search"}
          sx={{
            height: { xs: "125px", sm: "150px" },
            width: { xs: "125px", sm: "150px" },
            mb: 1,
          }}
        />
      </Box>
      <Typography align="center" variant="h3">
        Our Locations
      </Typography>
      <Typography align="center" variant="body1" color="text.secondary">
        Enter your approximate location and then select the best option from the
        dropdown to see if we're available in your neighborhood!
      </Typography>
      <Box sx={{ position: "relative", marginTop: 2 }}>
        <TextField
          color={
            statusMessage === ""
              ? "primary"
              : statusMessage === "Yes"
              ? "success"
              : statusMessage === "Waitlist"
              ? "info"
              : "error"
          }
          inputRef={inputRef}
          value={address}
          sx={{ width: "100%" }}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Enter your city, neighborhood, or address..."
          // label="Search location"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOn
                  color={
                    statusMessage === ""
                      ? "default"
                      : statusMessage === "Yes"
                      ? "success"
                      : statusMessage === "Waitlist"
                      ? "info"
                      : "error"
                  }
                />
              </InputAdornment>
            ),
          }}
        />
        {address && (
          <IconButton
            sx={{
              position: "absolute",
              right: 10,
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={handleClear}
          >
            <ClearIcon />
          </IconButton>
        )}
      </Box>
      {statusMessage && (
        <Alert
          severity={
            statusMessage === "Yes"
              ? "success"
              : statusMessage === "Waitlist"
              ? "info"
              : "error"
          }
          sx={{ my: 2 }}
        >
          <AlertTitle>
            {statusMessage === "Yes"
              ? "Available"
              : statusMessage === "Waitlist"
              ? "Coming soon"
              : "Error"}
          </AlertTitle>
          {statusMessage === "Yes"
            ? "Our service is available in your neighborhood. When you're ready for an extra hand, we're here to help!"
            : statusMessage === "Waitlist"
            ? "We will be available within the next 2-3 months. Please join our waitlist and we'll notify you once we begin servicing your area."
            : "There was an error fetching the service availability. Please try again later."}
        </Alert>
      )}
      {statusMessage === "Waitlist" && (
        <ContactUs buttonText="Join waitlist" location={location} />
      )}
      {statusMessage === "Yes" && (
        <GetStartedButton isStandalone={true} width={"100%"} />
      )}
    </Box>
  );
};

export default ServiceLookup;
