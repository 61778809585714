import { Box, Typography } from "@mui/material";
import React from "react";

const IconTextButton = ({ option, isSelected, onClick }) => {
  const handleClick = () => {
    onClick(option.text); // Call parent's onClick to handle the selection
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        width: "100%",
        height: { xs: "auto", sm: "auto" },
        minWidth: { xs: "80px", sm: "80px" },
        flex: { xs: "1 1 80px", sm: "1 1 80px" },
        padding: { xs: 1, sm: 2 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        border: `2px solid ${isSelected ? option?.color : "transparent"}`,
        backgroundColor: isSelected ? option?.color : "#f5f5f5",
        boxShadow: isSelected
          ? `0px 4px 12px ${option?.color}`
          : "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        transition: "all 0.3s ease",
        cursor: "pointer",
        "&:hover": {
          boxShadow: { sm: `0px 8px 16px rgba(0, 0, 0, 0.2)` },
          borderColor: { xs: "transparent", sm: option?.color },
        },
      }}
    >
      {option?.icon &&
        React.cloneElement(option.icon, {
          style: {
            color: isSelected ? "#fff" : "darkgray", // Apply color conditionally
            fontSize: "18px", // Adjust size if needed
          },
        })}
      {option?.image && (
        <Box
          component="img"
          src={option?.image}
          alt={option?.text}
          sx={{
            height: "36px",
            width: "36px",
            mb: 1,
            // Invert the image color when the button is selected
            filter: isSelected ? "invert(1)" : "none",
            transition: "filter 0.3s ease",
          }}
        />
      )}
      {option?.text && (
        <Typography
          variant="body1"
          sx={{ color: isSelected ? "#fff" : "#000" }}
        >
          {option?.text}
        </Typography>
      )}
    </Box>
  );
};

export default IconTextButton;
