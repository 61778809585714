import { ArrowForwardIosRounded, History } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import PushDrawer from "./PushDrawer";
import VisitListItem from "./VisitListItem"; // ensure this import is correct
import VisitCard from "./visit/VisitCard";

const VisitList = ({
  visits = {},
  localEmployees = {},
  adminView = false,
  eId = "",
  eName = "",
}) => {
  console.log("VISIT LIST VISITS: ", visits);

  const nextVisitRef = useRef(null);
  const [selectedVisit, setSelectedVisit] = useState();
  const [showVisit, setShowVisit] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State to track the number of months loaded
  const [loadedMonths, setLoadedMonths] = useState(0);

  // Get the start of the current month in Unix timestamp
  const startOfCurrentMonth = moment().startOf("month");

  // Function to load more past visits
  const loadMoreVisits = () => {
    setLoadedMonths(loadedMonths + 1);
  };

  // Identify the next visit
  const nowUnix = moment().unix(); // Get the current time in Unix timestamp once, to avoid recalculating it every iteration
  const nextVisit = Object.values(visits).find(
    (visit) => visit.start.seconds > nowUnix
  );

  // Keep selected visit up to date with updated information
  useEffect(() => {
    if (selectedVisit) {
      // Check if the currently selected visit is still present in the updated visits array
      const updatedVisit = visits[selectedVisit.id];

      if (updatedVisit) {
        // If found, update the selectedVisit with the new data
        setSelectedVisit({ id: selectedVisit.id, visit: updatedVisit });
      } else {
        // If not found, handle accordingly (e.g., reset to null or select another visit)
        setSelectedVisit(null);
      }
    }
  }, [visits]); // Add visits to the dependency array

  const groupedVisits = Object.entries(visits).reduce(
    (acc, [visitId, visitData]) => {
      // Skip visits that are cancelled
      if (visitData.status === "cancelled") {
        return acc;
      }

      // Convert visit start seconds to a moment object
      const visitStartMoment = moment(visitData.start.seconds * 1000);

      // Calculate how many months ago the visit was
      const visitMonthDiff = startOfCurrentMonth.diff(
        visitStartMoment,
        "months",
        true
      );

      // Only include visits from this month and additional loaded months
      if (visitMonthDiff < loadedMonths) {
        const monthYear = moment(visitData.start.seconds * 1000).format(
          "MMMM YYYY"
        );
        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push({ id: visitId, visit: visitData });

        // Sort the visits within each monthYear group
        acc[monthYear].sort((a, b) => {
          return moment(a.visit.start.seconds * 1000).diff(
            moment(b.visit.start.seconds * 1000)
          );
        });
      }

      return acc;
    },
    {}
  );

  // Convert groupedVisits into an array and sort it
  const sortedGroupedVisits = Object.entries(groupedVisits).sort((a, b) => {
    const dateA = moment(a[0], "MMMM YYYY");
    const dateB = moment(b[0], "MMMM YYYY");
    return dateA.diff(dateB);
  });

  const handleVisitClick = (visitId, visitData) => {
    console.log("visitId: ", visitId);
    setSelectedVisit({ id: visitId, visit: visitData });
    setShowVisit(true);
  };

  const handleVisitClose = () => {
    setShowVisit(false);
    setSelectedVisit(null); // Set selectedVisit to null inline
  };

  if (Object.keys(visits).length === 0) {
    // Display the message if the visits array is empty
    return (
      <Typography align="center" marginTop={"24px"}>
        There are no upcoming visits.
      </Typography>
    );
  }

  return (
    <>
      <PushDrawer
        title={"Visit Details"}
        minWidth={"600px"}
        open={showVisit}
        onOpen={() => setShowVisit(true)}
        onClose={handleVisitClose}
        hideBackdrop={false}
      >
        {selectedVisit && (
          <VisitCard
            key={selectedVisit.id}
            visit={selectedVisit.visit}
            visitId={selectedVisit.id}
            localEmployees={localEmployees}
            handleClose={handleVisitClose}
            adminView={adminView}
            eId={eId}
            eName={eName}
          />
        )}
      </PushDrawer>
      {/* Box component to center the button */}
      <Box display="flex" justifyContent="center">
        <Button
          variant="text"
          color="primary"
          onClick={loadMoreVisits}
          startIcon={<History />}
          sx={{
            color: theme.palette.grey[500],
            height: { xs: "60px", sm: "56px" },
            textTransform: "none",
            width: { xs: "100%", sm: "200px" },
          }}
        >
          Load previous month
        </Button>
      </Box>
      <Stack
        sx={{
          gap: { xs: 2, sm: 4 },
        }}
      >
        {sortedGroupedVisits.map(([monthYear, visitsArray]) => (
          <div key={visitsArray[0].id}>
            <Typography
              variant="h6"
              fontWeight="500"
              align={isMobile ? "center" : "left"}
              sx={{
                color: theme.palette.grayscale.dark,
                px: "24px", // This applies the padding on the X-axis (left and right)
                py: 1,
              }}
            >
              {monthYear}
            </Typography>
            <ButtonGroup
              orientation="vertical"
              variant="outlined"
              color="primary"
              fullWidth
              sx={{
                borderRadius: "15px",
                borderColor: "divider",
                borderWidth: 1,
                borderStyle: "solid",
                boxSizing: "border-box",
                bgcolor: "white",
              }}
            >
              {visitsArray.map(({ id: visitId, visit: visitData }) => (
                <ButtonBase
                  key={visitId}
                  onClick={() => handleVisitClick(visitId, visitData)}
                  sx={{
                    display: "flex", // Ensure contents are aligned in a row
                    justifyContent: "space-between", // Space between the list item and the icon
                    alignItems: "center", // Center items vertically
                    flexGrow: 1,
                    borderColor: "divider",
                    borderWidth: "0 0 1px 0",
                    borderStyle: "solid",
                    paddingLeft: isMobile ? 2 : 4,
                    paddingRight: isMobile ? 1 : 2,
                    paddingY: 2,

                    borderRadius: "0px",
                    transition: "0.3s",
                    "&:last-of-type": {
                      borderBottom: "none",
                      borderBottomLeftRadius: "15px",
                      borderBottomRightRadius: "15px",
                    },
                    "&:first-of-type": {
                      borderTop: "none",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    },
                    backgroundColor:
                      selectedVisit?.id === visitId
                        ? "lightblue"
                        : "transparent", // Selected state color
                    "&:hover": {
                      backgroundColor: "action.hover", // MUI's hover color from the theme
                      // If you want the hover color to be different when selected:
                      ...(selectedVisit?.id === visitId && {
                        backgroundColor: "lightblue", // Adjust the color for selected hover state if necessary
                      }),
                    },
                  }}
                >
                  <VisitListItem
                    key={visitId}
                    visit={visitData}
                    ref={visitData === nextVisit ? nextVisitRef : null}
                  />
                  <ArrowForwardIosRounded color="primary" sx={{ ml: 2 }} />
                  {/* Icon added here */}
                </ButtonBase>
              ))}
            </ButtonGroup>
          </div>
        ))}
      </Stack>
    </>
  );
};

export default VisitList;
