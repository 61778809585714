import { Box } from "@mui/material";
import { DateTime } from "luxon"; // Ensure this package is installed
import React from "react";
import FactBox from "../../boxes/FactBox";

const MemberStats = ({ memberData, visitsData, adminData }) => {
  // Filter visits where the `start` timestamp is in the past
  const pastVisitsCount = visitsData
    ? Object.values(visitsData).filter(
        (visit) => DateTime.fromJSDate(visit.start.toDate()) < DateTime.now()
      ).length
    : 0;

  // Define the statistics data structure
  const stats = [
    {
      label: "Joined",
      value:
        memberData?.admin?.created || adminData?.created
          ? DateTime.fromJSDate(
              adminData?.created
                ? adminData.created.toDate()
                : memberData.admin.created.toDate()
            ).toFormat("M/d/yy")
          : "N/A",
    },
    {
      label: "Total Visits",
      value: pastVisitsCount,
    },
    // {
    //   label: "Joined",
    //   value: memberData?.createdAt
    //     ? DateTime.fromJSDate(memberData.createdAt.toDate()).toFormat(
    //         "MMM yyyy"
    //       )
    //     : "N/A",
    // },
    {
      label: "Last Contacted",
      value: memberData?.crm?.lastContactedDate
        ? DateTime.fromJSDate(
            memberData.crm.lastContactedDate.toDate()
          ).toFormat("EEE, MMM d")
        : "N/A",
    },
    {
      label: "Follow Up Date",
      value: memberData?.crm?.followUpDate
        ? DateTime.fromJSDate(memberData.crm.followUpDate.toDate()).toFormat(
            "EEE, MMM d"
          )
        : "N/A",
    },
  ];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
        gap: 2,
        width: "100%",
      }}
    >
      {stats.map((stat, index) => (
        <FactBox key={index} value={stat.value} label={stat.label} />
      ))}
    </Box>
  );
};

export default MemberStats;
