import { Card, CardContent, Divider } from "@mui/material";
import React from "react";
import ViewLogs from "../logs/ViewLogs";
import LeadActions from "./LeadActions";
import LeadDetails from "./LeadDetails";

const LeadCard = ({ lead, employeeId }) => {
  const { phone, name, crm } = lead;

  console.log("lead: ", lead);


  return (
    <Card sx={{ width: "100%", marginBottom: 2 }}>
      <CardContent>
        {/* Lead Details */}
        <LeadDetails phone={phone} name={name} crm={crm} />

        <Divider sx={{ marginY: 2 }} />

        {/* Action Buttons */}
        <LeadActions
          leadId={lead?.id}
          leadName={lead?.name}
          leadAvatarUrl={null}
          leadLocation={lead?.location}
        />

        <Divider sx={{ marginY: 2 }} />

        {/* Logs Section */}
        <ViewLogs id={lead?.id} employeeId={employeeId} />
      </CardContent>
    </Card>
  );
};

export default LeadCard;
