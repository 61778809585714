import {
  Chip,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

import { formatDate, formatTimeRange } from "../../services/dateServices";

import { AccessTime, Edit } from "@mui/icons-material";

import moment from "moment-timezone";
import EditDateTimeDialog from "./EditDateTimeDialog";

const DateTimeListItem = ({
  flags,
  employees,
  start,
  end,
  status,
  timeZoneId,
  visitId,
  adminView = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);

  // Open edit dialog
  const handleOpen = () => {
    setOpen(true);
  };

  const isRefunded = flags && flags.includes("refundIssued");

  // Function to compare visit window with employee windows
  const isAnyWindowDifferent = () => {
    if (!employees || !start || !end) {
      return false; // Return false if visit or necessary properties are missing
    }

    const visitStart = moment(start.toDate()).tz(timeZoneId);
    const visitEnd = moment(end.toDate()).tz(timeZoneId);

    // Check if any employee's window is different
    return Object.values(employees).some((employee) =>
      Object.values(employee.windows || {}).some((window) => {
        const windowStart = moment(window.start.toDate()).tz(timeZoneId);
        const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

        return !windowStart.isSame(visitStart) || !windowEnd.isSame(visitEnd);
      })
    );
  };

  // Determining how to display time format
  const employeeCount = employees && Object.keys(employees).length;
  const windowsAreDifferent = isAnyWindowDifferent();
  const shouldShowTimes = employeeCount > 1 && windowsAreDifferent;

  // Custom formatting function
  const formatTime = (momentObj) => {
    return momentObj.minute() === 0
      ? momentObj.format("ha").toLowerCase() // Formats as '3pm' if minutes are 00
      : momentObj.format("h:mma").toLowerCase(); // Formats as '3:30pm' otherwise
  };

  const renderContent = () => (
    <>
      <ListItemIcon sx={{ minWidth: "48px" }}>
        <AccessTime color="primary" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Stack direction="row" alignItems={"center"} gap={1}>
            <Typography variant="h6">
              {formatDate(moment(start?.toDate()).toDate())}
            </Typography>
            {status === "reschedule" && (
              <Chip
                label="Reschedule"
                color="success"
                size="small"
                sx={{
                  zIndex: 1,
                  borderRadius: "5px",
                  // ...(isMobile ? chipStyles : desktopChipStyles),
                }}
              />
            )}
            {status === "cancelled" && (
              <Chip
                label="Cancelled"
                color="error"
                size="small"
                sx={{
                  zIndex: 1,
                  borderRadius: "5px",
                  // ...(isMobile ? chipStyles : desktopChipStyles),
                }}
              />
            )}
            {isRefunded && (
              <Chip
                label="Refund issued"
                color="success"
                size="small"
                sx={{
                  zIndex: 1,
                  borderRadius: "5px",
                  // ...(isMobile ? chipStyles : desktopChipStyles),
                }}
              />
            )}
          </Stack>
        }
        secondary={
          !shouldShowTimes && (
            <Typography variant="body1">
              {Object.entries(employees)[0]?.[1]?.windows
                ? Object.values(Object.entries(employees)[0][1].windows).map(
                    (window, index) => (
                      <React.Fragment key={index}>
                        {formatTime(
                          moment(window.start.toDate()).tz(timeZoneId)
                        )}{" "}
                        -{" "}
                        {formatTime(moment(window.end.toDate()).tz(timeZoneId))}
                        <br />
                      </React.Fragment>
                    )
                  )
                : formatTimeRange(
                    moment(start?.toDate()).toDate(),
                    moment(end?.toDate()).toDate()
                  )}
            </Typography>
          )
        }
      />
    </>
  );

  return (
    <>
      {adminView ? (
        <ListItemButton sx={{ paddingX: "0px" }} onClick={handleOpen}>
          {renderContent()}
          <IconButton aria-label="comment" onClick={handleOpen}>
            <Edit />
          </IconButton>
        </ListItemButton>
      ) : (
        <ListItem sx={{ paddingX: "0px" }}>{renderContent()}</ListItem>
      )}
      <EditDateTimeDialog
        open={open}
        setOpen={setOpen}
        visitId={visitId}
        start={start}
        end={end}
        timeZoneId={timeZoneId}
      />
    </>
  );
};

export default DateTimeListItem;
