import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useEmployee } from "../../../providers/EmployeeProvider";
import LogCommunicationDialog from "../communication/LogCommunicationDialog";

const LeadActions = ({ leadId, leadName, leadAvatarUrl, leadLocation = {} }) => {
  const [openLogCommunication, setOpenLogCommunication] = useState(false);

  const { data } = useEmployee();
  const eId = data?.auth?.uid;
  const eDisplayName = `${
    data?.employee?.firstName
  } ${data?.employee?.lastName.charAt(0)}.`;
  const eAvatarUrl = data?.employee?.avatarUrl;

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenLogCommunication(true)}
        >
          Log communication
        </Button>
        <Button variant="contained" color="secondary">
          Become a Member
        </Button>
      </Box>
      <LogCommunicationDialog
        open={openLogCommunication}
        handleClose={() => setOpenLogCommunication(false)}
        idsArr={[leadId, eId]}
        employee={{
          type: "employee",
          id: eId,
          displayName: eDisplayName,
          avatarUrl: eAvatarUrl,
        }}
        client={{
          type: "lead",
          id: leadId,
          displayName: leadName,
          avatarUrl: leadAvatarUrl,
        }}
        collectionName={"leads"}
        location={leadLocation}
      />
    </>
  );
};

export default LeadActions;
