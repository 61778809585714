import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  FormControl,
  List,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import moment from "moment-timezone";
import ResponsiveDialog from "./dialogs/ResponsiveDialog";
import VisitSummary from "./VisitSummary";
import { useEmployee } from "../providers/EmployeeProvider";
import { onAcceptVisit } from "../services/employeeServices";
import { useSnackbar } from "../providers/SnackbarProvider";

const EmployeeAcceptVisit = ({
  open,
  setOpen,
  visit,
  visitId,
  handleAccept = () => {},
  loading,
  setLoading,
  handleBack = () => {}, // may be deprecated 12/13
}) => {
  // State to manage the radio button selection
  const [confirmationType, setConfirmationType] = useState("this_visit");
  const { showSnackbar } = useSnackbar();

  const { data } = useEmployee();
  const currentEmployeeId = data?.auth?.uid;
  const visits = data?.visits;

  const timeZoneId = visit?.timeZoneId;
  const now = moment().tz(timeZoneId);
  const startMoment = moment(visit?.start.toDate());
  const endMoment = moment(visit?.end.toDate());
  const isRecurring = visit?.recurrence?.frequency;
  const frequency = visit?.recurrence?.frequency;

  const handleRadioChange = (event) => {
    setConfirmationType(event.target.value);
  };

  const handleAcceptVisit = async () => {
    await onAcceptVisit({
      confirmationType,
      currentEmployeeId,
      visit,
      visitId,
      visits, // map of all loaded visit objects for the employee
      handleAccept, // Pass handleAccept as a callback
      setLoading, // Pass setLoading as a callback
      showSnackbar,
    });
    setOpen(false);
  };

  // Check if it's a recurring visit and render radio buttons
  const renderConfirmationOptions = () => {
    return (
      <FormControl
        component="fieldset"
        sx={{ width: "100%", mb: "16px", borderRadius: "15px" }}
      >
        <RadioGroup
          name="confirmationType"
          value={confirmationType}
          onChange={handleRadioChange}
        >
          <List sx={{ py: 0 }}>
            {/* List item for the 'This visit' option */}
            <ListItemButton
              selected={confirmationType === "this_visit"}
              onClick={() => setConfirmationType("this_visit")}
              sx={{ borderRadius: "15px", width: "100%" }}
            >
              <Radio
                checked={confirmationType === "this_visit"}
                value="this_visit"
              />
              <ListItemText primary="This visit" />
            </ListItemButton>

            {/* List item for the 'This and all future visits' option */}
            <ListItemButton
              selected={confirmationType === "all_visits"}
              onClick={() => setConfirmationType("all_visits")}
              sx={{ borderRadius: "15px", width: "100%" }}
            >
              <Radio
                checked={confirmationType === "all_visits"}
                value="all_visits"
              />
              <ListItemText primary="This and following visits" />
            </ListItemButton>
          </List>
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={() => setOpen(false)}
      title={`Accept visit`}
      width={"400px"}
      dialogActions={
        <>
          <Button
            disabled={loading}
            onClick={() => {
              setOpen(false);
              handleBack();
            }}
            color="primary"
            variant="text"
            sx={{
              height: { xs: "60px", sm: "auto" },
              minWidth: { sm: "150px" },
              width: { xs: "50%", sm: "auto" },
            }}
          >
            Go Back
          </Button>
          <Button
            variant="contained"
            disableElevation={true}
            onClick={handleAcceptVisit}
            disabled={loading}
            color="primary"
            sx={{
              height: { xs: "50px", sm: "auto" },
              minWidth: { sm: "150px" },
              width: { xs: "50%", sm: "auto" },
            }}
          >
            {loading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "Accept"
            )}
          </Button>
        </>
      }
    >
      <DialogContent>
        <Typography variant="body1" style={{ marginBottom: "16px" }}>
          {`This visit is part of a recurring series.`}
        </Typography>
        <Box sx={{ mb: 2 }}>
          <VisitSummary
            start={startMoment}
            end={endMoment}
            frequency={frequency}
            timeZoneId={timeZoneId}
            spacing="compact"
          />
        </Box>

        {frequency > 0 && renderConfirmationOptions()}
      </DialogContent>
    </ResponsiveDialog>
  );
};

export default EmployeeAcceptVisit;
