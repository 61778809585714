// -- components/memberDashboard/Settings.js
import { Box, DialogContent, useMediaQuery, useTheme } from "@mui/material";

import React from "react";
import ResponsiveMenu from "../../../components/ResponsiveMenu";
import { useMember } from "../../../providers/MemberProvider";
import ContentHeader from "../../ContentHeader";
import AddressTab from "./AddressTab";
import NotificationsTab from "./NotificationsTab";
import ProfileTab from "./ProfileTab";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaymentIcon from "@mui/icons-material/Payment";
import BillingTab from "./BillingTab";

import { Checklist } from "@mui/icons-material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import ServicesGrid from "../../../pages/public/sections/ServicesGrid";

// Load your publishable key from the environment variables or configuration
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY
);

const Settings = () => {
  const { data } = useMember();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let adminData = data?.member?.admin?.data;
  let privateData = data?.member?.private?.data;
  let memberData = data?.member;
  let accountData = data?.account;

  console.log("memberData: ", memberData);


  const signOutUser = async () => {
    const auth = getAuth();
    console.log(auth.currentUser);
    try {
      navigate("/");
      await signOut(auth);
      console.log("User signed out successfully");
      // You can also set any state or dispatch any actions here if you're using Redux or React Context for global state management.
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const menuItems = [
    {
      title: "Profile",
      component: <ProfileTab />,
      icon: <AccountBoxIcon />,
      secondaryText: "Edit your personal information",
    },
    {
      title: "Address",
      component: (
        <AddressTab
          accountId={adminData?.accountId}
          memberId={memberData?.id}
          addresses={accountData?.addresses}
          defaultAddress={privateData?.defaultAddress}
          firstName={memberData?.firstName}
          lastName={memberData?.lastName}
          stripeId={adminData?.stripeId}
          phone={privateData?.phone}
        />
      ),
      icon: <HomeIcon />,
      secondaryText: "Manage your addresses",
    },
    {
      title: "Notifications",
      component: (
        <NotificationsTab
          memberId={data?.auth?.uid}
          initialNotifications={data?.member?.private?.data?.notifications}
        />
      ),
      icon: <NotificationsIcon />,
      secondaryText: "Set your notification preferences",
    },
    {
      title: "Billing",
      component: (
        <Elements stripe={stripePromise}>
          <BillingTab />
        </Elements>
      ),
      icon: <PaymentIcon />,
      secondaryText:
        "Set membership plan, payment methods, and autoPay settings",
    },
    ...(isMobile
      ? [
          {
            title: "Services",
            component: (
              <DialogContent>
                <ServicesGrid />
              </DialogContent>
            ),
            icon: <Checklist />,
            secondaryText: "See all the services that we can assist with",
          },
        ]
      : []),
  ];

  return (
    <Box>
      <ContentHeader title={"Settings"}></ContentHeader>
      <ResponsiveMenu menuItems={menuItems} />
    </Box>
  );
};

export default Settings;
