import { Container, Typography } from "@mui/material";
import React from "react";
import helpArrivesImg from "../../../assets/senior-help-arrives.png";
import bookVisitImg from "../../../assets/senior-help-book-visit.png";
import customerServiceImg from "../../../assets/senior-help-customer-service.png";

import CardGrid from "../../../components/CardGrid";

import { useTheme } from "@mui/material"; // Don't forget to import useTheme
import GetStartedButton from "../../../components/buttons/GetStartedButton";
import useTrackVisibility from "../../../hooks/useTrackVisibility";

function HowItWorks({ city = "", pageName = "" }) {

  const ref = useTrackVisibility(() => {},
  `${pageName}/"how it works"`); // Pass the logMessage here

  // const theme = useTheme(); // hook to get current theme
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const theme = useTheme();

  const items = [
    {
      imageName: customerServiceImg,
      header: "Become A Member",
      subText:
        "Spend 5 minutes to become a member online or over the phone. Please call if you have any specific questions.",
      // buttonText: "Get Started",
      // buttonDestination: "/member/signup",
    },
    {
      imageName: bookVisitImg,
      header: "Schedule Your Visit",
      subText:
        "Describe the tasks you need help with, select your preferred employee, and pick the day and time that works best for you!",
      // buttonText: "Schedule",
      // buttonDestination: "/member/signup",
    },
    {
      imageName: helpArrivesImg,
      header: "To-Do's Complete!",
      subText:
        "Our trusted and hardworking employees will make quick work of your to-do list. Life is better with an extra pair of helping hands!",
      // buttonText: "Sign up",
      // buttonDestination: "/member/signup",
    },
    // ... Add more items as needed
  ];

  return (
    <div
    ref={ref}
      style={{
        // backgroundColor: theme.palette.primary.lightest,
        paddingTop: "32px",
        paddingBottom: "32px",
      }}
    >
      <Container sx={{ justifyContent: "center" }}>
        <Typography
          component="h2" // This changes the underlying element to an h1
          variant="h3"
          fontWeight="700"
          gutterBottom
          align="center"
        >
          How It Works
        </Typography>
        {/* <Typography variant="body1" sx={{ my: 2 }}>
          {`From gardening to tech help, you’re just a few steps away from
          realizing all of your “to-do list” hopes and dreams! Our senior help
          service${city && ` based in ${city}`} is tailored to meet your
          household needs. With a Linked Lives Membership, we’re just a call
          away when you need an extra pair of hands.`}
        </Typography> */}
      </Container>
      <CardGrid items={items} />
      <GetStartedButton />
    </div>
  );
}

export default HowItWorks;
