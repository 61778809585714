import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";
import React, { useState } from "react";
import { db, functions } from "../../firebase";
import { extractPhoneNumber } from "../../services/inputServices";
import PhoneField from "../fields/PhoneField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function UnlockFAQ({ handleSubmit }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [values, setValues] = useState({
    name: "",
    phone: "",
    zipCode: "",
    message: "",
    followUpDate: moment().add(7, "days"),
    timeOfDay: "afternoon", // Add this line
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [leadCaptured, setLeadCaptured] = useState(
    localStorage.getItem("leadCaptured") === "true"
  );

  const handleSave = async (event) => {
    event.preventDefault();
    if (!validateStep()) return;
    setLoading(true);

    let utmSource = localStorage.getItem("utm_source");
    let storedSessionId = localStorage.getItem("sessionId");

    const leadData = {
      name: values?.name || "",
      phone: extractPhoneNumber(values?.phone || ""),
      location: {
        zipCode: values?.zipCode | "",
      },
      crm: {
        leadCreated: new Date(),
        lastContactedDate: false,
        followUpDate: moment(values?.followUpDate || new Date()).toDate(),
        contactedCount: 0,
        source: "reminder_popup",
        timeline: {
          reminder_popup: new Date(),
        },
        utmSource: utmSource || "",
        message: `Follow up in ${values?.timeOfDay}`,
      },
    };

    console.log("leadData: ", leadData);

    const cleanData = (data) => {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([_, v]) => v !== undefined && v !== null && v !== "")
          .map(([k, v]) =>
            v && typeof v === "object" && !(v instanceof Date)
              ? [k, cleanData(v)]
              : [k, v]
          )
      );
    };

    // Clean leadData to avoid sending undefined, null, or empty values
    const filteredLeadData = cleanData(leadData);

    console.log("filteredLeadData: ", filteredLeadData);

    const createLead = httpsCallable(functions, "createLead");
    createLead({ ...filteredLeadData, sessionId: storedSessionId });

    // Save the lead to Firebase
    // await setDoc(doc(db, "leads", storedSessionId), filteredLeadData, {
    //   merge: true,
    // });

    // await addDoc(collection(db, "leads"), leadData);
    console.log("Lead saved successfully");

    if (storedSessionId) {
      try {
        await setDoc(
          doc(db, "sessions", storedSessionId),
          {
            _popupSubmitted: new Date(),
            _source: "reminder_popup",
            _name: values?.name || "",
            _phone: values?.phone || "",
          },
          { merge: true }
        );
      } catch (err) {
        console.log("error setting initial document: ", err);
      }
    }

    // Set a flag in localStorage indicating the form has been leadCaptured
    // localStorage.setItem("leadCaptured", "true");
    // localStorage.setItem("zipCode", values.zipCode);

    setLoading(false);
    setLeadCaptured(true);
    handleSubmit(values.zipCode);
  };

  const validateStep = () => {
    let tempErrors = {};
    // if (!values.name) tempErrors.name = "Please enter your name";

    if (!values.phone || values.phone.replace(/\D/g, "").length !== 10)
      tempErrors.phone = "Please enter your phone.";

    // Add validation for zipCode not to exceed 5 digits
    // if (!values.zipCode || values.zipCode.length < 5)
    //   tempErrors.zipCode = "Please enter your zip code.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const actualValue = type === "checkbox" ? checked : value;
    setValues((prevValues) => ({ ...prevValues, [name]: actualValue }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name];
      return newErrors;
    });
  };

  const handleZipChange = (e) => {
    // Only keep digits and limit to the first 5
    let value = e.target.value.replace(/\D/g, "").slice(0, 5);
    // Update the event's value before passing it to handleChange
    e.target.value = value;
    handleChange(e);
  };

  const handleDateChange = (newValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      followUpDate: newValue,
    }));
  };

  const renderOwner = () => (
    <Box
      fullWidth
      sx={{
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#F5F5F5",
        p: 1,
        my: 1,
        height: "fit-content",
        borderRadius: "50px",
      }}
    >
      <Avatar
        src={
          "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2F9nRhRtIUV1fdKXANdzbI4wGli4E2?alt=media&token=d1e3dfd6-5fe0-4bca-b762-2eb77b5792a6"
        }
        alt="Alex Rodriguez"
        sx={{ width: 64, height: 64 }}
      />
      <Box sx={{ ml: 2 }}>
        <Typography variant="body1">Alex Rodriguez</Typography>
        <Typography variant="body2" sx={{ fontStyle: "italic" }}>
          Owner, Linked Lives
        </Typography>
        {/* <Typography variant="body2">Mobile: (360) 336-6344</Typography> */}
      </Box>
      {/* <Call sx={{ ml: "auto", mr: 2 }} /> */}
    </Box>
  );

  return (
    <Box sx={{ flex: { xs: 1, sm: 0.5, width: "100%" } }}>
      <TextField
        name="name" // Add this line
        id="name"
        label="Name (optional)"
        type="text"
        fullWidth
        variant="outlined"
        value={values.name}
        onChange={handleChange}
        error={!!errors.name} // Show error state if there's an error for zipCode
        helperText={errors.name} // Display the actual error message
      />
      {/* <TextField
        multiline
        minRows={1}
        fullWidth
        variant="outlined"
        name="message"
        sx={{ mt: 1 }}
        label="Leave a message..."
        value={values.message}
        onChange={handleChange}
      /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: 2,
          mt: 2,
        }}
      >
        {/* <TextField
          name="zipCode"
          id="zipCode"
          label="Zip Code"
          type="tel" // Encourages a numeric keypad
          inputMode="numeric" // Suggests a numeric keyboard on devices that support inputMode
          fullWidth
          variant="outlined"
          value={values.zipCode}
          onChange={handleZipChange}
          sx={{ mb: 1 }}
          error={!!errors.zipCode} // Show error state if there's an error for zipCode
          helperText={errors.zipCode} // Display the actual error message
        /> */}

        <PhoneField
          phone={values.phone}
          error={errors.phone}
          handleChange={handleChange}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: 2,
          mt: 2,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker
            label="Follow-Up Date"
            value={values.followUpDate}
            fullWidth
            onChange={handleDateChange}
            renderInput={(params) => <TextField fullWidth {...params} />}
            sx={{ flex: 0.5 }}
          />
        </LocalizationProvider>
        <TextField
          select
          name="timeOfDay"
          label="Time of Day"
          sx={{ flex: 0.5 }}
          variant="outlined"
          value={values.timeOfDay}
          onChange={handleChange}
        >
          <MenuItem value="morning">Morning</MenuItem>
          <MenuItem value="afternoon">Afternoon</MenuItem>
          <MenuItem value="evening">Evening</MenuItem>
        </TextField>
      </Box>
      {values.followUpDate && !isMobile && (
        <Typography
          zIndex={"30"}
          position={"relative"}
          variant="caption"
          sx={{ mt: 1, ml: 1, fontStyle: "italic" }}
        >
          Alex will reach out in the {values.timeOfDay} on{" "}
          {moment(values.followUpDate).format("dddd, MMM Do")}
        </Typography>
      )}
      {values.followUpDate && !isMobile && renderOwner()}
      <Button
        variant="contained"
        size="large"
        fullWidth
        disabled={loading}
        onClick={handleSave}
        sx={{
          height: "56px",
          mt: { xs: 2, sm: 1 },
          textTransform: "none",
        }}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          `Unlock FAQ's`
        )}
      </Button>
      {/* <Button
        variant="text"
        size="large"
        fullWidth
        disabled={loading}
        onClick={handleSubmit}
        sx={{ height: "56px", textTransform: "none" }}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "No thanks"
        )}
      </Button> */}
      {/* {renderBBB()} */}
    </Box>
  );
}

export default UnlockFAQ;
