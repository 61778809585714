import { CheckCircle, Explore, ForumOutlined } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MembershipIcon from "@mui/icons-material/CardMembership"; // Example icon, replace with appropriate one
import LocationOnIcon from "@mui/icons-material/LocationOn"; // Example icon
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Collapse,
  DialogContent,
  Grid,
  IconButton,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import alexAvatar from "../assets/alex-avatar.png";
import { db } from "../firebase";
import PricingCards from "../pages/public/sections/PricingCards";
import { useMember } from "../providers/MemberProvider";
import { setMembershipPlan } from "../services/memberServices";
import AdSources from "./AdSources";
import AddAddress from "./AddAddress";
import StyledProgress from "./StyledProgress";
import ResponsiveDialog from "./dialogs/ResponsiveDialog";
import SignupFeePayment from "./payment/SignupFeePayment";

// Load your publishable key from the environment variables or configuration
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY
);

const NewMemberChecklist = () => {
  const [checked, setChecked] = useState([]);
  const { data, isServiceArea } = useMember(); // Assuming useMember is a custom hook
  const theme = useTheme();
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [openAdSources, setOpenAdSources] = useState(false);
  const [checklistComplete, setChecklistComplete] = useState(false);
  const [openPricingCards, setOpenPricingCards] = useState(false);
  const [openSignupFee, setOpenSignupFee] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  // For the membership plan selection
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handlePlanClick = async (plan) => {
    setIsLoading(true); // Start loading
    setSelectedPlan(plan.tier); // Set the selected plan

    try {
      // Call the cloud function with the necessary data
      await setMembershipPlan({
        plan: plan.tier,
        hourlyRate: plan.hourlyRate,
        membershipFee: plan.membershipFee,
        timeZoneId: data?.member?.timeZoneId,
      });

      console.log(`Plan Tier: ${plan.tier} set successfully`);

      // Update Firestore document for the member
      const memberPrivateDataRef = doc(
        db,
        "members",
        data?.auth?.uid,
        "private",
        "data"
      );

      await updateDoc(memberPrivateDataRef, {
        selectedPlan: true,
      });
      setOpenPricingCards(false);
    } catch (error) {
      console.error("Error setting membership plan:", error);
    } finally {
      setIsLoading(false); // Stop loading after the operation is complete
    }
  };

  let adminData = data?.member?.admin?.data;
  let privateData = data?.member?.private?.data;
  let memberData = data?.member;

  console.log("memberData: ", memberData);

  useEffect(() => {
    let newChecked = [];
    if (data?.member?.location !== undefined) newChecked.push(0);
    // if (data?.member?.private?.data?.selectedPlan !== undefined)
    //   newChecked.push(1);
    if (data?.member?.private?.data?.signupFeeStatus !== undefined)
      newChecked.push(1);
    if (data?.member?.private?.data?.adSources !== undefined)
      newChecked.push(2);
    setChecked(newChecked);

    // Check if all items are checked
    const allChecked = newChecked.length === checklistItems.length;

    if (allChecked) {
      // Delay of 2 seconds before writing to Firestore
      setTimeout(() => setChecklistComplete(true), 1000);
      setTimeout(() => updateChecklistComplete(), 3000);
    }
  }, [data]);

  const updateChecklistComplete = async () => {
    const userDocRef = doc(db, "members", data.auth.uid);

    try {
      await updateDoc(userDocRef, {
        checklistComplete: true,
      });
      console.log("Checklist complete status updated in Firestore");
    } catch (error) {
      console.error(
        "Error updating checklist complete status in Firestore:",
        error
      );
    }
  };

  const handleItemClick = (action, index) => {
    console.log("action, index: ", [action, index]);
    if (checked.includes(index)) return;
    setSelectedItem(action);
    action();
  };

  const updateReadPacket = async () => {
    const userDocPrivateRef = doc(
      db,
      "members",
      data.auth.uid,
      "private",
      "data"
    ); // Replace 'users' with your actual collection name and ensure you have the correct document ID

    try {
      await updateDoc(userDocPrivateRef, {
        readPacket: true,
      });
      console.log("Checklist updated in Firebase");
    } catch (error) {
      console.error("Error updating checklist in Firebase:", error);
    }
  };

  const checklistItems = [
    {
      label: "serviceAddress",
      primary: "Service address",
      secondary: "Enter your address to start booking visits.",
      Icon: LocationOnIcon,
      action: () => {
        setOpenAddAddress(true);
      },
    },
    // {
    //   label: "chooseMembership",
    //   primary: "Choose plan",
    //   secondary: "Select a plan that best fits your needs.",
    //   Icon: MembershipIcon,
    //   action: () => {
    //     setOpenPricingCards(true);
    //   },
    // },
    {
      label: "chooseMembership",
      primary: "Signup fee",
      secondary: "Enjoy help on-demand with your lifetime membership.",
      Icon: MembershipIcon,
      action: () => {
        setOpenSignupFee(true);
      },
    },
    {
      label: "referralSource",
      primary: "Referral source",
      secondary: "Share how you found out about our service.",
      Icon: ForumOutlined,
      action: () => {
        setOpenAdSources(true);
      },
    },
    // {
    //   label: "memberPacket",
    //   primary: "Member packet",
    //   secondary: "Understand our service and how we can help.",
    //   Icon: ReadIcon,
    //   action: () => {
    //     updateReadPacket();
    //     // setRescheduleDialogOpen(true);
    //   },
    // },
    // {
    //   label: "setAvatar",
    //   primary: "Profile picture",
    //   secondary: "Help our employees recognize you more easily.",
    //   Icon: AvatarIcon,
    //   action: () => {
    //     // Add action to open avatar upload dialog or functionality
    //   },
    // },
    // {
    //   label: "getMobileApp",
    //   primary: "Mobile app",
    //   secondary: "Create a shortcut for the best experience.",
    //   Icon: MobileAppIcon,
    //   action: () => {
    //     // Add action to show information about downloading the mobile app
    //   },
    // },
  ];

  const handleSetPlan = (value) => {
    console.log("plan: ", value);
  };

  const completionRate = 5 + (checked.length / checklistItems.length) * 95;
  const completionMessage = `${checked.length} of ${checklistItems.length} complete`;

  return (
    <>
      <Collapse in={!checklistComplete} timeout={1000}>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 1, sm: 2 },
          }}
        >
          <Card
            variant="outlined"
            sx={{
              p: 2,
              mb: 1,
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ maxWidth: "400px" }}>
              <Typography variant="h6" gutterBottom>
                Welcome {data?.member?.firstName},
              </Typography>

              {isServiceArea !== false && (
                <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                  Thanks for joining our growing Linked Lives community. Please
                  take a few minutes to work through our new member checklist.
                  I'm glad you're here!
                </Typography>
              )}
              {isServiceArea === false && (
                <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                  I apologize, our services are not yet available in your area
                  quite yet. Below, you'll find information on the waitlist
                  where you can see our progress towards reaching your town and
                  speed things up by inviting others. I appreciate your
                  patience!
                </Typography>
              )}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src={alexAvatar}
                  alt="Alex Rodriguez"
                  sx={{ width: 64, height: 64 }}
                />
                <Box sx={{ ml: 2 }}>
                  <Typography variant="body1">Alex Rodriguez</Typography>
                  <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                    Owner, Linked Lives
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
          <Card
            variant="outlined"
            sx={{ p: 2, mb: 1, boxSizing: "border-box", width: "100%" }}
          >
            <Box sx={{ mb: 2, display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "baseline",
                  mb: 2,
                }}
              >
                <Typography
                  variant="h6"
                  fontSize={"1.2rem"}
                  // fontWeight={"600"}
                >
                  New Member Checklist
                </Typography>
                <Typography align="center" variant="caption" sx={{ ml: 1 }}>
                  {completionMessage}
                </Typography>
              </Box>
              <Box sx={{ mx: -3 }}>
                <StyledProgress
                  variant="determinate"
                  value={completionRate}
                  color={theme.palette.success.main} // Set the color to success
                />
              </Box>
              {/*  */}
            </Box>
            <Grid container spacing={1}>
              {checklistItems.map((item, index) => (
                <Grid item xs={12} sm={12} md={12} key={index}>
                  <Card
                    variant="outlined"
                    sx={{
                      backgroundColor: checked.includes(index)
                        ? "#e0f2e9"
                        : "white",
                    }}
                  >
                    <ListItemButton
                      variant="outlined"
                      onClick={() => handleItemClick(item.action, index)}
                      // disabled={checked.includes(index)}
                      sx={{
                        display: "flex",
                        position: "relative",
                        flexDirection: "row",
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", mr: 2 }}
                      >
                        {checked.includes(index) ? (
                          <CheckCircle color="success" />
                        ) : (
                          <item.Icon
                            color={
                              checked.includes(index) ? "success" : "primary"
                            }
                          />
                        )}
                      </Box>
                      <CardContent
                        sx={{
                          flexGrow: 1,
                          pr: 6,
                          py: 0,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            color: checked.includes(index)
                              ? theme.palette.success.main
                              : theme.palette.primary.main,
                          }}
                        >
                          {item.primary}
                        </Typography>
                        {!checked.includes(index) && (
                          <Typography variant="body2">
                            {item.secondary}
                          </Typography>
                        )}
                      </CardContent>
                      <IconButton
                        onClick={() => handleItemClick(item.action, index)}
                        disabled={checked.includes(index)}
                        sx={{ position: "absolute", right: 8 }}
                      >
                        {!checked.includes(index) && <ArrowForwardIosIcon />}
                      </IconButton>
                    </ListItemButton>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Box>
      </Collapse>
      <ResponsiveDialog
        open={openAddAddress}
        onClose={() => setOpenAddAddress(false)}
        title={"Add Service Address"}
        width={"600px"}
      >
        {openAddAddress && (
          <AddAddress
            memberId={data?.auth?.uid}
            accountId={adminData?.accountId || data?.auth?.uid}
            firstName={data?.member?.firstName}
            lastName={data?.member?.lastName}
            stripeId={adminData?.stripeId}
            phone={privateData?.phone}
            handleSubmit={() => setOpenAddAddress(false)}
          />
        )}
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openSignupFee}
        onClose={() => setOpenSignupFee(false)}
        title={"Signup Fee"}
        width={"600px"}
      >
        {isServiceArea ? (
          <Elements stripe={stripePromise}>
            <SignupFeePayment
              customerId={adminData?.stripeId}
              memberId={data?.auth.uid}
              handleClose={() => setOpenSignupFee(false)}
              zipCode={memberData?.location?.zipCode}
              signupFeeStatus={privateData?.signupFeeStatus}
              adminMode={false}
              locationStatus={memberData?.status}
              mName={data?.member?.firstName}
            />
          </Elements>
        ) : (
          <DialogContent>
            <Box textAlign="center" sx={{ my: 2 }}>
              {/* Icon */}
              <Explore color="primary" sx={{ fontSize: 40, mb: 1 }} />
              {/* Header Text */}
              <Typography variant="h6" sx={{ mb: 1 }}>
                Hang Tight!
              </Typography>

              {/* Message */}
              <Typography sx={{ mb: 10 }}>
                {data?.member?.location === undefined
                  ? "Please enter your address first in the previous checklist item, so that we can confirm that we provide service in your neighborhood."
                  : "Thanks for your interest! Unfortunately, we haven't reached your town quite yet. Once service begins, we will let you know!"}
              </Typography>
            </Box>
          </DialogContent>
        )}
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openAdSources}
        onClose={() => setOpenAdSources(false)}
        title={"Referral Source"}
        width={"600px"}
      >
        {openAdSources && (
          <AdSources
            handleSubmit={() => setOpenAdSources(false)}
            memberDisplayName={`${data?.member?.firstName} ${data?.member?.lastName}`}
            memberId={data?.auth?.uid}
          />
        )}
      </ResponsiveDialog>
      <ResponsiveDialog
        open={openPricingCards}
        onClose={() => setOpenPricingCards(false)}
        title={"Select Your Plan"}
        width={"800px"}
      >
        {openPricingCards && (
          <DialogContent>
            {isServiceArea ? (
              <>
                {/* <Typography align="center" sx={{ mb: 1 }}>
                  Select the plan that works best for you.
                </Typography> */}
                <PricingCards
                  onClick={handlePlanClick}
                  dense={true}
                  buttonText="Select"
                  loading={isLoading}
                  loadingPlan={selectedPlan}
                  zipCode={data?.member?.location?.zipCode}
                />
              </>
            ) : (
              <Box textAlign="center" sx={{ my: 2 }}>
                {/* Icon */}
                <Explore color="primary" sx={{ fontSize: 40, mb: 1 }} />
                {/* Header Text */}
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Hang Tight!
                </Typography>

                {/* Message */}
                <Typography sx={{ mb: 10 }}>
                  {data?.member?.location === undefined
                    ? "Before you can select a plan, please enter your address so that we can confirm that we provide service in your neighborhood."
                    : "We haven't reached your town quite yet. Once service begins, we will let you know and you will be able to select a plan."}
                </Typography>
              </Box>
            )}
          </DialogContent>
        )}
      </ResponsiveDialog>
    </>
  );
};

export default NewMemberChecklist;
