import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import { convertStateNameToAbbreviation } from "../../../services/locationServices";
import ColorAvatar from "../../ColorAvatar";

const MemberInfo = ({ memberData, privateData }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 2,
        pb: 2,
        // border: "1px solid #e0e0e0",
        borderRadius: 1,
      }}
    >
      <ColorAvatar
        name={`${memberData?.firstName} ${memberData?.lastName}`}
        avatarUrl={memberData?.avatarUrl}
        size={96}
      />
      <Box> 
        <Typography variant="h6" component="div">
          {memberData?.firstName ? (
            `${memberData.firstName} ${memberData.lastName}`
          ) : (
            <Skeleton width="150px" />
          )}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {memberData?.location?.city &&
          memberData?.location?.state &&
          memberData?.location?.zipCode ? (
            `${memberData.location.city}, ${convertStateNameToAbbreviation(
              memberData.location.state
            )} ${memberData.location.zipCode}`
          ) : (
            <Skeleton width="120px" />
          )}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {privateData?.phone ? privateData.phone : <Skeleton width="80px" />}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {privateData?.email ? privateData.email : <Skeleton width="120px" />}
        </Typography>
      </Box>
    </Box>
  );
};

export default MemberInfo;
