import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  ListItem,
} from "@mui/material";
import React, { useState } from "react";

import {
  FamilyRestroom,
  LocalHospital,
  TimeToLeave,
  Schedule,
  Handyman,
  DoNotDisturbAlt,
  School,
  DirectionsCar,
  FitnessCenter,
  DirectionsWalk,
  Umbrella,
  Favorite,
  AccessTime,
  BeachAccess,
  EventBusy,
  MinorCrash,
  SportsFootball,
  DriveEta,
  AcUnit,
  Healing,
  EventRepeatOutlined,
  ThumbDown,
} from "@mui/icons-material";

import moment from "moment-timezone";
import { useSnackbar } from "../providers/SnackbarProvider";
import ResponsiveDialog from "./dialogs/ResponsiveDialog";
import VisitSummary from "./VisitSummary";
import { onDeclineVisit } from "../services/employeeServices";
import { useEmployee } from "../providers/EmployeeProvider";

const declineReasons = {
  family: {
    label: "Family commitment",
    icon: <FamilyRestroom style={{ color: "#f6b26b" }} />, // warm color for family
  },
  illness: {
    label: "Feeling unwell",
    icon: <LocalHospital style={{ color: "#e06666" }} />, // red for health
  },
  timeOff: {
    label: "Taking time-off",
    icon: <BeachAccess style={{ color: "#CCAE52" }} />, // yellow for relaxation
  },
  scheduleConflict: {
    label: "Scheduling conflict",
    icon: <EventBusy style={{ color: "#6aa84f" }} />, // green for scheduling
  },
  lackOfExperience: {
    label: "Lacking experience required",
    icon: <Handyman style={{ color: "#8e7cc3" }} />, // orange for work
  },
  serviceLimitation: {
    label: "Outside of service scope",
    icon: <DoNotDisturbAlt style={{ color: "#cc4125" }} />, // purple for limitation
  },
  academic: {
    label: "Academic obligation",
    icon: <School style={{ color: "#3d85c6" }} />, // blue for academia
  },
  extracurricular: {
    label: "Conflicts with extracurriculars",
    icon: <SportsFootball style={{ color: "#76a5af" }} />, // teal for sports
  },
  transportation: {
    label: "Personal transportation issues",
    icon: <MinorCrash style={{ color: "#999999" }} />, // grey for transportation
  },
  distance: {
    label: "Travel distance too far away",
    icon: <DriveEta style={{ color: "#45818e" }} />, // dark teal for distance
  },
  weather: {
    label: "Adverse weather conditions",
    icon: <AcUnit style={{ color: "#83BBC3" }} />, // light blue for weather
  },
  recoveryTime: {
    label: "Recovering from health issues",
    icon: <Healing style={{ color: "#d5a6bd" }} />, // pink for recovery
  },
  badExperience: {
    label: "Not a good fit",
    icon: <ThumbDown style={{ color: "#a64d79" }} />, // maroon for bad experience
  },
  other: {
    label: "No longer available",
    icon: <AccessTime style={{ color: "#666666" }} />, // dark grey for time
  },
};

const EmployeeDeclineVisit = ({
  open,
  setOpen,
  visit,
  visitId,
  handleDecline,
  loading,
  setLoading,
  employeeId,
}) => {
  // State to manage the radio button selection
  const [cancellationType, setCancellationType] = useState("this_visit");

  const { data } = useEmployee();
  const currentEmployeeId = data?.auth?.uid;
  const visits = data?.visits;
  const availabilityWindows = data?.employee?.public?.availability?.windows;

  const timeZoneId = visit?.timeZoneId;
  const now = moment().tz(timeZoneId);
  const isWithinWeek = visit?.start.toDate() - now < 7 * 24 * 60 * 60 * 1000;
  const startMoment = moment(visit?.start.toDate());
  const endMoment = moment(visit?.end.toDate());
  const { showSnackbar, showLoading } = useSnackbar();
  const isRecurring = visit?.recurrence?.frequency;
  const belongsToSeries = visit?.employees?.[employeeId]?.belongsToSeries;

  const [reason, setReason] = useState("");

  const frequency = visit?.recurrence?.frequency;
  const momentStart = moment(visit?.start?.toDate()).toDate();
  const momentEnd = moment(visit?.end?.toDate()).toDate();

  const handleRadioChange = (event) => {
    setCancellationType(event.target.value);
  };

  // Function to cancel the visit, now with a parameter for cancellation type
  const handleDeclineVisit = async () => {
    await onDeclineVisit({
      cancellationType, // Renamed from 'values' for clarity
      reason,
      currentEmployeeId,
      visit,
      visitId,
      visits, // map of all loaded visit objects for the employee
      handleDecline, // Pass handleAccept as a callback
      setLoading, // Pass setLoading as a callback
      availabilityWindows,
      showSnackbar,
    });
    setOpen(false);
  };

  // Utility functions for date and time formatting
  const formatDate = (date) => {
    return moment(date).format("dddd, MMM Do");
  };

  const formatTimeRange = (startDate, endDate) => {
    const startTime = moment(startDate).format("h:mm A");
    const endTime = moment(endDate).format("h:mm A");
    return `${startTime} - ${endTime}`;
  };

  // Check if it's a recurring visit and render radio buttons
  const renderCancellationOptions = () => {
    return (
      <FormControl
        sx={{ width: "100%", mb: "16px", borderRadius: "15px" }}
        component="fieldset"
      >
        <RadioGroup
          name="cancellationType"
          value={cancellationType}
          onChange={handleRadioChange}
        >
          <List sx={{ py: 0 }}>
            {/* List item for the 'This visit' option */}
            <ListItemButton
              fullWidth
              selected={cancellationType === "this_visit"}
              onClick={() => setCancellationType("this_visit")}
              sx={{ borderRadius: "15px" }}
            >
              <Radio
                checked={cancellationType === "this_visit"}
                value="this_visit"
              />
              <ListItemText primary="This visit" />
            </ListItemButton>

            {/* List item for the 'This and all future visits' option */}
            <ListItemButton
              fullWidth
              selected={cancellationType === "all_visits"}
              onClick={() => setCancellationType("all_visits")}
              sx={{ borderRadius: "15px" }}
            >
              <Radio
                checked={cancellationType === "all_visits"}
                value="all_visits"
              />
              <ListItemText primary="This and following visits" />
            </ListItemButton>
          </List>
        </RadioGroup>
      </FormControl>
    );
  };

  const DeclineReasonSelect = (reason, setReason) => {
    const handleChange = (event) => {
      setReason(event.target.value);
    };

    // Custom render value function to display both icon and label
    const renderValue = (selected) => {
      if (!selected) return;
      const { icon, label } = declineReasons[selected];
      return (
        <div
          style={{ display: "flex", alignItems: "center", overflow: "hidden" }}
        >
          {icon}
          <span
            style={{
              marginLeft: 8,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "calc(100% - 30px)", // Adjust width as needed
            }}
          >
            {label}
          </span>
        </div>
      );
    };

    return (
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="decline-reason-label">Reason for declining</InputLabel>
        <Select
          labelId="decline-reason-label"
          id="decline-reason-select"
          value={reason}
          label="Reason for declining"
          onChange={handleChange}
          displayEmpty
          renderValue={renderValue}
        >
          {Object.entries(declineReasons).map(([key, { label, icon }]) => (
            <MenuItem key={key} value={key}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primary={label}
                primaryTypographyProps={{ noWrap: true }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={() => setOpen(false)}
      title={`Decline visit`}
      width={"400px"}
      fullHeight={true}
      dialogActions={
        <>
          <Button
            disabled={loading}
            onClick={() => setOpen(false)}
            color="primary"
            variant="outlined"
            sx={{
              height: { xs: "60px", sm: "auto" },
              minWidth: { sm: "150px" },
              width: { xs: "50%", sm: "auto" },
            }}
          >
            No
          </Button>
          <Button
            onClick={handleDeclineVisit}
            disabled={loading || reason === ""}
            color="primary"
            variant="text"
            sx={{
              height: { xs: "50px", sm: "auto" },
              minWidth: { sm: "150px" },
              width: { xs: "50%", sm: "auto" },
            }}
          >
            {loading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              "Yes, Decline"
            )}
          </Button>
        </>
      }
    >
      <DialogContent>
        {isWithinWeek ? (
          <Alert severity="warning" style={{ marginBottom: "16px" }}>
            Respond promptly to give members time for rescheduling. Ensure your
            availability is up-to-date.
          </Alert>
        ) : (
          <Alert severity="info" sx={{ mb: 2 }}>
            Ensure your availability is up-to-date.
          </Alert>
        )}
        <Typography variant="body1" style={{ marginBottom: "16px" }}>
          {`Are you sure you want to decline this visit?`}
        </Typography>
        <Box sx={{ mb: 2 }}>
          <VisitSummary
            start={startMoment}
            end={endMoment}
            frequency={belongsToSeries ? frequency : null}
            timeZoneId={timeZoneId}
            spacing="compact"
          />
        </Box>

        {belongsToSeries && renderCancellationOptions()}
        {DeclineReasonSelect(reason, setReason)}
      </DialogContent>
    </ResponsiveDialog>
  );
};

export default EmployeeDeclineVisit;
