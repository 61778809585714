import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";

// Import your components

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const CardTabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={activeTab}
        onChange={handleChange}
        aria-label="Member Tabs"
        sx={{
          borderBottom: "1.5px solid rgba(0, 0, 0, 0.3)",
          justifyContent: { xs: "flex-start", sm: "center" },
          // boxShadow:
          //   "0px 5px 5px 0px rgba(0,0,0,0.1)",

          mx: { xs: -3, sm: -3 },
          "& .MuiTabs-indicator": {
            display: "none", // Hide the default indicator
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            sx={{
              color: activeTab === index ? "primary.main" : "text.secondary",
              borderBottom:
                activeTab === index ? "3px solid" : "3px solid rgba(0,0,0,0)",
              borderColor: activeTab === index ? "primary.main" : "none",
              padding: "12px 16px",
              textTransform: "none",
            }}
          />
        ))}
      </Tabs>

      {/* Render the corresponding component for the active tab */}
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={activeTab} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </>
  );
};

export default CardTabs;
