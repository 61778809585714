import {
  ChatOutlined,
  DirectionsOutlined,
  EventRepeatOutlined,
  FormatListBulletedOutlined,
} from "@mui/icons-material"; // Import icons
import {
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import { formatAddress } from "../../services/dateServices";

import { LocationOnOutlined } from "@mui/icons-material";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { debounce } from "lodash";
import moment from "moment-timezone";
import { db } from "../../firebase";
import { useMember } from "../../providers/MemberProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import AddEmployeeDialog from "../AddEmployeeDialog";
import CancelVisitDialog from "../CancelVisitDialog";
import PushDrawer from "../PushDrawer";
import RatingChip from "../RatingChip";
import RescheduleVisitMember from "../RescheduleVisitMember";
import ReviewVisitMember from "../ReviewVisitMember";
import VisitCardMenu from "../VisitCardMenu";
import VisitRefundRequest from "../VisitRefundRequest";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import DateTimeListItem from "./DateTimeListItem";
import HelpersListItem from "./HelpersListItem";

const VisitCard = ({
  visit,
  visitId,
  localEmployees, // all local employees in an area. Used for rescheduling purposes.
  handleClose = () => {},
  adminView = false,
  eId = "",
  eName = "",
}) => {
  const { data } = useMember();
  const theme = useTheme();
  const { showSnackbar, showLoading } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [editableDescription, setEditableDescription] = useState(
    visit?.description
  );
  const [showReview, setShowReview] = useState(false);
  const [fetchPrivateData, setFetchPrivateData] = useState(true);
  const [privateData, setPrivateData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddEmployeeDialog, setShowAddEmployeeDialog] = useState(false);
  const [rescheduleDialogOpen, setRescheduleDialogOpen] = useState(false);
  const [showRefundDialog, setShowRefundDialog] = useState(false);

  const timeZoneId = visit?.timeZoneId;

  useEffect(() => {
    const fetchPrivateData = async () => {
      try {
        // Assuming 'visitId' is the ID for the visit document
        const privateDataRef = doc(db, "visits", visitId, "private", "data");
        const privateDataSnap = await getDoc(privateDataRef);

        if (privateDataSnap.exists()) {
          setPrivateData(privateDataSnap.data());
        } else {
          console.log("No private data found");
        }
      } catch (error) {
        console.error("Error fetching private data:", error);
      } finally {
        setFetchPrivateData(false);
      }
    };

    if (visitId) {
      fetchPrivateData();
    }
  }, [visitId]);

  // Effect for handling component unmount
  useEffect(() => {
    return () => {
      // This function is called when the component is unmounted
      handleSave();
    };
  }, [editableDescription, visitId, db]);

  const handleReviewOpen = () => {
    setShowReview(true);
  };

  const handleReviewClose = () => {
    setShowReview(false);
  };

  const isPastEvent = moment().isAfter(moment(visit?.start?.toDate()));
  const isReviewed = typeof visit?.rating === "number" && !isNaN(visit?.rating);
  const isRefunded = visit.flags && visit.flags.includes("refundIssued");
  const needsReschedule = visit?.status === "reschedule";

  const canReschedule =
    (isPastEvent && visit.status === "reschedule") || !isPastEvent;
  const isActive = !isPastEvent && visit.status === "confirmed";
  const canCancel = !isPastEvent && visit.status !== "cancelled";
  const canReview = isPastEvent && !isReviewed && !needsReschedule;

  const canRefund =
    isPastEvent &&
    !isRefunded &&
    visit.status !== "cancelled" &&
    visit.status !== "reschedule";

  const debouncedSave = useCallback(
    debounce(async (newDescription) => {
      const visitRef = doc(db, "visits", visitId);

      try {
        await updateDoc(visitRef, { description: newDescription });
      } catch (error) {
        console.error("Error updating description: ", error);
      }
    }, 5000),
    [visitId, db] // added dependencies here
  );

  const handleSave = async () => {
    console.log("Updating visit with ID:", visitId);
    const visitRef = doc(db, "visits", visitId);

    try {
      await updateDoc(visitRef, { description: editableDescription });
      console.log("Description updated successfully");
    } catch (error) {
      console.error("Error updating description: ", error);
    }
  };

  // Function to compare visit window with employee windows
  const isAnyWindowDifferent = (visit) => {
    if (!visit || !visit.employees || !visit.start || !visit.end) {
      return false; // Return false if visit or necessary properties are missing
    }

    const visitStart = moment(visit.start.toDate()).tz(timeZoneId);
    const visitEnd = moment(visit.end.toDate()).tz(timeZoneId);

    // Check if any employee's window is different
    return Object.values(visit.employees).some((employee) =>
      Object.values(employee.windows || {}).some((window) => {
        const windowStart = moment(window.start.toDate()).tz(timeZoneId);
        const windowEnd = moment(window.end.toDate()).tz(timeZoneId);

        return !windowStart.isSame(visitStart) || !windowEnd.isSame(visitEnd);
      })
    );
  };

  // Determining how to display time format
  const employeeCount = visit?.employees && Object.keys(visit.employees).length;
  const windowsAreDifferent = isAnyWindowDifferent(visit);
  const shouldShowTimes = employeeCount > 1 && windowsAreDifferent;

  return (
    <>
      <PushDrawer
        title={"Review"}
        minWidth={"600px"}
        open={showReview}
        onOpen={handleReviewOpen}
        onClose={handleReviewClose}
        hideBackdrop={true}
      >
        <ReviewVisitMember
          visit={visit}
          visitId={visitId}
          handleClose={handleReviewClose}
        />
      </PushDrawer>

      <ResponsiveDialog
        open={rescheduleDialogOpen}
        onClose={() => setRescheduleDialogOpen(false)}
        title={`Reschedule visit`}
        width={"800px"}
        fullHeight={true}
        maxHeight="583px"
        backgroundCloses={false}
      >
        {rescheduleDialogOpen && (
          <RescheduleVisitMember
            visitInput={{ id: visitId, ...visit }}
            employees={localEmployees}
            handleClose={() => setRescheduleDialogOpen(false)}
          />
        )}
      </ResponsiveDialog>

      {/* Visit Details View */}
      <DialogContent>
        <List sx={{ marginTop: "-24px" }}>
          <DateTimeListItem
            visit={visit}
            flags={visit?.flags}
            employees={visit?.employees}
            start={visit?.start}
            end={visit?.end}
            status={visit?.status}
            timeZoneId={visit?.timeZoneId}
            visitId={visitId}
            adminView={adminView}
          />

          {visit.recurrence?.frequency > 0 && (
            <ListItem sx={{ paddingX: "0px" }}>
              <ListItemIcon sx={{ minWidth: "48px" }}>
                <EventRepeatOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    {visit.recurrence.frequency > 1
                      ? `Every ${visit.recurrence.frequency}-weeks on ${moment(
                          visit?.start?.toDate()
                        ).format("dddd")}`
                      : `Every week on ${moment(visit?.start?.toDate()).format(
                          "dddd"
                        )}`}
                  </Typography>
                }
              />
            </ListItem>
          )}

          {/* Visit Notes */}
          <ListItem sx={{ paddingX: "0px" }}>
            <ListItemIcon sx={{ minWidth: "48px" }}>
              <FormatListBulletedOutlined color="primary" />
            </ListItemIcon>

            {isPastEvent ? (
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: "grey.100",
                  px: 2, // Adjust padding as needed
                  py: 1.5,
                  borderRadius: "15px", // To match TextField's default style
                  // Add more styling as needed to match the TextField appearance
                }}
              >
                {editableDescription || "No tasks specified"}
              </Typography>
            ) : (
              <TextField
                fullWidth
                minRows={4}
                multiline={true}
                variant="outlined"
                value={editableDescription}
                onChange={(e) => {
                  setEditableDescription(e.target.value);
                  debouncedSave(e.target.value);
                }}
                placeholder="Click to add tasks"
                sx={{
                  backgroundColor: "grey.100",
                }}
              />
            )}
          </ListItem>

          <ListItem sx={{ paddingX: "0px" }}>
            <ListItemIcon sx={{ minWidth: "48px" }}>
              <LocationOnOutlined color="primary" />
            </ListItemIcon>
            {privateData?.address && (
              <ListItemText
                sx={{ marginRight: 4 }}
                primary={
                  fetchPrivateData ? (
                    <Skeleton variant="text" />
                  ) : (
                    formatAddress(privateData.address)
                  )
                }
              />
            )}
          </ListItem>
          {privateData?.address?.directions && (
            <ListItem sx={{ paddingX: "0px" }}>
              <ListItemIcon sx={{ minWidth: "48px" }}>
                <DirectionsOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                sx={{ marginRight: 4 }}
                primary={privateData?.address?.directions}
              />
            </ListItem>
          )}
          {visit?.rating && (
            <ListItem sx={{ paddingX: "0px" }}>
              <ListItemIcon sx={{ minWidth: "48px" }}>
                <ChatOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={<RatingChip rating={visit.rating} />}
                secondary={visit.review}
              />
            </ListItem>
          )}

          <HelpersListItem
            localEmployees={localEmployees}
            visitEmployees={visit?.employees}
            isOpenVisit={visit?.isOpenVisit}
            employeesNeeded={visit?.employeesNeeded}
            visitId={visitId}
            timeZoneId={timeZoneId}
            start={visit?.start}
            end={visit?.end}
            adminView={adminView}
          />
        </List>

        {/* {shouldShowTimes && <VisitTimelineView visit={visit} />} */}

        <VisitCardMenu
          canReschedule={canReschedule}
          canCancel={canCancel}
          canReview={canReview}
          canRefund={canRefund}
          canAddHelp={isActive}
          setShowAddEmployeeDialog={setShowAddEmployeeDialog}
          setRescheduleDialogOpen={setRescheduleDialogOpen}
          setShowConfirmDialog={setShowConfirmDialog}
          setShowRefundDialog={setShowRefundDialog}
          handleReviewOpen={handleReviewOpen}
        />
      </DialogContent>

      <CancelVisitDialog
        open={showConfirmDialog}
        setOpen={setShowConfirmDialog}
        handleCancel={handleClose}
        visit={visit}
        visitId={visitId}
        timeZoneId={timeZoneId}
        adminMode={adminView}
        eId={eId}
        eName={eName}
      />

      <AddEmployeeDialog
        open={showAddEmployeeDialog}
        setOpen={setShowAddEmployeeDialog}
        visitId={visitId}
        employeesNeeded={visit?.employeesNeeded}
      />

      <VisitRefundRequest
        open={showRefundDialog}
        setOpen={setShowRefundDialog}
        visitId={visitId}
        memberId={visit?.member}
        billed={visit?.billed || false}
        employees={visit.employees}
      />
    </>
  );
};

export default VisitCard;
