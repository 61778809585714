import { AddIcCall, PersonAdd } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { useEmployee } from "../../providers/EmployeeProvider";
import LeadCard from "../crm/lead/LeadCard";
import MemberCard from "../crm/member/MemberCard";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import CreateMember from "./CreateMember";
import MemberSearchBar from "./MemberSearchBar";
import NewLead from "./NewLead";

const MemberSearch = ({ isAdmin = false }) => {
  const { data } = useEmployee();

  const [selectedUser, setSelectedUser] = useState(null);

  // Dialogs
  const [openCreateMember, setOpenCreateMember] = useState(false);
  const [openCreateLead, setOpenCreateLead] = useState(false);
  const [openMemberCard, setOpenMemberCard] = useState(false);
  const [openLeadCard, setOpenLeadCard] = useState(false);

  const currentEmployeeId = data?.auth?.uid;

  const handleListItemClick = (user) => {
    setSelectedUser(user);
    if (user.type === "member") {
      setOpenMemberCard(true);
    } else if (user.type === "lead") {
      // open the lead card
      setOpenLeadCard(true);
    }
  };

  return (
    <div>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} gap={1}>
            <MemberSearchBar onResultSelect={handleListItemClick} />
          </Grid>
          <Box
            sx={{
              position: {
                xs: "fixed",
                sm: "relative",
              },
              bottom: { xs: "72px", sm: "unset" },
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: { xs: "8px", sm: "16px" },
              width: "auto",
              left: { xs: 0, sm: "unset" },
              right: { xs: 0, sm: "unset" },
              backgroundColor: "white",
              boxShadow: {
                xs: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                sm: "none",
              },
            }}
          >
            <Button
              fullWidth
              onClick={() => setOpenCreateMember(true)}
              color="primary"
              variant="outlined"
              sx={{
                minWidth: { xs: "100%", sm: "150px" },
                height: { xs: "56px", sm: "150px" },
                mb: 1,
              }}
              startIcon={<PersonAdd />}
            >
              New Member
            </Button>
            <Button
              fullWidth
              onClick={() => setOpenCreateLead(true)}
              color="primary"
              variant="outlined"
              sx={{
                minWidth: { xs: "100%", sm: "150px" },
                height: { xs: "56px", sm: "150px" },
                mb: 1,
              }}
              startIcon={<AddIcCall />}
            >
              New Lead
            </Button>
          </Box>
        </Grid>
      </>
      {selectedUser?.type === "member" && (
        <>
          <ResponsiveDialog
            open={openMemberCard}
            onClose={() => setOpenMemberCard(false)}
            title={"Member details"}//{`${selectedUser?.firstName} ${selectedUser?.lastName}`}
            desktopAnchor={"right"}
            anchor={"right"}
            width={"600px"}
            fullHeight={true}
          >
            <MemberCard
              memberId={selectedUser?.id}
              isAdmin={isAdmin}
              openMemberCard={openMemberCard}
            />
          </ResponsiveDialog>
        </>
      )}
      {selectedUser?.type === "lead" && (
        <>
          <ResponsiveDialog
            open={openLeadCard}
            onClose={() => setOpenLeadCard(false)}
            title={`${selectedUser?.name}`}
            desktopAnchor={"right"}
            anchor={"right"}
            width={"600px"}
            fullHeight={true}
          >
            <LeadCard lead={selectedUser} employeeId={currentEmployeeId} />
          </ResponsiveDialog>
        </>
      )}

      {/* Create member dialog */}
      <ResponsiveDialog
        open={openCreateMember}
        onClose={() => setOpenCreateMember(false)}
        title={"New Member"}
        width={"600px"}
        desktopAnchor={"right"}
        anchor={"right"}
        fullHeight={true}
      >
        <CreateMember
          open={openCreateMember}
          handleClose={() => setOpenCreateMember(false)}
          setSelectedMember={setSelectedUser}
          handleOpenMember={() => setOpenMemberCard(true)}
          userUid={data?.auth?.uid}
          userName={`${data?.employee?.firstName} ${data?.employee?.lastName}`}
          userAvatar={data?.employee?.avatarUrl}
        />
      </ResponsiveDialog>

      {/* Create lead dialog */}
      <ResponsiveDialog
        open={openCreateLead}
        onClose={() => setOpenCreateLead(false)}
        title={"New Lead"}
        width={"600px"}
        desktopAnchor={"right"}
        anchor={"bottom"}
        fullHeight={true}
      >
        {openCreateLead && (
          <NewLead
            employeeId={currentEmployeeId}
            handleClose={() => setOpenCreateLead(false)}
          />
        )}
      </ResponsiveDialog>
    </div>
  );
};

export default MemberSearch;
