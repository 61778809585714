import {
  addDoc,
  collection,
  doc,
  endAt,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  startAt,
  where,
} from "firebase/firestore";
import { distanceBetween, geohashQueryBounds } from "geofire-common"; // make sure you have 'geofire-common' installed
import moment from "moment-timezone";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import LoadingUser from "../components/LoadingUser";
import { auth, db } from "../firebase";
import { decodeGeoHash, isPointInPolygon } from "../services/locationServices";
import { createLog } from "../services/logServices";

const MemberContext = createContext({
  data: null,
  loading: true,
  setData: () => {}, // empty function for the initial value
  setLoading: () => {}, // empty function for the initial value
});

const MemberProvider = ({ children }) => {
  // const [data, setData] = useState(null);
  const [data, setData] = useState(() => ({
    auth: {},
    member: {},
    account: {},
    visits: {},
    // ... other data fields
  }));
  const [loading, setLoading] = useState(true); // Initial loading state
  const [accountLoading, setAccountLoading] = useState(true); // Initial loading state

  const [employeeUnsubscribes, setEmployeeUnsubscribes] = useState({});
  const employeeUnsubscribesRef = useRef({});
  const visitUnsubscribesRef = useRef({});
  const [visitsLoading, setVisitsLoading] = useState(true);
  const [isServiceArea, setIsServiceArea] = useState(false);
  const [visitUnsubscribes, setVisitUnsubscribes] = useState({});
  const accountUnsubscribes = useRef({}); // To manage multiple account listeners
  const [nextVisit, setNextVisit] = useState();
  const [prevVisit, setPrevVisit] = useState();

  /////// TRACKER
  const [sessionStart] = useState(Date.now());
  const [buttonClicks, setButtonClicks] = useState([]);

  const getDeviceInfo = () => {
    return {
      deviceType: navigator.userAgent,
      isStandalone: window.matchMedia("(display-mode: standalone)").matches,
      width: window.innerWidth,
      height: window.innerHeight,
      platform: navigator.userAgentData?.platform || "unknown",
      userAgent: navigator.userAgent,
    };
  };

  const logSessionData = async () => {
    const sessionEnd = Date.now();
    const sessionDuration = sessionEnd - sessionStart;
    const deviceInfo = getDeviceInfo();

    if (data?.auth?.uid) {
      try {
        await addDoc(collection(db, "members", data.auth.uid, "sessionLogs"), {
          sessionStart,
          sessionEnd,
          sessionDuration,
          buttonClicks,
          deviceInfo,
          timestamp: serverTimestamp(),
        });
      } catch (error) {
        console.error("Error logging session data:", error);
      }
    }
  };

  const trackButtonClick = (event) => {
    if (event.target.tagName === "BUTTON") {
      setButtonClicks((buttonClicks) => [
        ...buttonClicks,
        { time: Date.now(), text: event.target.innerText },
      ]);
    }
  };

  useEffect(() => {
    document.addEventListener("click", trackButtonClick);
    return () => {
      document.removeEventListener("click", trackButtonClick);
      logSessionData(); // Log data when component unmounts
    };
  }, []);
  ///////

  // AUTH // MEMBER // MEMBER.PRIVATE
  useEffect(() => {
    // Initiate the authentication listener

    console.log("-------- init snapshot listener: auth ----------");

    const authUnsubscribe = auth.onAuthStateChanged((userAuth) => {
      if (!userAuth) {
        setData(null);
        setLoading(false);
        return;
      }

      const memberDocRef = doc(db, "members", userAuth.uid);
      let privateUnsubscribe;
      let adminUnsubscribe;

      try {
        const memberUnsubscribe = onSnapshot(
          memberDocRef,
          async (memberDoc) => {
            if (memberDoc.exists()) {
              const memberData = memberDoc.data();
              const memberId = memberDoc.id; // Get the document ID

              console.log("-------- init snapshot listener: member ----------");

              // Store member data
              setData((prevData) => ({
                ...prevData,
                auth: userAuth,
                member: {
                  ...memberData,
                  id: memberId, // Inject the document ID here
                },
              }));

              // Set up listener for private sub-collection
              const privateColRef = collection(memberDocRef, "private");
              privateUnsubscribe = onSnapshot(
                privateColRef,
                (privateSnapshot) => {
                  const allPrivateData = {};
                  privateSnapshot.docs.forEach((docSnapshot) => {
                    const privateDocId = docSnapshot.id;
                    allPrivateData[privateDocId] = docSnapshot.data();
                  });

                  setData((prevData) => ({
                    ...prevData,
                    member: {
                      ...prevData.member,
                      private: allPrivateData,
                    },
                  }));
                }
              );

              console.log("-------- init snapshot listener: admin ----------");
              // Set up listener for admin sub-collection
              const adminColRef = collection(memberDocRef, "admin");
              adminUnsubscribe = onSnapshot(adminColRef, (adminSnapshot) => {
                const allAdminData = {};
                adminSnapshot.docs.forEach((docSnapshot) => {
                  const adminDocId = docSnapshot.id;
                  allAdminData[adminDocId] = docSnapshot.data();
                });

                setData((prevData) => ({
                  ...prevData,
                  member: {
                    ...prevData.member,
                    admin: allAdminData,
                  },
                }));
              });

              setLoading(false);
            } else {
            }
          }
        );

        return () => {
          memberUnsubscribe();
          Object.values(accountUnsubscribes).forEach((unsub) => unsub());
          if (privateUnsubscribe) privateUnsubscribe(); // Cleanup the private sub-collection listener
          if (adminUnsubscribe) adminUnsubscribe(); // Cleanup the admin sub-collection listener
        };
      } catch (error) {
        console.error("Error setting up snapshot listener:", error);
      } finally {
        createLog({
          collectionName: "members",
          idsArr: [userAuth.uid],
          summary: `Visited the dashboard.`,
          logType: "activity",
        });
      }
    });

    // Cleanup the authentication listener
    return () => {
      authUnsubscribe();
    };
  }, []);

  // ACCOUNT
  useEffect(() => {
    const accountId = data?.member?.admin?.data?.accountId || data?.auth?.uid; // if there's no accountId, the default is the memberId

    if (!accountId) return; // Return early if there is no account ID

    console.log("-------- init snapshot listener: account ----------");

    const accountDocRef = doc(db, "accounts", accountId);

    const unsubscribe = onSnapshot(accountDocRef, (accountDoc) => {
      if (accountDoc.exists()) {
        const accountData = accountDoc.data();

        setData((prevData) => ({
          ...prevData,
          account: accountData,
        }));
      }
    });

    setAccountLoading(false);

    // When this useEffect cleans up, unsubscribe from the account listener
    return () => {
      console.log("!!!!!!!!!!! unsubscribed from account !!!!!!!!!!!");
      unsubscribe();
    };
  }, [
    data?.auth?.uid,
    data?.member?.admin?.data?.accountId,
    data?.member?.private?.data?.defaultAddress,
  ]);

  // Queried VISITS
  useEffect(() => {
    // Get the user's UID. Here it's assumed you have access to this information, perhaps through context, props, or a global state.
    const accountId = data?.member?.admin?.data?.accountId || data?.auth?.uid; // if there's no accountId, the default is the memberId
    if (!accountId) return;

    console.log("accountId:", accountId);

    // Define a reference to the 'visits' collection.
    const visitsRef = collection(db, "visits");

    // Construct the query.
    const visitsQuery = query(
      visitsRef,
      where("account", "==", accountId) // Checks if the user's UID exists in the 'employees' field of the visit document.
      // where('start', '>', new Date()), // Assuming 'start' is a timestamp, this condition checks if it's later than now.
      // where("status", "==", "confirmed") // Filter visits where status is "confirmed".
    );

    // Attach a listener for this query.
    const unsubscribe = onSnapshot(visitsQuery, (querySnapshot) => {
      const visits = {};
      querySnapshot.forEach((doc) => {
        // Store the visit data in the 'visits' object using the visit ID as the key.
        visits[doc.id] = doc.data();
      });

      // Update the state with the new visits data.
      setData((prevData) => ({
        ...prevData,
        visits,
      }));

      setVisitsLoading(false);
    });

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, [data?.auth?.uid, data?.member?.admin?.data?.accountId]);

  // Queried GEOHASH EMPLOYEES
  useEffect(() => {
    setIsServiceArea(true);

    if (!data?.member?.location?.geohash) {
      setIsServiceArea(null);
      return;
    }

    const memberCoordinates = decodeGeoHash(data?.member?.location?.geohash);

    // Define the radius within which you want to find employees, in meters.
    const radiusInM = 10 * 1609.34; // e.g., 10 miles to meters

    const bounds = geohashQueryBounds(memberCoordinates, radiusInM);

    // This Map helps avoid duplicate employees and easily manage the real-time data
    const allEmployees = new Map();

    const unsubscribe = bounds.map(([start, end]) => {
      const q = query(
        collection(db, "employees"),
        where("status", "==", "active"),
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      return onSnapshot(q, (querySnapshot) => {
        const changes = querySnapshot.docChanges();
        changes.forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const employeeData = change.doc.data();

            const employeeMaxTravelMiles = employeeData.maxTravelMiles;
            let employeeCoordinates = decodeGeoHash(
              employeeData?.location?.geohash
            );

            const employeeDistance =
              distanceBetween(memberCoordinates, employeeCoordinates) *
              0.621371; // convert to miles

            // Assuming eventData.location.bounds is an array of { lat, lng } points defining the polygon
            // Assuming memberCoordinates are a { lat, lng } pair
            if (employeeData?.location?.bounds !== undefined) {
              if (
                isPointInPolygon(
                  memberCoordinates,
                  employeeData.location.bounds
                )
              ) {
                setIsServiceArea(true);
                allEmployees.set(change.doc.id, employeeData);
              }
            } else if (employeeDistance <= employeeMaxTravelMiles) {
              setIsServiceArea(true);
              allEmployees.set(change.doc.id, employeeData);
            }
          } else if (change.type === "removed") {
            // Employee has been removed from the query results, remove them from the Map
            allEmployees.delete(change.doc.id);
          }
        });

        // Extract a plain object from the Map
        const aggregatedEmployees = Object.fromEntries(allEmployees);
        // console.log("aggregatedEmployees: ", aggregatedEmployees);

        // Update state with all aggregated employees
        setData((prevData) => ({
          ...prevData,
          employees: aggregatedEmployees,
        }));

        // You may also handle "no service" cases here, depending on your business logic
        if (Object.keys(aggregatedEmployees).length === 0) {
          // console.log("no employees here");
          setIsServiceArea(false);
        }
      });
    });

    // Cleanup function
    return () => {
      unsubscribe.forEach((unsub) => unsub());
    };
  }, [data?.member?.location?.geohash]); // Dependency array

  // Queried GEOHASH MEMBERS
  useEffect(() => {
    if (
      !isServiceArea ||
      !data?.member?.location?.geohash ||
      !data.member.location
    )
      return;

    console.log("No IDS to add, getting local peeps");

    const center = decodeGeoHash(data?.member?.location?.geohash);
    const radiusInM = 5 * 1609.34; // for example, 20 miles to meters

    const bounds = geohashQueryBounds(center, radiusInM);

    const allMembers = new Map(); // to store members data

    const unsubscribe = bounds.map(([start, end]) => {
      const q = query(
        collection(db, "members"), // Adjust according to your collection name
        where("status", "in", ["active", "waitlist"]), // Query for 'active' or 'waitlist'
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      return onSnapshot(q, (querySnapshot) => {
        const changes = querySnapshot.docChanges();
        changes.forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const memberData = change.doc.data();
            let memberCoordinates = decodeGeoHash(
              memberData?.location?.geohash
            );

            const memberDistance =
              distanceBetween(center, memberCoordinates) * 0.621371; // convert to miles

            // Here you can check any condition you want to filter your members
            // For example, if they are within a certain distance
            if (memberDistance <= 15) {
              // YOUR_CONDITION is the radius within which you want to include members
              // console.log("adding member: ", change.doc.data().firstName);

              allMembers.set(change.doc.id, memberData);
            }
          } else if (change.type === "removed") {
            // console.log("removed member: ", change.doc.data().firstName);

            allMembers.delete(change.doc.id);
          }
        });

        // Do something with the aggregated members
        const aggregatedMembers = Object.fromEntries(allMembers);
        // console.log("aggregatedMembers: ", aggregatedMembers);

        setData((prevData) => ({
          ...prevData,
          waitlist: aggregatedMembers,
        }));

        // You can also manage the state for when there are no members available
        if (allMembers.size === 0) {
          console.log("No local members found");
          // handle the scenario appropriately
        }
      });
    });

    // Cleanup function to unsubscribe when the component is unmounted or when the dependencies of this useEffect change
    return () => {
      unsubscribe.forEach((unsub) => unsub());
    };
  }, [data?.member?.location?.geohash, isServiceArea]); // Add dependencies here

  // Get all announcements
  useEffect(() => {
    const fetchAnnouncementDetails = async () => {
      try {
        // Extract announcement IDs from the loaded data
        const announcementIds = data.member.private.data.announcements; // updated

        // Fetch details for each announcement by ID
        const announcements = await Promise.all(
          announcementIds.map(async (id) => {
            const docRef = doc(db, "announcements", id);
            const docSnap = await getDoc(docRef);
            return docSnap.exists()
              ? { id: docSnap.id, ...docSnap.data() }
              : null;
          })
        );

        // Filter out null values if some documents were not found
        const validAnnouncements = announcements.filter(Boolean);

        // Update the context or global state with the fetched announcement details
        setData((prevData) => ({
          ...prevData,
          announcements: validAnnouncements,
        }));
      } catch (error) {
        console.error("Error fetching announcement details:", error);
      }
    };

    if (data?.member?.private?.data?.announcements && !data?.announcements) {
      fetchAnnouncementDetails();
    }
  }, [data?.member?.private?.data?.announcements]);

  // POLYGON EVENTS nearby - Use effect to find nearby events
  useEffect(() => {
    const memberLocationHash = data?.member?.location?.geohash;
    if (!memberLocationHash) return;

    const memberGeohashCoordinates = decodeGeoHash(memberLocationHash);
    console.log("memberGeohashCoordinates: ", memberGeohashCoordinates);
    const radiusInM = 10 * 1609.34; // 10 miles in meters, adjust as needed

    const bounds = geohashQueryBounds(memberGeohashCoordinates, radiusInM);

    const defaultAddressKey = data?.member?.private?.data?.defaultAddress;
    const matchedAddress = data?.account?.addresses?.[defaultAddressKey];

    if (!matchedAddress) return;

    const memberCoordinates = {
      lat: matchedAddress.lat,
      lng: matchedAddress.lng,
    };
    console.log("memberCoordinates: ", memberCoordinates);

    const eventsRef = collection(db, "events"); // Adjust according to your events collection name
    const eventUnsubscribes = [];

    bounds.forEach(([start, end]) => {
      const eventsQuery = query(
        eventsRef,
        where("status", "==", "active"), // Filter events where status is "active"
        orderBy("location.geohash"),
        startAt(start),
        endAt(end)
      );

      const unsubscribe = onSnapshot(eventsQuery, (querySnapshot) => {
        const newEvents = {};
        querySnapshot.forEach((doc) => {
          const eventData = doc.data();

          console.log("memberCoordinates: ", memberCoordinates);
          console.log("eventData.location.bounds: ", eventData.location.bounds);

          // Assuming eventData.location.bounds is an array of { lat, lng } points defining the polygon
          // Assuming memberCoordinates are a { lat, lng } pair
          if (isPointInPolygon(memberCoordinates, eventData.location.bounds)) {
            console.log("is within bounds");
            newEvents[doc.id] = eventData;
          }
        });

        // Update the state with new events within maxDistance
        setData((prevData) => ({
          ...prevData,
          events: { ...prevData.events, ...newEvents },
        }));
      });

      eventUnsubscribes.push(unsubscribe);
    });

    // Cleanup function
    return () => {
      eventUnsubscribes.forEach((unsub) => unsub());
    };
  }, [
    data?.member?.location?.geohash,
    data?.member?.private?.data?.defaultAddress,
    data?.account?.addresses,
  ]); // Dependency array

  // useEffect hook to watch for changes in the user state
  useEffect(() => {
    if (data && data.member) {
      console.log("Updated data:", data);
    }
  }, [data]);

  useEffect(() => {
    if (!data?.member?.timeZoneId) return;

    const timeZoneId = data?.member?.timeZoneId;

    const now = moment().tz(timeZoneId);
    let next = null;
    let prev = null;

    // Convert visits to an array and sort by the 'start' field
    const sortedVisits = Object.entries(data?.visits || {})
      .map(([id, visit]) => ({ ...visit, id }))
      .sort((a, b) => a.start.toDate() - b.start.toDate());

    // Iterate over the sorted visits to find the previous and next visits
    for (let visit of sortedVisits) {
      if (visit.status === "cancelled" || visit.status === "reschedule") {
        continue; // Skip cancelled visits
      }

      const startMoment = moment(visit.start.toDate()).tz(timeZoneId);

      if (startMoment.isAfter(now)) {
        next = { ...visit }; // Assign the next visit directly
        break; // Break the loop after finding the next visit
      } else if (startMoment.isBefore(now)) {
        prev = { ...visit }; // Always update prev to the most recent visit before now
      }
    }

    setNextVisit(next);
    setPrevVisit(prev);
  }, [data?.visits]);

  if (loading) {
    console.log("MemberProvider -- loading");

    return <LoadingUser loading={loading} />;
  }

  return (
    <MemberContext.Provider
      value={{
        data,
        setData,
        loading,
        setLoading,
        isServiceArea,
        visitsLoading,
        nextVisit,
        prevVisit,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

export default MemberProvider;

export const useMember = () => {
  return useContext(MemberContext);
};
