import {
  AccessTime,
  AccountCircle,
  Edit,
  EventRounded,
  ExpandMore,
  GroupOutlined,
  InfoOutlined,
  Notes,
  Public,
  StarOutlineRounded,
  StarRounded,
  SupervisedUserCircle,
} from "@mui/icons-material";
import {
  Accordion,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Rating,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Chip,
  MenuItem,
  Select,
  FormControl,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { doc, updateDoc, Timestamp } from "firebase/firestore";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { ConfirmationBurst } from "../animations/ConfirmationBurst";
import { db } from "../firebase";
import ActionFooter from "./ActionFooter";
import PushDrawer from "./PushDrawer";
import AdjustTimeWindow from "./AdjustTimeWindow";
import ColorAvatar from "./ColorAvatar";
import ResponsiveDialog from "./dialogs/ResponsiveDialog";
import CreatePost from "./feed/CreatePost";
import { useEmployee } from "../providers/EmployeeProvider";
import AdditionalHelpList from "./feedback/AdditionalHelpList";
import { useSnackbar } from "../providers/SnackbarProvider";

const ReviewVisitEmployee = ({
  visit,
  visitId,
  member,
  employeeId,
  handleClose = () => {},
  stars = null,
  buttonText = "Done",
  confirmationScreen = true,
  details = false,
}) => {
  const { data } = useEmployee();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { showSnackbar } = useSnackbar();

  const [rating, setRating] = useState(stars);
  const [review, setReview] = useState("");
  const [showDetails, setShowDetails] = useState(details);
  const [loading, setLoading] = useState(false); // State to track loading
  const [showConfirmation, setShowConfirmation] = useState(false); // State to show confirmation
  const [openEditTime, setOpenEditTime] = useState(false);
  const [openCreatePost, setOpenCreatePost] = useState(false);
  const [visibility, setVisibility] = useState("public");

  const currentEmployeeId = employeeId;
  const timeZoneId = visit?.timeZoneId;

  useEffect(() => {
    setRating(stars);
  }, [stars]);

  // Return if props are not loaded
  if (!visit || !visitId || !member || !employeeId) return;

  const dropdownItems = [
    {
      label: "Public post",
      value: "public",
      chipText: null, //"Earn a bonus", //"$5 bonus",
      icon: <Public />,
    },
    {
      label: "Manager only",
      value: "manager",
      chipText: null,
      icon: <AccountCircle />,
    },
  ];

  const handleChange = (event) => {
    setVisibility(event.target.value);
    console.log("event.target.value: ", event.target.value);
  };

  const VisibilitySelect = (visibility, setVisibility) => {
    // Custom render value function to display both icon and label
    const renderValue = (selected) => {
      if (!selected) return;
      const selectedItem = dropdownItems.find(
        (item) => item.value === selected
      );
      return (
        <div
          style={{ display: "flex", alignItems: "center", overflow: "hidden" }}
        >
          {selectedItem.icon}
          <span
            style={{
              marginLeft: 8,
              marginRight: 8,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "calc(100% - 30px)",
            }}
          >
            {selectedItem.label}
          </span>
          {selectedItem.chipText && (
            <Chip label={selectedItem.chipText} color="success" />
          )}
        </div>
      );
    };

    return (
      <FormControl fullWidth>
        <InputLabel id="visibility-label">Set visibility</InputLabel>
        <Select
          labelId="visibility-label"
          id="visibility-select"
          value={visibility}
          label="Set visibility"
          onChange={handleChange}
          displayEmpty
          renderValue={renderValue}
        >
          {dropdownItems.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
              {item.chipText && <Chip label={item.chipText} color="success" />}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  // Utility functions for date and time formatting
  const formatDate = (date) => {
    return moment(date).format("MMMM D, YYYY");
  };

  const formatTimeRange = (startDate, endDate) => {
    const startTime = moment(startDate).format("h:mm A");
    const endTime = moment(endDate).format("h:mm A");
    return `${startTime} - ${endTime}`;
  };

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ExpandMore sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    borderRadius: "15px",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    // padding: theme.spacing(2),
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    borderRadius: "15px",
  }));

  const handleAddPost = () => {
    setOpenCreatePost(false);
    setRating(5);
    handleSubmit();
  };

  const handleSubmit = async () => {
    setLoading(true); // Start the loading state

    // Reference to the visit document
    const visitDocRef = doc(db, "visits", visitId);

    // Prepare the nested fields for update
    const updateFields = {
      [`employees.${employeeId}.rating`]: rating || 5,
      [`employees.${employeeId}.review`]: review || "Post created",
    };

    try {
      await updateDoc(visitDocRef, updateFields);
      console.log("Document successfully updated with rating and review!");
      setLoading(false); // Stop the loading state
      if (confirmationScreen) {
        setShowConfirmation(true); // Show the confirmation
      } else {
        setRating(0);
        setReview("");
        handleClose();
      }
    } catch (error) {
      console.error("Error updating document: ", error);
      setLoading(false); // Stop the loading if there's an error
    }
  };

  const handleEditTime = () => {
    setOpenEditTime(true);
  };

  const saveWindows = async (localWindows) => {
    setLoading(true);
    console.log("Saving windows: ", localWindows);
    try {
      // Convert localWindows from Moment objects to Firestore Timestamps
      const windowsToSave = {};
      Object.keys(localWindows).forEach((windowId) => {
        windowsToSave[windowId] = {
          start: Timestamp.fromDate(localWindows[windowId].start.toDate()),
          end: Timestamp.fromDate(localWindows[windowId].end.toDate()),
        };
      });

      // Path to the specific document
      const docRef = doc(db, "visits", visitId);

      // Prepare the update object
      const updateData = {
        [`employees.${currentEmployeeId}.windows`]: windowsToSave,
      };

      // Update the document
      await updateDoc(docRef, updateData);
      console.log("Windows saved successfully");
    } catch (error) {
      console.error("Error saving windows: ", error);
    } finally {
      setLoading(false);
      setOpenEditTime(false);
    }
  };

  return (
    <>
      {/* Edit the times  */}
      <ResponsiveDialog
        title={"Edit your hours"}
        open={openEditTime}
        onClose={() => setOpenEditTime(false)}
        width={"600px"}
        fullHeight={true}
        anchor={"right"}
      >
        <AdjustTimeWindow
          onSave={saveWindows}
          windows={
            visit?.employees[currentEmployeeId]?.windows ?? {
              "window-1": {
                start: visit?.start,
                end: visit?.end,
              },
            }
          }
          timeZoneId={visit?.timeZoneId}
          name={visit?.displayName}
          loading={loading}
        />
      </ResponsiveDialog>

      <DialogContent>
        {/* Centered Rating Section */}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              position: "sticky",
              top: isMobile ? "32vh" : undefined,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%", // Ensure it spans the full width
              gap: 2,
              marginY: 2,
            }}
          >
            <CircularProgress size={24} color="inherit" />
          </Box>
        ) : showConfirmation ? (
          <Box
            sx={{
              display: "flex",
              position: "sticky",
              top: isMobile ? "32vh" : undefined,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // width: "100%", // Ensure it spans the full width
              gap: 2,
              marginY: 2,
              marginX: "32px",
            }}
          >
            <ConfirmationBurst size="xlarge" />
            <Typography variant="h6" marginTop={2}>
              Received!
            </Typography>
            <Typography>
              Thanks for taking the time to let us know how your visits are
              going.
            </Typography>
          </Box>
        ) : (
          <>
            {/* Visit Details Accordion */}
            {/* <Accordion
              disableGutters
              elevation={0}
              expanded={showDetails}
              onChange={() => setShowDetails((prev) => !prev)} // Toggle the showDetails state
            >
              <AccordionSummary
                sx={{ marginBottom: "16px" }}
                expandIcon={<ExpandMore color="primary" />}
              >
                <Typography color="primary">Visit Details</Typography>
              </AccordionSummary>*/}
            <AccordionDetails sx={{ px: 1 }}>
              <ListItem sx={{ py: 0 }}>
                <ListItemIcon>
                  <EventRounded color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6">
                      {formatDate(moment(visit?.start?.toDate()).toDate())}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItemButton
                onClick={() => handleEditTime()}
                sx={{ borderRadius: "15px", py: 0 }}
              >
                <ListItemIcon>
                  <AccessTime color="primary" />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    borderRadius: "15px",
                    backgroundColor: "white",
                    // marginX: 1,
                    paddingY: 1,
                    pl: 2,
                  }}
                  secondary={
                    <Typography variant="body1">
                      {visit?.employees[currentEmployeeId]?.windows
                        ? Object.values(
                            visit?.employees[currentEmployeeId].windows
                          ).map((window, index) => (
                            <React.Fragment key={index}>
                              {moment(window.start.toDate())
                                .tz(timeZoneId)
                                .format("h:mm a")}{" "}
                              -{" "}
                              {moment(window.end.toDate())
                                .tz(timeZoneId)
                                .format("h:mm a")}
                              <br />
                            </React.Fragment>
                          ))
                        : `${moment(visit?.start?.toDate())
                            .tz(timeZoneId)
                            .format("h:mm a")} - ${moment(visit?.end?.toDate())
                            .tz(timeZoneId)
                            .format("h:mm a")}`}
                    </Typography>
                  }
                />

                <ListItemSecondaryAction sx={{ pr: 2 }}>
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation(); // Stop the ListItemButton's onClick from being triggered
                      handleEditTime();
                    }}
                  >
                    <Edit />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>

              <ListItem sx={{ py: 0 }}>
                <ListItemIcon>
                  <GroupOutlined color="primary" />
                </ListItemIcon>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <ColorAvatar
                    name={`${member?.firstName} ${member?.lastName}`}
                    avatarUrl={member?.avatarUrl}
                    size={"30px"}
                  />

                  <Typography>
                    {member.firstName} {member.lastName}
                  </Typography>
                </Box>
              </ListItem>
              {visit?.description && (
                <ListItem sx={{ py: 0 }}>
                  <ListItemIcon>
                    <Notes color="primary" />
                  </ListItemIcon>
                  <Typography>{visit?.description}</Typography>
                </ListItem>
              )}
            </AccordionDetails>
            <AdditionalHelpList
              visitId={visitId}
              memberName={visit.displayName}
              employeeId={employeeId}
            />
          </>
        )}
      </DialogContent>
    </>
  );
};

export default ReviewVisitEmployee;
