import { CheckCircle } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useMember } from "../../providers/MemberProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { joinEvent } from "../../services/eventServices";
import ResponsiveDialog from "../dialogs/ResponsiveDialog";
import EventConfirmation from "./EventConfirmation";
import EventInformation from "./EventInformation";

const EventDetails = ({ event, timeZoneId }) => {
  const [loading, setLoading] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { data } = useMember();
  const { showSnackbar } = useSnackbar();

  const defaultAddressKey = data?.member?.private?.data?.defaultAddress;
  const matchedAddress = data?.account?.addresses?.[defaultAddressKey];
  const userId = data?.auth.uid;

  const isJoined = event?.signUpList?.some(
    (member) => member.userId === data?.auth?.uid
  );

  console.log("event?.signUpList: ", event?.signUpList);

  if (!event) return <div>No event selected</div>;

  const handleJoin = async () => {
    setLoading(true);
    try {
      console.log("handle join");
      await joinEvent({
        eventId: event.id,
        address: matchedAddress,
        firstName: data?.member?.firstName,
        lastName: data?.member?.lastName,
        avatarUrl: data?.member?.avatarUrl,
        phone: data?.member?.private?.data?.phone,
        email: data?.member?.private?.data?.email,
      });
      // Add any success handling here, if necessary
      console.log("Join event successful");
      setOpenConfirmationDialog(true);
    } catch (error) {
      console.error("Error joining event:", error);
      showSnackbar("Error joining event", "error");
    } finally {
      setLoading(false);
    }
  };

  const getLatestDeadline = (eventDays) => {
    const deadlines = Object.values(eventDays)
      .filter((day) => day.signUpDeadline) // Filter out days without a deadline
      .map((day) => moment(day.signUpDeadline.toDate()).tz(timeZoneId));
    return deadlines.reduce(
      (latest, current) => (latest.isAfter(current) ? latest : current),
      moment(0)
    );
  };
  // Determine if the current time is past the deadline of the last day
  const latestDeadline = getLatestDeadline(event.eventDays);
  const isSignUpDisabled = moment().tz(timeZoneId).isAfter(latestDeadline);

  return (
    <>
      <DialogContent>
        <EventInformation
          event={event}
          timeZoneId={timeZoneId}
          userId={userId}
        />
        {isJoined && (
          <Button
            variant="outlined"
            onClick={() => setOpenConfirmationDialog(true)}
            sx={{
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            Show confirmation details
          </Button>
        )}
      </DialogContent>
      {!isJoined && (
        <DialogActions
          sx={{
            justifyContent: "flex-start",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Button
            onClick={handleJoin}
            color="primary"
            variant="contained"
            disabled={loading || isJoined || isSignUpDisabled}
            sx={{
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "60px" },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : isJoined ? (
              <>
                <CheckCircle sx={{ mr: 1 }} />
                Joined
              </>
            ) : (
              "Sign up"
            )}
          </Button>
        </DialogActions>
      )}
      <ResponsiveDialog
        title={"Confirmed!"}
        open={openConfirmationDialog}
        width={"600px"}
        onClose={() => setOpenConfirmationDialog(false)}
        dialogActions={
          <Button
            onClick={() => setOpenConfirmationDialog(false)}
            color="primary"
            variant="outlined"
            sx={{
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            Close
          </Button>
        }
      >
        <DialogContent>
          <EventConfirmation />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
};

export default EventDetails;
