/* global google */

import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../firebase";
import { useSnackbar } from "../providers/SnackbarProvider";
import { addAddress, createStripeCustomer } from "../services/memberServices";

const INITIAL_VALUES = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  zipCode: "",
  directions: "",
};

const AddAddress = ({
  memberId,
  accountId,
  firstName,
  lastName,
  stripeId,
  phone,
  memberEmail = null, // only define if we are an admin calling the function
  handleSubmit = () => {},
}) => {
  // const { data, setData } = useMember();

  const { showSnackbar } = useSnackbar(); // Use the hook to get showSnackbar function

  const [values, setValues] = useState(INITIAL_VALUES);
  const [errors, setErrors] = useState({});
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  // const [autocomplete, setAutocomplete] = useState(null);
  const [loading, setLoading] = useState(false);

  const addressRef = useRef(null);

  const handleAddressModalOpen = () => {
    setValues(INITIAL_VALUES); // Reset the values
    setAddressModalOpen(true);
    // Once the modal is opened and everything is rendered, initialize the Autocomplete.
    setTimeout(initAutocomplete, 0); // Ensure this runs after rendering is complete
  };

  const handleAddressModalClose = () => {
    setValues(INITIAL_VALUES); // Reset the values
    setAddressModalOpen(false);
  };

  // The functionality to add the address would reside here.
  // You would call the addAddress function you created earlier.
  const handleAddAddress = async (values) => {
    // Validate the address fields first
    if (!validateStep()) {
      console.log("Validation errors detected");
      showSnackbar(
        "Please correct the validation errors before proceeding.",
        "error"
      );
      return;
    }

    setLoading(true);
    try {
      const response = await addAddress({
        accountId,
        ...values,
      });

      console.log("response:", response);

      const { success, geohash, timeZoneId, lat, lng } = response;

      if (success) {
        // Check if stripeId is not set and call createStripeCustomer
        if (!stripeId) {
          // Prepare necessary data for createStripeCustomer
          let customerData = {
            firstName,
            lastName,
            phone,
            city: values.city,
            line1: values.line1,
            line2: values.line2,
            zipCode: values.zipCode,
            state: values.state,
            ...(memberEmail && { memberEmail }), // Conditionally add memberEmail if it exists
          };

          // Call createStripeCustomer without awaiting the result
          createStripeCustomer(customerData)
            .then((response) => {
              console.log("Stripe customer creation initiated:", response);
            })
            .catch((error) => {
              console.error("Error creating Stripe customer:", error);
            });
        }

        const memberDataDocRef = doc(db, "members", memberId);

        await updateDoc(memberDataDocRef, {
          location: {
            city: values.city,
            state: values.state,
            zipCode: values.zipCode, // Assuming you have zipCode in your values object
            geohash: geohash.substring(0, 6),
          },
          timeZoneId: timeZoneId,
          status: "active",
        });

        const memberPrivateDataDocRef = doc(
          db,
          "members",
          memberId,
          "private",
          "data"
        );

        // Utility function to remove periods from a string
        function removePeriods(str) {
          return str.replace(/\./g, "");
        }

        // Using the utility function to create a period-free addressId
        const addressId = `${removePeriods(values.line1)}, ${removePeriods(
          values.city
        )}`; // Create a unique ID using City+line1 without periods

        // Update the 'data' document with the new defaultAddress value
        await updateDoc(memberPrivateDataDocRef, {
          defaultAddress: addressId,
        });

        // NEW: Update the membersPrivate collection
        const membersPrivateDocRef = doc(db, "membersPrivate", memberId);

        await setDoc(
          membersPrivateDocRef,
          {
            location: {
              city: values.city,
              state: values.state,
              zipCode: values.zipCode,
              line1: values.line1,
              line2: values.line2,
              geohash: geohash,
              lat: response?.lat || null,
              lng: response?.lng || null,
              directions: values?.directions || "",
            },
            timeZoneId: timeZoneId,
          },
          { merge: true }
        );

        // Might have possibly been used to refresh the user interface. I'll check.
        // setData((currentData) => ({ ...currentData }));

        handleSubmit();
      } else {
        throw new Error("Failed to add the address");
      }
    } catch (error) {
      console.log("An error occurred while adding the address:", error);
      // Use the showSnackbar function instead of the local setAlert
      showSnackbar("Failed to add the address. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const initAutocomplete = () => {
    console.log("Google object:", typeof google);
    console.log("addressRef:", addressRef.current);

    if (typeof google === "undefined" || !addressRef.current) {
      console.log("Google Maps or address input not found");
      return;
    }

    console.log("Initializing Autocomplete");
    const autocompleteInstance = new google.maps.places.Autocomplete(
      addressRef.current,
      {
        types: ["address"],
      }
    );
    autocompleteInstance.setFields(["address_components"]);
    autocompleteInstance.addListener("place_changed", () =>
      onPlaceSelected(autocompleteInstance)
    );
  };

  useEffect(() => {
    console.log("AddAddress component mounted");
    loadScript(initAutocomplete);
  }, []);

  const onPlaceSelected = (autocompleteInstance) => {
    console.log("Place selected!"); // Add this line

    const place = autocompleteInstance.getPlace();
    if (!place.address_components) return;
    const addressComponents = {};
    place.address_components.forEach((component) => {
      addressComponents[component.types[0]] = component.long_name;
    });
    const line1 = addressComponents.street_number
      ? `${addressComponents.street_number} ${addressComponents.route}`
      : addressComponents.route;
    const line2 = "";
    const city = addressComponents.locality;
    const state = addressComponents.administrative_area_level_1;
    const zipCode = addressComponents.postal_code;

    setValues((prevValues) => ({
      ...prevValues,
      line1,
      line2,
      city,
      state,
      zipCode,
    }));

    setErrors({}); // Clear all errors
  };

  const loadScript = (callback) => {
    if (typeof google !== "undefined") {
      console.log("Google Maps API already loaded");
      if (typeof callback === "function") {
        return setTimeout(callback, 0); // Delay the callback execution
      }
      return; // If callback is not a function, just return
    }

    const GOOGLE_MAPS_SCRIPT_ID = "google-maps-script";
    const existingScript = document.getElementById(GOOGLE_MAPS_SCRIPT_ID);
    if (existingScript) {
      console.log("Script already exists in the document");
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC6k5ohWTgieFep-vs8Wbe6SKWySlSAx9g&libraries=places&callback=initAutocomplete`;
    script.id = GOOGLE_MAPS_SCRIPT_ID;
    script.onload = () => console.log("Google Maps Script loaded successfully");
    script.onerror = () => console.error("Error loading Google Maps Script");
    document.body.appendChild(script);
  };

  useEffect(() => {
    window.initAutocomplete = initAutocomplete;
    loadScript();

    // Add z-index override for .pac-container
    const style = document.createElement("style");
    document.head.appendChild(style);
    style.innerHTML = `.pac-container { z-index: 20000 !important; }`; // Set a value that ensures the dropdown is above the modal

    console.log("running");

    // Cleanup function
    return () => {
      document.head.removeChild(style);
      window.initAutocomplete = null; // Clear the global reference
    };
  }, []);

  // handleChange function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateStep = () => {
    let tempErrors = {};

    if (!values.line1.trim()) tempErrors.line1 = "Address Line 1 is required";
    if (!values.city.trim()) tempErrors.city = "City is required";
    if (!values.state.trim()) tempErrors.state = "State is required";
    if (!values.zipCode.trim()) tempErrors.zipCode = "Zip Code is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // return true if no errors, false otherwise
  };

  return (
    <>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} marginTop={1}>
          <TextField
            name="line1"
            label="Address Line 1"
            value={values.line1}
            onChange={handleChange}
            error={!!errors.line1}
            helperText={errors.line1}
            inputRef={addressRef}
            onFocus={() => console.log("Focus on address input")}
          />
          <TextField
            name="line2"
            label="Address Line 2"
            value={values.line2}
            onChange={handleChange}
          />
          <div style={{ display: "flex", gap: "10px" }}>
            <TextField
              name="city"
              label="City"
              value={values.city}
              onChange={handleChange}
              error={!!errors.city}
              helperText={errors.city}
            />
            <TextField
              name="state"
              label="State"
              value={values.state}
              onChange={handleChange}
              error={!!errors.state}
              helperText={errors.state}
            />
            <TextField
              name="zipCode"
              label="Zip Code"
              value={values.zipCode}
              onChange={handleChange}
              error={!!errors.zipCode}
              helperText={errors.zipCode}
            />
          </div>
          <Accordion
            elevation={0}
            style={{
              boxShadow: "none",
              padding: 0,
              border: "none",
              margin: 0,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              style={{ padding: 0, border: "none", margin: 0 }}
            >
              <Typography>Additional Directions</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0, border: "none", margin: 0 }}>
              <TextField
                fullWidth
                label="Additional directions to find your address"
                variant="outlined"
                name="directions"
                value={values.directions}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          onClick={handleAddressModalClose}
          color="primary"
          sx={{
            minWidth: { xs: "50%", sm: "150px" },
            height: "60px",
            textTransform: "none",
          }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddAddress(values)}
          disabled={loading} // Disable the button while loading data from useMember
          sx={{
            minWidth: { xs: "50%", sm: "200px" },
            height: "60px",
            textTransform: "none",
          }}
        >
          {loading ? <CircularProgress size={24} /> : "Add Address"}
        </Button>
      </DialogActions>
    </>
  );
};

export default AddAddress;
