import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogContent,
  TextField,
} from "@mui/material";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../../firebase";
import { createCommunicationLog } from "../../../services/logServices";
import IconTextButton from "../../buttons/IconTextButton";
import ResponsiveDialog from "../../dialogs/ResponsiveDialog";

import {
  Call,
  CheckBoxRounded,
  Email,
  IndeterminateCheckBoxOutlined,
  NorthEast,
  Person,
  Sms,
  SouthWest,
} from "@mui/icons-material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";
import angryFace from "../../../assets/angry-face.png";
import happyFace from "../../../assets/happy-face.png";
import sadFace from "../../../assets/sad-face.png";
import skepticalFace from "../../../assets/skeptical-face.png";
import smilingFace from "../../../assets/smiling-face.png";

// Interaction options, sentiment, interest, and priority options
const interactionOptions = [
  {
    id: "call",
    text: "Call",
    color: "hsl(140,0%,30%)",
    icon: <Call />,
  },
  {
    id: "email",
    text: "Email",
    color: "hsl(190,0%,30%)",
    icon: <Email />,
  },
  {
    id: "text",
    text: "Text",
    color: "hsl(60,0%,30%)",
    icon: <Sms />,
  },
  {
    id: "in-person",
    text: "In-person",
    color: "hsl(350,0%,30%)",
    icon: <Person />,
  },
];

const sentimentOptions = [
  {
    id: "Absolutely",
    image: smilingFace,
    text: "Absolutely!",
    color: "hsl(140,100%,30%)",
  },
  { id: "Yes", image: happyFace, text: "Yes", color: "hsl(190,100%,30%)" },
  {
    id: "maybe",
    image: skepticalFace,
    text: "Maybe",
    color: "hsl(60,100%,30%)",
  },
  {
    id: "not really",
    image: sadFace,
    text: "Not really",
    color: "hsl(40,100%,30%)",
  },
  { id: "no", image: angryFace, text: "No", color: "hsl(350,100%,30%)" },
];

const interestOptions = [
  {
    id: 5,
    image: smilingFace,
    text: "5",
    color: "hsl(140,100%,30%)",
  },
  {
    id: 4,
    image: happyFace,
    text: "4",
    color: "hsl(190,100%,30%)",
  },
  {
    id: 3,
    image: skepticalFace,
    text: "3",
    color: "hsl(60,100%,30%)",
  },
  {
    id: 2,
    image: sadFace,
    text: "2",
    color: "hsl(40,100%,30%)",
  },
  {
    id: 1,
    image: angryFace,
    text: "1",
    color: "hsl(350,100%,30%)",
  },
];

const priorityOptions = [
  { id: 1, text: "P1", color: "hsl(10,100%,30%)" },
  { id: 2, text: "P2", color: "hsl(50,100%,40%)" },
  { id: 3, text: "P3", color: "hsl(100,100%,30%)" },
  { id: 4, text: "P4", color: "hsl(190,100%,30%)" },
  { id: 5, text: "P5", color: "hsl(250,100%,30%)" },
];

const tagOptions = [
  "mailbox full",
  "mailbox not setup",
  "disconnected number",
  "busy",
  "wrong number",
  "call failed",
  "did not ring",
  "hung up",
];

const buttonConfigs = [
  { action: "add", unit: "day", label: "+1 Day" },
  { action: "add", unit: "week", label: "+1 Week" },
  { action: "add", unit: "month", label: "+1 Month" },
  { action: "subtract", unit: "day", label: "-1 Day" },
  { action: "subtract", unit: "week", label: "-1 Week" },
  { action: "subtract", unit: "month", label: "-1 Month" },
];

const LogCommunicationDialog = ({
  open,
  handleClose,
  idsArr,
  employee,
  client,
  collectionName,
  location,
}) => {
  const [interactionType, setInteractionType] = useState([]);
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [sentiment, setSentiment] = useState("");
  const [interest, setInterest] = useState("");
  const [priority, setPriority] = useState("");
  const [madeContact, setMadeContact] = useState(false);
  const [initiatedByUser, setInitiatedByUser] = useState(true);
  const [loading, setLoading] = useState(false);

  const [communicationDate, setCommunicationDate] = useState(moment());
  const [followUpDate, setFollowUpDate] = useState(
    moment.tz("America/Los_Angeles").add(7, "days").startOf("day")
  );
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const logData = {
        madeContact,
        collectionName,
        idsArr,
        description,
        interactionType,
        initiator: initiatedByUser ? employee : client,
        receiver: initiatedByUser ? client : employee,
        tags,
        sentiment,
        interest,
        priority,
        location,
        communicationDate,
        followUpDate,
      };

      await createCommunicationLog(logData);

      // Update the lead or member's crm document with the new followUpDate, communicationDate,
      // Get the document reference based on collection type and client.id
      const clientDocRef = doc(db, collectionName, client.id);

      console.log("collectionName: ", collectionName);
      console.log("client.id: ", client.id);

      // Prepare the updates
      const updates = {
        [`crm.followUpDate`]: Timestamp.fromDate(followUpDate.toDate()), // Update only this field
        [`crm.lastContactedDate`]: Timestamp.fromDate(
          communicationDate.toDate()
        ), // Update only this field
        [`crm.interest`]: interest, // Update only this field
        [`crm.priority`]: priority, // Update only this field
      };

      // Update the client document
      await updateDoc(clientDocRef, updates);
      handleClose();
    } catch (error) {
      console.error("Error submitting log:", error);
      // Optional: Add user notification or feedback here (e.g., a toast notification)
    } finally {
      setLoading(false);
    }
  };

  const currentDate = moment(); // Get the current date

  // Calculate the difference in days, weeks, and months
  const daysDiff = followUpDate.diff(currentDate, "days");
  const weeksDiff = followUpDate.diff(currentDate, "weeks");
  const monthsDiff = followUpDate.diff(currentDate, "months");

  // Determine the closest unit of measurement
  let closestUnit;
  if (Math.abs(daysDiff) < 7) {
    closestUnit = `${Math.abs(daysDiff)} day${
      Math.abs(daysDiff) === 1 ? "" : "s"
    }`;
  } else if (Math.abs(weeksDiff) < 4) {
    closestUnit = `${Math.abs(weeksDiff)} week${
      Math.abs(weeksDiff) === 1 ? "" : "s"
    }`;
  } else {
    closestUnit = `${Math.abs(monthsDiff)} month${
      Math.abs(monthsDiff) === 1 ? "" : "s"
    }`;
  }

  console.log("priority: ", priority);

  return (
    <ResponsiveDialog
      open={open}
      onClose={handleClose}
      title={"Log Communication"}
      width={"600px"}
      desktopAnchor={"right"}
      anchor={"right"}
      showBackdrop={false}
      fullHeight={true}
      dialogActions={
        <>
          {/* <Button
            onClick={handleClose}
            sx={{
              width: "50%",
              height: "60px",
            }}
          >
            Cancel
          </Button> */}
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={loading} // Disable the button while loading data from useMember
            color="primary"
            sx={{
              width: "100%",
              height: "60px",
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Create log"}
          </Button>
        </>
      }
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: 2,
            }}
          >
            <MobileDatePicker
              label="Communication date"
              inputFormat="MM/DD/YYYY"
              value={communicationDate}
              onChange={(newDate) => setCommunicationDate(newDate)}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mb: 2 }} />
              )}
              sx={{ width: "100%" }}
            />

            {/* <TimePicker
            label="Follow up time"
            minutesStep={5}
            value={startTime}
            onChange={(newTime) => setStartTime(newTime)}
            renderInput={(params) => (
              <TextField {...params} fullWidth sx={{ mb: 2 }} />
            )}
            sx={{ maxWidth: "200px" }}
          /> */}
          </Box>

          {/* Initiated by user */}
          <Button
            fullWidth
            variant={initiatedByUser ? "outlined" : "outlined"}
            color={initiatedByUser ? "primary" : "success"}
            onClick={() => setInitiatedByUser(!initiatedByUser)}
            sx={{
              textTransform: "none",
              mt: 2,
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // Distribute space between items
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {initiatedByUser ? <NorthEast /> : <SouthWest />}
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              {initiatedByUser ? "Initiated by myself" : "Initiated by senior"}
            </Box>
          </Button>

          {/* Interaction Type IconTextButton */}
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, my: 2 }}>
            {interactionOptions.map((option) => (
              <IconTextButton
                key={option.id}
                option={option}
                isSelected={interactionType.includes(option.id)} // Check if selected by id
                onClick={() => {
                  setInteractionType(
                    (prev) =>
                      prev.includes(option.id) // Check if the id is already in the array
                        ? prev.filter((item) => item !== option.id) // Remove the id if it exists
                        : [...prev, option.id] // Add the id if it doesn't exist
                  );
                }}
              />
            ))}
          </Box>

          {interactionType.length > 0 && (
            <>
              {/* Made Contact Toggle */}
              <Button
                fullWidth
                variant={madeContact ? "outlined" : "outlined"}
                color={madeContact ? "success" : "error"}
                onClick={() => setMadeContact(!madeContact)}
                sx={{
                  textTransform: "none",
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Distribute space between items
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {madeContact ? (
                    <CheckBoxRounded />
                  ) : (
                    <IndeterminateCheckBoxOutlined />
                  )}
                </Box>
                <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                  {madeContact ? "Contact Made" : "No Contact Made"}
                </Box>
              </Button>

              {/* Description */}
              <TextField
                label="Notes"
                fullWidth
                sx={{ my: 2 }}
                multiline
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {madeContact && (
                <>
                  {/* Sentiment IconTextButton */}
                  {/* <Typography mt={2}>Sentiment</Typography>
        <Box sx={{display: "flex", flexWrap: "wrap", gap: 1, my: 2,}}>
          {sentimentOptions.map((option) => (
            <IconTextButton
              key={option.id}
              option={option}
              isSelected={sentiment === option.text}
              onClick={(selected) => setSentiment(selected)}
            />
          ))}
        </Box> */}

                  {/* Interest IconTextButton */}

                  {/* <Typography mt={2}>Interest</Typography> */}
                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}
                  >
                    {interestOptions.map((option) => (
                      <IconTextButton
                        key={option.id}
                        option={option}
                        isSelected={interest === option.id}
                        onClick={() => setInterest(option.id)} // Pass the id instead of text
                      />
                    ))}
                  </Box>
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  position: "relative",
                }}
              >
                <MobileDatePicker
                  label="Follow up date"
                  inputFormat="MM/DD/YYYY"
                  value={followUpDate}
                  onChange={(newDate) => setFollowUpDate(newDate)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ mb: 0 }} />
                  )}
                  sx={{ width: "100%" }}
                />
                {closestUnit && (
                  <Chip
                    label={closestUnit}
                    sx={{
                      position: "absolute",
                      right: 12, // Adjust the distance from the right
                      borderRadius: "5px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      marginLeft: 1, // Adjust margin for spacing
                      zIndex: 1, // Ensure Chip stays on top
                    }}
                  />
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  gap: 1,
                  my: 2,
                }}
              >
                {buttonConfigs.map(({ action, unit, label }) => (
                  <Box
                    key={`${action}-${unit}`}
                    sx={{ flex: "1 1 calc(33% - 10px)" }} // Adjusts button width and margin
                  >
                    <Button
                      fullWidth
                      variant="outlined"
                      sx={{ height: "60px", textTransform: "none" }}
                      onClick={() =>
                        setFollowUpDate(followUpDate.clone()[action](1, unit))
                      }
                    >
                      {label}
                    </Button>
                  </Box>
                ))}
              </Box>

              {/* Tags Autocomplete */}
              <Autocomplete
                multiple
                options={tagOptions} // Use the defined tagOptions array
                freeSolo
                value={tags} // Bind to tags state
                onChange={(event, newValue) => {
                  setTags(newValue);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Tags"
                    variant="outlined"
                  />
                )}
                sx={{ my: 2 }} // Spacing for the Autocomplete
              />

              {/* Priority IconTextButton */}
              {/* <Typography mt={2}>Priority</Typography> */}
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, my: 2 }}>
                {priorityOptions.map((option) => (
                  <IconTextButton
                    key={option.id}
                    option={option}
                    isSelected={priority === option.id}
                    onClick={() => setPriority(option.id)} // Pass the id instead of text
                  />
                ))}
              </Box>
            </>
          )}
        </DialogContent>
      </LocalizationProvider>
    </ResponsiveDialog>
  );
};

export default LogCommunicationDialog;
