import { Cancel, Search } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
} from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import _ from "lodash"; // Ensure lodash is installed for debounce functionality
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { formatPhoneNumber } from "../../services/inputServices";
import ColorAvatar from "../ColorAvatar";

function MemberSearchBar({ onResultSelect = () => {} }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessionCache, setSessionCache] = useState({});

  const handleChange = (event) => {
    setSearchTerm(
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
    );
  };

  useEffect(() => {
    console.log("running useEffect");
    if (searchTerm === "") {
      // Aggregate all unique results from the cache.
      const allCachedUsers = Object.values(sessionCache).flat();
      const uniqueCachedUsersMap = {};

      allCachedUsers.forEach((user) => {
        uniqueCachedUsersMap[user.id] = user;
      });

      const uniqueCachedMembers = Object.values(uniqueCachedUsersMap);

      setResults(uniqueCachedMembers);
      return;
    }

    if (sessionCache[searchTerm]) {
      // If results for the current term are cached, use them!
      setResults(sessionCache[searchTerm]);
      return;
    }

    setLoading(true);
    const debouncedSearch = _.debounce(searchMembers, 500); // Debouncing to minimize the number of requests

    debouncedSearch(searchTerm);

    // Cleanup the debounce
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, sessionCache]);

  const searchMembers = async (searchText) => {
    console.log("running searchMembers");

    if (!searchText.trim()) {
      setResults([]); // or set it to default results list if you have it.
      return;
    }

    setLoading(true);

    // Queries for each MEMBER field
    const memberFirstNameQuery = query(
      collection(db, "members"),
      where("firstName", ">=", searchTerm),
      where("firstName", "<=", searchTerm + "\uf8ff")
    );
    const memberLastNameQuery = query(
      collection(db, "members"),
      where("lastName", ">=", searchTerm),
      where("lastName", "<=", searchTerm + "\uf8ff")
    );

    const memberZipCodeQuery = query(
      collection(db, "members"),
      where("location.zipCode", ">=", searchTerm),
      where("location.zipCode", "<=", searchTerm + "\uf8ff")
    );

    // Queries for each LEAD field
    const leadNameQuery = query(
      collection(db, "leads"),
      where("name", ">=", searchTerm),
      where("name", "<=", searchTerm + "\uf8ff")
    );
    const leadPhoneQuery = query(
      collection(db, "leads"),
      where("phone", ">=", searchTerm),
      where("phone", "<=", searchTerm + "\uf8ff")
    );

    try {
      // Fetch data separately
      const [
        memberFirstNameSnapshot,
        memberLastNameSnapshot,
        memberZipCodeSnapshot,
        leadNameSnapshot,
        leadPhoneSnapshot,
      ] = await Promise.all([
        getDocs(memberFirstNameQuery),
        getDocs(memberLastNameQuery),
        getDocs(memberZipCodeQuery),
        getDocs(leadNameQuery),
        getDocs(leadPhoneQuery),
      ]);

      const resultSet = {}; // Using an object to store results with unique IDs

      // Process each snapshot with type and add it to the object
      const processSnapshot = (snapshot, type) => {
        snapshot.forEach((doc) => {
          const data = { id: doc.id, ...doc.data(), type }; // Add type field
          resultSet[data.id] = data;
        });
      };

      processSnapshot(memberFirstNameSnapshot, "member");
      processSnapshot(memberLastNameSnapshot, "member");
      processSnapshot(memberZipCodeSnapshot, "member");
      processSnapshot(leadNameSnapshot, "lead");
      processSnapshot(leadPhoneSnapshot, "lead");

      // Convert the object values to an array
      const uniqueResults = Object.values(resultSet);

      setResults(uniqueResults);
      setLoading(false);

      // Storing data in session cache
      setSessionCache((prevState) => {
        return { ...prevState, [searchTerm]: uniqueResults };
      });
    } catch (error) {
      console.error("Error fetching results: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleListItemClick = (user) => {
    onResultSelect(user);
  };

  const renderList = () => {
    if (loading) {
      return (
        <div>
          <ListItemButton>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ marginRight: 2 }}
            />
            <ListItemText
              primary={<Skeleton variant="text" width={100} />}
              secondary={<Skeleton variant="text" width={200} />}
            />
          </ListItemButton>
        </div>
      );
    }
    return results.map((user) => (
      <ListItemButton
        key={user.id}
        onClick={() => handleListItemClick(user)}
        sx={{ borderRadius: "15px", gap: 1 }}
      >
        {user.type === "member" && (
          <>
            <ColorAvatar
              name={`${user.firstName} ${user.lastName}`}
              avatarUrl={user.avatarUrl}
              size={"40px"}
            />
            <ListItemText
              primary={`${user.firstName} ${user.lastName}`}
              secondary={`${user?.location?.city}, ${user?.location?.state}`}
            />
          </>
        )}
        {user.type === "lead" && (
          <>
            <ColorAvatar
              name={user.name}
              avatarUrl={user.avatarUrl}
              size={"40px"}
            />
            <ListItemText
              primary={user.name}
              secondary={`${formatPhoneNumber(user.phone)}${
                user?.location?.zipCode ? ` • ${user.location.zipCode}` : ""
              }`}
            />
          </>
        )}
      </ListItemButton>
    ));
  };

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search for a member..."
        value={searchTerm}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm && (
                <IconButton
                  aria-label="clear search"
                  onClick={() => setSearchTerm("")}
                  edge="end"
                >
                  <Cancel />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <List>{renderList()}</List>
    </>
  );
}

export default MemberSearchBar;
