import EmployeesTab from "./tabs/EmployeesTab.js";
import HomePage from "./tabs/HomePage.js";
import Visits from "./tabs/Visits";

import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase.js";

import {
  Add,
  Badge,
  BadgeOutlined,
  Checklist,
  ChecklistOutlined,
  Home,
  HomeOutlined,
  LiveHelp,
  LiveHelpOutlined,
  SettingsOutlined,
  SettingsRounded,
  Today,
  TodayOutlined,
} from "@mui/icons-material";
import { Box, Fab, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import AnnouncementDialog from "../../components/AnnouncementDialog";
import ChangePassword from "../../components/ChangePassword";
import ScheduleVisitAdmin from "../../components/ScheduleVisitAdmin.js";
import SimplePage from "../../components/SimplePage";
import ResponsiveDialog from "../../components/dialogs/ResponsiveDialog.js";
import FAQSection from "../../pages/public/sections/FAQSection.js";
import ServicesGrid from "../../pages/public/sections/ServicesGrid.js";
import { useMember } from "../../providers/MemberProvider";
import { SnackbarProvider } from "../../providers/SnackbarProvider";
import VisitProvider from "../../providers/VisitProvider";
import BottomNav from "../BottomNav";
import ContentView from "../ContentView";
import Sidebar from "../Sidebar";
import Settings from "./tabs/Settings";
import FAQTab from "./tabs/FAQTab.js";
import { createLog } from "../../services/logServices.js";

const tabs = [
  {
    label: "Home",
    color: "#eceff1",
    component: HomePage,
    icon: <Home />,
    outlineIcon: <HomeOutlined />,
  },
  {
    label: "Visits",
    color: "#eceff1",
    component: Visits,
    icon: <Today />,
    outlineIcon: <TodayOutlined />,
  },
  // {
  //   label: "Activity",
  //   color: "#eceff1",
  //   component: Social,
  //   icon: <Handshake />,
  //   outlineIcon: <HandshakeOutlined />,
  // },

  // { label: "Settings", component: Profile, icon: <AccountCircle /> },
  {
    label: "Employees",
    color: "#eceff1",
    component: EmployeesTab,
    icon: <Badge />,
    outlineIcon: <BadgeOutlined />,
  },
  {
    label: "FAQ",
    component: FAQTab,
    icon: <LiveHelp />,
    outlineIcon: <LiveHelpOutlined />,
  },
  {
    label: "Services",
    component: ServicesGrid,
    icon: <Checklist />,
    outlineIcon: <ChecklistOutlined />,
  },
  {
    label: "Settings",
    component: Settings,
    icon: <SettingsRounded />,
    outlineIcon: <SettingsOutlined />,
  },

  // { label: 'Book a Visit', component: ScheduleVisit, icon: <Add /> },
];

const mobileOnlyTabs = ["Home", "Visits", "FAQ", "Employees", "Settings"]; // Add the labels of tabs you want to show only on mobile here

const MemberDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, isServiceArea } = useMember();

  const [selectedTab, setSelectedTab] = useState(tabs[0].label);
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const [scheduleVisitModalOpen, setScheduleVisitModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedVisit, setSelectedVisit] = useState({});
  const [passwordOpen, setPasswordOpen] = useState(false);

  const defaultAddressKey = data?.member?.private?.data?.defaultAddress;
  const matchedAddress = data?.account?.addresses?.[defaultAddressKey];

  // Handler to open ScheduleVisit modal
  const handleOpenScheduleVisitModal = () => {
    setScheduleVisitModalOpen(true);
    createLog({
      collectionName: "members",
      idsArr: [data?.auth?.uid],
      summary: `Clicked schedule a visit.`,
      logType: "activity",
    });
  };

  // Handler to close ScheduleVisit modal
  const handleCloseScheduleVisitModal = () => {
    console.log("======= CLOSED DRAWER ===========");
    if (activeStep == 3) {
      setActiveStep(0);
      setSelectedVisit({}); // Reset selectedVisit
    }
    setScheduleVisitModalOpen(false);
  };

  const handleTabChange = (tabLabel) => {
    // Scroll to the top when tab changes
    window.scrollTo(0, 0);
    setSelectedTab(tabLabel);
  };

  useEffect(() => {
    // Just a mock check, replace this with your actual logic for checking if the user is new
    const isNewUser = true; // Replace with logic to check if user is new
    if (isNewUser) {
      // setWelcomeModalOpen(true);
    }
  }, []);

  // If there are no active employees, set the member's status to waitlisted. This prevents them from getting billed
  useEffect(() => {
    const updateMemberStatus = async () => {
      try {
        const uid = data?.auth?.uid;
        if (!uid) return;

        const status = data?.member.status;
        if (status !== "waitlist" && status !== "active") return;

        const memberRef = doc(db, "members", uid); // Replace 'members' with your collection name
        const newStatus = isServiceArea ? "active" : "waitlist";

        await updateDoc(memberRef, {
          status: newStatus,
        });

        console.log(`Member status updated to ${newStatus}`);
      } catch (error) {
        console.error("Error updating member status:", error);
      }
    };

    updateMemberStatus();
  }, [isServiceArea]); // Dependency array includes data and isServiceArea

  return (
    <SnackbarProvider>
      {data?.member?.private?.data?.hasLoggedIn === false ? (
        <SimplePage>
          <Typography variant="body1">
            Welcome {data?.member?.firstName}
          </Typography>
          <Typography variant="h6">Create your password</Typography>

          <ChangePassword setOpen={setPasswordOpen} disableCancel={true} />
        </SimplePage>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              height: "100%",
            }}
          >
            {isMobile ? (
              <>
                <ContentView tabs={tabs} selectedTab={selectedTab} />
                <BottomNav
                  tabs={tabs}
                  selectedTab={selectedTab}
                  setTab={handleTabChange}
                  mobileOnlyTabs={mobileOnlyTabs}
                />
              </>
            ) : (
              <>
                <Sidebar
                  tabs={tabs}
                  selectedTab={selectedTab}
                  onChangeTab={handleTabChange}
                />
                <ContentView tabs={tabs} selectedTab={selectedTab} />
              </>
            )}
            <ResponsiveDialog
              title={"Schedule a visit"}
              open={scheduleVisitModalOpen}
              onClose={handleCloseScheduleVisitModal}
              width="800px"
              fullHeight={true}
              maxHeight="583px"
              backgroundCloses={false}
            >
              {scheduleVisitModalOpen && (
                <VisitProvider
                  employees={data?.employees}
                  member={data?.member}
                  memberId={data?.auth?.uid}
                  address={matchedAddress}
                >
                  <ScheduleVisitAdmin
                    handleClose={handleCloseScheduleVisitModal}
                  />
                </VisitProvider>
              )}
            </ResponsiveDialog>
            <AnnouncementDialog
              userCollection={"members"}
              user={data?.member}
              userId={data?.auth?.uid}
              open={welcomeModalOpen}
              setOpen={setWelcomeModalOpen}
              handleClose={() => setWelcomeModalOpen(false)}
            />
          </Box>
          {isServiceArea && (
            <Fab
              color="primary"
              aria-label="add"
              variant="extended"
              sx={{
                position: "fixed",
                right: isMobile ? theme.spacing(1) : theme.spacing(3),
                bottom: isMobile ? theme.spacing(10) : theme.spacing(3),
              }}
              onClick={handleOpenScheduleVisitModal}
            >
              <Box display="flex" alignItems="center" gap={1} margin={1}>
                <Add />
                {!isMobile ? "Book a Visit" : null}
              </Box>
            </Fab>
          )}
        </>
      )}
    </SnackbarProvider>
  );
};

export default MemberDashboard;
