import { AddIcCall, PlaylistAddCheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import {
  Timestamp,
  addDoc,
  collection
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";
import React, { useState } from "react";
import { db, functions } from "../../firebase";
import { extractPhoneNumber } from "../../services/inputServices";
import PhoneField from "../fields/PhoneField";

const NewLead = ({ employeeId, handleClose }) => {
  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    zipCode: "",
    additionalNotes: "",
    interestLevel: 5,
    followUpDate: "",
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showIntro, setShowIntro] = useState(true); // New state to control intro screen display
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
    if (!values.name) newErrors.name = "Name is required";
    if (values.phone && values.phone.replace(/\D/g, "").length !== 10) {
      newErrors.phone = "Phone must be 10 digits";
    }
    if (values.zipCode.length < 5)
      newErrors.zipCode = "Zip code must be 5 digits";
    if (values.email && !emailRegex.test(values.email))
      newErrors.email = "Invalid email format";
    if (!values.phone && !values.email)
      newErrors.contact = "Either phone or email is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    } else if ((name === "phone" || name === "email") && errors.contact) {
      const newErrors = { ...errors };
      delete newErrors.contact;
      setErrors(newErrors);
    }
  };

  const handleInterestChange = (event, newValue) => {
    setValues({ ...values, interestLevel: newValue });
  };

  const handleDateChange = (event) => {
    const newDate = event.target.value; // Assuming the event contains the new date
    // Format the date to YYYY-MM-DD if necessary
    setValues({ ...values, followUpDate: newDate });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!validateForm()) {
      console.error("Form validation failed");
      setLoading(false);
      return; // Stop the submission if validation fails
    }

    const leadData = {
      name: values?.name || "",
      phone: extractPhoneNumber(values?.phone || ""),
      email: values?.email || "",
      location: {
        zipCode: values?.zipCode || "",
      },
      crm: {
        leadCreated: new Date(),
        lastContactedDate: false,
        followUpDate: Timestamp.fromDate(
          moment(values?.followUpDate || new Date()).toDate()
        ),
        contactedCount: 0,
        source: "employee_submitted_lead",
        timeline: {
          employee_submitted_lead: new Date(),
        },
        interest: values?.interestLevel || 5,
      },
    };

    const cleanData = (data) => {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([_, v]) => v !== undefined && v !== null && v !== "")
          .map(([k, v]) =>
            v && typeof v === "object" && !(v instanceof Date)
              ? [k, cleanData(v)]
              : [k, v]
          )
      );
    };

    // Clean leadData to avoid sending undefined, null, or empty values
    const filteredLeadData = cleanData(leadData);

    try {
      // First, add the new lead document and get its reference
      // const leadRef = await addDoc(collection(db, "leads"), filteredLeadData);
      // console.log("Lead recorded successfully:", filteredLeadData);

      const createLead = httpsCallable(functions, "createLead");
      createLead(filteredLeadData);

      // Now, create the log entry with the new lead's ID
      // const logData = {
      //   leadId: leadRef.id, // Use the ID of the newly created lead document
      //   type: "created",
      //   date: serverTimestamp(),
      //   notes: values.additionalNotes,
      //   employee: employeeId,
      // };

      // Add the log entry to a separate collection, referencing the new lead ID
      // await addDoc(collection(db, "communications"), logData);
      // console.log("Log entry created successfully:", logData);

      setSubmitted(true);
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setLoading(false);
    }
  };

  if (showIntro) {
    return (
      <>
        <DialogContent>
          <Box textAlign="center" sx={{ my: 4 }}>
            <AddIcCall color="primary" sx={{ fontSize: 40, mb: 1 }} />
            <Typography variant="h6" sx={{ mb: 1 }}>
              Add a New Lead
            </Typography>
            <Chip
              label="$5 Bonus"
              color="success"
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <Typography align="left" variant="body1" sx={{ mb: 2 }}>
              Add a new lead if a person wants to learn more, but is not ready
              to sign up yet.
            </Typography>
            <Typography align="left" variant="body1" sx={{ mb: 2 }}>
              Once we verify this new lead, you will receive your bonus in your
              next paycheck. You will only get the New Lead bonus, not both.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Button
            onClick={() => setShowIntro(false)}
            variant="contained"
            sx={{
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </>
    );
  }

  if (submitted) {
    return (
      <>
        <DialogContent>
          <Box textAlign="center" sx={{ my: 4 }}>
            <PlaylistAddCheckCircle
              color="primary"
              sx={{ fontSize: 40, mb: 1 }}
            />
            <Typography variant="h6" sx={{ mb: 1 }}>
              Lead Recorded
            </Typography>
            <Typography align="center" variant="body1" gutterBottom>
              Nice work! Once this lead is verified, you will receive your bonus
              in your next paycheck.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              minWidth: { xs: "100%", sm: "150px" },
              height: { xs: "60px", sm: "auto" },
            }}
          >
            Go Back
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Box sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
            <TextField
              required
              fullWidth
              id="name"
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
            />
            <Divider sx={{ my: 2 }} />
            <PhoneField
              phone={values.phone}
              error={errors.phone}
              handleChange={handleChange}
            />
            <TextField
              fullWidth
              name="email"
              type="email"
              label="Email"
              value={values.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            {/* Display error for either phone or email requirement */}
            {errors.contact && (
              <Typography color="error" variant="body2">
                {errors.contact}
              </Typography>
            )}
            <TextField
              fullWidth
              name="zipCode"
              label="Zip Code"
              value={values.zipCode}
              onChange={(e) => {
                const zip = e.target.value;
                // Only allow numbers and ensure it's no longer than 5 digits
                if (/^\d{0,5}$/.test(zip)) {
                  handleChange(e);
                }
              }}
              inputProps={{ maxLength: 5, inputMode: "numeric" }} // Limit to 5 digits and show numeric keypad on mobile
              error={!!errors.zipCode}
              helperText={errors.zipCode}
            />
            <TextField
              label="Follow up on"
              name="followUpDate"
              type="date"
              fullWidth
              margin="normal"
              value={values.followUpDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
            />
            <Box
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.23)",
                px: 2,
                py: 1,
                borderRadius: "15px",
              }}
            >
              <Typography
                sx={{ py: 1 }}
                align="left"
                id="interest-level-slider"
                gutterBottom
              >
                Interest Level
              </Typography>
              <Slider
                aria-labelledby="interest-level-slider"
                value={values.interestLevel}
                onChange={handleInterestChange}
                valueLabelDisplay="auto"
                step={1}
                marks
                min={1}
                max={5}
                name="interestLevel"
              />
            </Box>

            <TextField
              fullWidth
              label="Additional Notes"
              variant="standard"
              name="additionalNotes"
              value={values.additionalNotes}
              onChange={(event) =>
                setValues({ ...values, additionalNotes: event.target.value })
              }
              multiline
              minRows={1}
              style={{ marginTop: "20px" }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
          sx={{
            minWidth: { xs: "100%", sm: "150px" },
            height: { xs: "60px", sm: "auto" },
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
        </Button>
      </DialogActions>
    </>
  );
};

export default NewLead;
