import { ArrowForwardIosRounded } from "@mui/icons-material";
import { Box, ListItemButton, Skeleton, Typography } from "@mui/material"; // Import Material-UI components
import React, { useState } from "react";
import PushDrawer from "../PushDrawer";
import VisitListItem from "../VisitListItem";
import VisitCard from "./VisitCard";

const VisitOverviewCard = ({
  visit, // needs to have id pushed in as well
  loading = false,
  localEmployees, // for rescheduling
  eId = "",
  eName = "",
}) => {



  const [showVisit, setShowVisit] = useState(false);

  const handleVisitClose = () => {
    setShowVisit(false);
    // setSelectedVisit(null); // Set selectedVisit to null inline
  };

  return (
    <>
      <Box>
        {loading ? (
          // Show a loading skeleton while fetching data
          <Box sx={{ py: 2 }}>
            <Skeleton variant="rounded" height={72}>
              {/* <Box
                variant="circular"
                height={56}
                width={56}
                sx={{ mr: "24px", backgroundColor: "white" }}
              />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box
                  variant="rectangular"
                  sx={{ borderRadius: "15px", backgroundColor: "white" }}
                  height={24}
                  width={150}
                />
                <Box
                  variant="rectangular"
                  sx={{ borderRadius: "15px", backgroundColor: "white" }}
                  height={20}
                  width={100}
                />
              </Box> */}
            </Skeleton>
          </Box>
        ) : visit ? (
          // Show the next visit if available
          <Box sx={{ py: 2 }}>
            <ListItemButton
              onClick={() => setShowVisit(true)}
              sx={{
                display: "flex",
                borderRadius: "15px",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#F5F5F5",
              }}
            >
              <VisitListItem visit={visit} compact={true} margins="small" />
              <ArrowForwardIosRounded color="primary" />{" "}
              {/* Set the arrow color */}
            </ListItemButton>
          </Box>
        ) : (
          // Show a message if there are no upcoming visits
          <Box sx={{ py: 2 }}>
            <Box
              sx={{
                background: "#F5F5F5",
                width: "auto",
                height: "72px",
                borderRadius: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography variant="body1" color="textSecondary">
                No upcoming visits.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <PushDrawer
        title={"Visit Details"}
        minWidth={"600px"}
        open={showVisit}
        onOpen={() => setShowVisit(true)}
        onClose={handleVisitClose}
        hideBackdrop={false}
      >
        {visit && (
          <VisitCard
            key={visit.id}
            visit={visit}
            visitId={visit.id}
            localEmployees={localEmployees}
            handleClose={handleVisitClose}
            adminView={true}
            eId={eId}
            eName={eName}
          />
        )}
      </PushDrawer>
    </>
  );
};

export default VisitOverviewCard;
