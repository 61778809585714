import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import GuestHeader from "../../components/GuestHeader";
import useLogPageVisit from "../../hooks/useLogPageVisit";
import ContactSectionV2 from "./sections/ContactSectionV2";
import CountdownSection from "./sections/CountdownSection";
import FAQSection from "./sections/FAQSection";
import MembershipDetails from "./sections/MembershipDetails";
import OurMembers from "./sections/OurMembers";
import SentimentSection from "./sections/SentimentSection";
import TestimonialVideoSection from "./sections/TestimonialVideoSection";
import WebFooter from "./sections/WebFooter";
import ServiceLookup from "../../components/locations/ServiceLookup";

function MembershipPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        value: 2,
        currency: "USD",
      });
    }
  }, []);

  useLogPageVisit("/pricing");

  return (
    <div>
      <Helmet>
        <title>Reliable and Flexible Odd Job Help</title>
        <meta
          name="description"
          content="For those seeking odd job help, Linked Lives offers seniors a membership-based service that guarantees a helping hand when you need it most."
        />
      </Helmet>
      <GuestHeader />
      {/* <FreeHourOffer /> */}
      {/* <InfiniteScrollAvatars /> */}
      {/* <PricingSectionV2 /> */}
      <MembershipDetails pageName="/pricing" />
      <ContactSectionV2 pageName="/pricing" />
      {/* <PricingHero /> */}
      <TestimonialVideoSection pageName="/pricing" />
      {/* <PricingSection /> */}
      {/* <PricingTable /> */}
      {/* <MembershipLanding /> */}
      <OurMembers pageName="/pricing" />
      {/* <MembershipOptions /> */}
      <SentimentSection pageName="/pricing" />
      <FAQSection pageName="/pricing" />
      <ServiceLookup pageName="/home" />

      {/* <Typography align="center" variant="h4">
        Ready?
      </Typography>
      <Typography align="center" variant="body1">
        Join over {Math.floor(MEMBER_COUNT / 10) * 10}+ seniors who count on us
        for their household help.
      </Typography>
      <GetStartedButton /> */}
      {/* <WheelPopup fullScreen={true} /> */}
      <CountdownSection pageName="/pricing" />

      <WebFooter pageName="/pricing" />
    </div>
  );
}

export default MembershipPage;
