import {
  ChatOutlined,
  Login as LoginIcon,
  LoyaltyOutlined,
  PhoneOutlined,
  SmsOutlined,
  Today,
} from "@mui/icons-material";
import React from "react";
import ActionButtonRow from "../../buttons/ActionButtonRow";
import { Box } from "@mui/material";

const MemberActions = ({
  phoneNumber,
  onScheduleVisit,
  onLogConvo,
  onSendLoginLink,
  onApplyDiscount,
}) => {
  const actions = [
    {
      label: "Book",
      tooltip: "Schedule a visit",
      icon: <Today />,
      onClick: onScheduleVisit,
    },
    {
      label: "Log",
      tooltip: "Log a conversation",
      icon: <ChatOutlined />,
      onClick: onLogConvo,
    },
    {
      label: "Call",
      tooltip: `Copy phone number`,
      icon: <PhoneOutlined />,
      onClick: () => navigator.clipboard.writeText(phoneNumber),
    },
    {
      label: "Text",
      tooltip: `Copy phone number`,
      icon: <SmsOutlined />,
      onClick: () => navigator.clipboard.writeText(phoneNumber),
    },
    {
      label: "Login",
      tooltip: "Send login link",
      icon: <LoginIcon />,
      onClick: onSendLoginLink,
    },
    {
      label: "Discount",
      tooltip: "Create a discount",
      icon: <LoyaltyOutlined />,
      onClick: onApplyDiscount,
    },
  ];

  return (
    <Box
    sx={{
      backgroundColor: "#F5F5F5",
      mx: {xs: -3, sm: 0},
      borderRadius: {xs: 0, sm: "15px"},
      py: 1,
      px: 2,
      // borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      // borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      overflowX: "auto", // Enable horizontal scrolling
      overflowY: "hidden", // Disable vertical scrolling
      whiteSpace: "nowrap", // Prevent line breaks
      "&::-webkit-scrollbar": {
        display: "none", // Hide the scrollbar for webkit browsers
      },
    }}
    >
      <ActionButtonRow actions={actions} />
    </Box>
  );
};

export default MemberActions;
