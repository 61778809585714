import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import GuestHeader from "../../components/GuestHeader";

import { useTheme } from "@mui/material"; // Don't forget to import useTheme
import WebFooter from "./sections/WebFooter";
import useLogPageVisit from "../../hooks/useLogPageVisit";

function PrivacyPolicyPage() {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useLogPageVisit("/privacy-policy");


  return (
    <div>
      <GuestHeader />

      <Box
        sx={{
          paddingTop: theme.spacing(4),
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            paddingY: theme.spacing(4),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              maxWidth: "800px",
              // height: "100%",
            }}
          >
            <Typography variant="h4" fontWeight="700" gutterBottom>
              Linked Lives Privacy Policy.
            </Typography>
            <div>
              <p>Last Updated: Aug 6, 2024</p>
              <h4>Privacy Policy Overview</h4>
              <p>
                Linked Lives ("we," "our," "us") is committed to protecting your
                privacy. This Privacy Policy explains how we collect, use, and
                share your personal information when you visit our website and
                use our services.
              </p>
              <h4>Information Collection</h4>
              <p>
                Linked Lives, headquartered in Anacortes, Washington, collects
                personal information to provide and improve our services. The
                types of personal information we collect include names, email
                addresses, phone numbers, and physical addresses. This
                information is collected through sign-up forms, contact forms,
                and over the phone.
              </p>
              <h4>Use of Personal Information</h4>
              <p>
                We use the personal information we collect to communicate with
                you for business purposes, marketing, advertising, promotions,
                updates, and reminders. By providing us with your personal
                information, you consent to receiving such communications.
              </p>
              <h4>Sharing of Personal Information</h4>
              <p>
                Linked Lives shares personal information with third-party
                service providers to facilitate our operations. Specifically, we
                share information with Stripe for payment processing and Twilio
                to send text messages. These third-party providers are only
                given access to the information necessary to perform their
                specific services and are required to protect your information.
              </p>
              <h4>Data Protection</h4>
              <p>
                We take the security of your personal information seriously. All
                personal data is stored on secure servers with strict access
                control measures in place. We do not store payment information;
                all payment details are securely handled by Stripe.
              </p>
              <h4>User Rights</h4>
              <p>
                You have the right to access, correct, or delete your personal
                information at any time. If you wish to opt out of our
                communications or delete your data, please contact us at
                alex@ourlinkedlives.com. We will process your request promptly
                and ensure that your data is handled according to your wishes.
              </p>
              <h4>Cookies</h4>
              <p>
                Linked Lives does not use cookies or other tracking technologies
                on our website.
              </p>
              <h4>Minors</h4>
              <p>
                Our website and services are not intended for minors under the
                age of 13. We do not knowingly collect personal information from
                minors. If we become aware that we have inadvertently received
                personal information from a minor, we will delete such
                information from our records.
              </p>
              <h4>Policy Updates</h4>
              <p>
                Linked Lives may update this Privacy Policy from time to time to
                reflect changes in our practices or for other operational,
                legal, or regulatory reasons. We will notify you of any
                significant changes by email.
              </p>
              <h4>Contact Information</h4>
              <p>
                If you have any questions or concerns about our Privacy Policy
                or your personal information, please contact us at
                alex@ourlinkedlives.com. We are here to help and will respond to
                your inquiries promptly.
              </p>
            </div>
          </Box>
        </Container>
      </Box>
      <WebFooter />
    </div>
  );
}

export default PrivacyPolicyPage;
