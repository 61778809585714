import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";

// Define the ActionButtonRow component
const ActionButtonRow = ({ actions }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 2,
        flexWrap: "no-wrap",
        width: "min-content",
        mx: "auto",
      }}
    >
      {actions.map((action, index) => (
        <Box key={index} sx={{ textAlign: "center" }}>
          <Tooltip title={action.tooltip} arrow placement="top">
            <IconButton
              onClick={action.onClick}
              disabled={action.disabled}
              sx={{
                padding: "6px",
                height: "54px",
                width: "54px",
                boxSizing: "border-box",
                borderRadius: "30px",
                background: "white",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                transition: "box-shadow, border, 0.3s ease-in-out", // Smooth transition for shadow
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  boxShadow: "0 5px 10px 0 rgba(0,0,0,0.15)", // Slightly more pronounced shadow on hover
                },
              }}
            >
              {action.icon}
            </IconButton>
          </Tooltip>
          <Typography
            variant="caption"
            display="block"
            sx={{ fontSize: "0.7rem", marginTop: "4px" }}
          >
            {action.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ActionButtonRow;
