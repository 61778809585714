import React from 'react';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';

const LeadDetails = ({ phone, name, crm }) => {
  return (
    <Box>
      <Typography variant="h6">{name}</Typography>
      <Typography variant="body1">Phone: {phone}</Typography>
      <Typography variant="body2">
        Last Contacted: {crm?.lastContactedDate ? DateTime.fromMillis(crm?.lastContactedDate.seconds * 1000).toLocaleString(DateTime.DATE_MED) : 'N/A'}
      </Typography>
      <Typography variant="body2">
        Follow Up: {crm?.followUpDate ? DateTime.fromMillis(crm?.followUpDate.seconds * 1000).toLocaleString(DateTime.DATE_MED) : 'N/A'}
      </Typography>
      <Typography variant="body2">
        Lead Created: {crm?.leadCreated ? DateTime.fromMillis(crm?.leadCreated.seconds * 1000).toLocaleString(DateTime.DATE_MED) : 'N/A'}
      </Typography>
    </Box>
  );
};

export default LeadDetails;
