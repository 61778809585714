import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  Box,
  Skeleton,
  DialogContent,
  Stack,
} from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useMember } from "../providers/MemberProvider";
import {
  fetchCards,
  updateDefaultPaymentMethod,
} from "../services/memberServices";
import { CreditCard } from "@mui/icons-material";
import { MasterCardIcon, VisaIcon } from "./PaymentIcons";
import AddCreditCard from "./AddCreditCard";
import ResponsiveDialog from "./dialogs/ResponsiveDialog";

const ListCreditCards = () => {
  const [loading, setLoading] = useState(true);
  const stripe = useStripe();
  const [cards, setCards] = useState([]);
  const { data } = useMember();
  const stripeId = data?.member?.admin?.data?.stripeId;
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState(null);
  const [updatingCardId, setUpdatingCardId] = useState(null);
  const [openAddCreditCard, setOpenAddCreditCard] = useState(false);

  // Function to fetch cards, this can be called again to refresh the list
  const refreshCards = () => {
    setOpenAddCreditCard(false);
    setLoading(true);
    fetchCards({ customerId: stripeId })
      .then(({ paymentMethods, defaultPaymentMethodId }) => {
        setCards(paymentMethods);
        setDefaultPaymentMethodId(defaultPaymentMethodId);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  // Call refreshCards function on component mount
  useEffect(() => {
    refreshCards();
  }, [stripeId]);

  const handleSetDefaultCard = async (cardId) => {
    // Optimistically update the UI to reflect the new default card
    const previousDefaultPaymentMethodId = defaultPaymentMethodId;
    setDefaultPaymentMethodId(cardId);
    setUpdatingCardId(cardId);

    try {
      // Attempt to update the default payment method in the backend
      await updateDefaultPaymentMethod({
        customerId: stripeId,
        paymentMethodId: cardId,
      });
    } catch (error) {
      console.error("Error setting default card:", error);
      // If the backend call fails, revert the optimistic UI update
      setDefaultPaymentMethodId(previousDefaultPaymentMethodId);
    } finally {
      setUpdatingCardId(null);
    }
  };

  // Function to return an icon based on the card brand
  const getCardIcon = (brand) => {
    console.log("brand: ", brand);
    switch (brand) {
      case "visa":
        return <VisaIcon style={{ fontSize: 40 }} />;

      case "mastercard":
        return <MasterCardIcon style={{ fontSize: 40 }} />;

      // Add other cases for different card brands
      default:
        return <CreditCard sx={{ fontSize: 40 }} />;
    }
  };

  if (loading) {
    return (
      <Card variant="outlined" sx={{ my: 2 }}>
        <CardContent>
          <Grid container alignItems="center" spacing={2}>
            <Grid
              item
              xs={3}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Skeleton
                variant="rounded"
                sx={{ borderRadius: "5px" }}
                width={40}
                height={30}
              />
            </Grid>
            <Grid item xs={3} alignItems="center">
              <Stack direction="column">
                <Skeleton width="70%" />
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "5px", mt: 1 }}
                  width={30}
                  height={10}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rounded" width="100%" height="36.5px" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      {cards.map((card, index) => (
        <Card key={index} variant="outlined" sx={{ my: 2 }}>
          <CardContent>
            <Grid container alignItems="center" spacing={2}>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="center"
              >
                {getCardIcon(card.brand)}
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">•••• {card.last4}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {String(card.exp_month).padStart(2, "0")}/
                  {String(card.exp_year).slice(-2)}
                </Typography>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-start">
                {card.id === defaultPaymentMethodId ? (
                  <Button
                    variant="contained"
                    color="success"
                    disableElevation
                    fullWidth
                    sx={{
                      pointerEvents: "none", // Disables pointer events like hover, click etc.
                      "&:hover": {
                        backgroundColor: "success.main", // Keeps the background color the same even on hover
                      },
                    }}
                  >
                    Default
                    {updatingCardId === card.id && (
                      <CircularProgress
                        size={20}
                        sx={{ color: "white", marginLeft: 1 }}
                      />
                    )}
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => handleSetDefaultCard(card.id)}
                    disabled={updatingCardId !== null}
                  >
                    Set Default
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}

      <Button
        onClick={() => setOpenAddCreditCard(true)}
        variant="outlined"
        disabled={!stripe}
        sx={{
          minWidth: { xs: "100%", sm: "150px" },
          height: { xs: "60px", sm: "auto" },
        }}
      >
        Add Credit Card
      </Button>

      <ResponsiveDialog
        title={"Add Credit Card"}
        open={openAddCreditCard}
        width="500px"
        onClose={() => setOpenAddCreditCard(false)}
      >
        <AddCreditCard onCardAdded={refreshCards} />
      </ResponsiveDialog>
    </>
  );
};

export default ListCreditCards;
