import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import moment from "moment-timezone";
import React from "react";

const TimelineRow = ({ windows, index, start, end, timeZoneId }) => {
  const theme = useTheme();

  console.log("===> windows: ", windows);

  const getComplementaryColor = (index) => {
    const colors = [
      theme.palette.complementary.color1,
      theme.palette.complementary.color2,
      theme.palette.complementary.color3,
      theme.palette.complementary.color4,
      theme.palette.complementary.color5,
      theme.palette.complementary.color6,
    ];

    return colors[index % colors.length];
  };

  const calculateWindowStyle = (windowStart, windowEnd) => {
    if (!windowStart || !windowEnd) return;

    const totalDuration = end.diff(start);

    const windowStartOffset = windowStart.diff(start);
    const windowDuration = windowEnd.diff(windowStart);

    const left = (windowStartOffset / totalDuration) * 100;
    const width = (windowDuration / totalDuration) * 100;

    console.log("left/width", [left, width]);

    return { left: `${left}%`, width: `${width}%` };
  };

  const formatDuration = (windowStart, windowEnd) => {
    const duration = moment.duration(windowEnd.diff(windowStart));
    const hours = duration.hours();
    const minutes = duration.minutes();

    let durationText = "";
    if (hours > 0) {
      durationText += `${hours} hour${hours > 1 ? "s" : ""} `;
    }
    if (minutes > 0) {
      durationText += `${minutes} min`;
    }

    return durationText.trim(); // Trim to remove any extra space at the end
  };

  let backgroundColor;
  if (index >= 0) {
    backgroundColor = getComplementaryColor(index); // Generate random color for each window
  } else {
    backgroundColor = theme.palette.primary.main;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: index >= 0 ? "50px" : "10px",
        position: "relative", // This is for positioning the windows absolutely within the row
        marginY: "10px",
        zIndex: 0, // Ensures it's above the original schedule layer
      }}
    >
      {Object.values(windows).map((window) => {
        const windowStart = moment(window?.start.toDate()).tz(timeZoneId);
        const windowEnd = moment(window?.end.toDate()).tz(timeZoneId);

        console.log("windowStart: ", windowStart);
        if (!windowStart || !windowEnd) return;

        const durationText = formatDuration(windowStart, windowEnd);

        return (
          <Tooltip
            enterTouchDelay={0}
            title={durationText}
            key={windowStart.toString()}
            arrow
            placement="top"
          >
            <Box
              key={windowStart.toString()}
              sx={{
                ...calculateWindowStyle(windowStart, windowEnd),
                backgroundColor, // Replace with actual logic to assign colors
                position: "absolute", // Only the individual window boxes are absolutely positioned
                height: "100%",
                borderRadius: index >= 0 ? "10px" : "3px",
              }}
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};

const OriginalSchedule = ({ start, end, visitStart, visitEnd }) => {
  const totalDuration = end.diff(start);
  const visitStartOffset = visitStart.diff(start);
  const visitEndOffset = visitEnd.diff(start);

  const left = (visitStartOffset / totalDuration) * 100;
  const width = ((visitEndOffset - visitStartOffset) / totalDuration) * 100;

  return (
    <Box
      sx={{
        position: "absolute",
        left: `${left}%`,
        width: `${width}%`,
        top: 0,
        bottom: 0,
        backgroundImage: `
        linear-gradient(45deg, rgba(0,0,0,0.2) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.2) 75%, transparent 75%, transparent)
      `,
        backgroundSize: "10px 10px",
        zIndex: -1, // Ensures it's behind the rows
      }}
    >
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          top: "-25%",
          transform: "translateX(50%)",
          // color: "white",
        }}
      >
        Original Schedule
      </Typography>
    </Box>
  );
};

const TimeMarker = ({ start, end }) => {
  const totalDuration = end.diff(start, "minutes");
  const markerCount = totalDuration / 15; // 15-minute intervals

  // Generate markers
  const markers = Array.from({ length: markerCount + 1 }, (_, i) => {
    const time = start.clone().add(i * 15, "minutes");
    let label = "";
    if (i % 4 === 0) {
      label =
        time.minutes() === 0
          ? time.format("ha").toLowerCase()
          : time.format("h:mma").toLowerCase();
    }
    return {
      position: (i / markerCount) * 100,
      label: label,
    };
  });

  return (
    <Box
      sx={{ position: "absolute", top: 0, left: 0, bottom: 0, width: "100%" }}
    >
      {markers.map((marker, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            left: `${marker.position}%`,
            transform: "translateX(-50%)",
            width: "1px",
            top: index % 4 === 0 ? "0" : index % 2 === 0 ? "2.5px" : "5px", // Adjust top for vertical centering
            height:
              index % 4 === 0
                ? "100%"
                : index % 2 === 0
                ? "calc(100% - 5px)"
                : "calc(100% - 10px)", // Height based on index
            backgroundColor: `rgba(0, 0, 0, ${
              index % 4 === 0 ? 0.4 : index % 2 === 0 ? 0.3 : 0.2
            })`, // Alpha value based on index
            zIndex: "-2",
          }}
        >
          {marker.label && (
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                top: "0", // Changed from '100%' to '0'
                left: "50%",
                transform: "translate(-50%, -100%)", // Adjust to move labels above the line
                whiteSpace: "nowrap",
                color: `rgba(0, 0, 0, 0.6)`,
              }}
            >
              {marker.label}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

const VisitTimelineView = ({ visit }) => {
  // Convert to moment-timezone
  const timeZoneId = visit?.timeZoneId;
  const visitStart = moment(visit?.start.toDate()).tz(timeZoneId);
  const visitEnd = moment(visit?.end.toDate()).tz(timeZoneId);

  // Check if the visit object is valid and has required fields
  if (
    !visit ||
    !visit.start ||
    !visit.end ||
    !visit.timeZoneId ||
    !visit.employees
  ) {
    return <Typography variant="body1">Invalid visit data</Typography>;
  }

  // Function to find the earliest start and latest end times
  const findTimeBounds = () => {
    let earliest = visitStart.clone();
    let latest = visitEnd.clone();

    Object.entries(visit?.employees)
      .filter(
        ([_, employee]) =>
          employee?.responseStatus === "accepted" && employee.windows
      )
      .forEach(([, employee]) => {
        Object.values(employee.windows).forEach((window) => {
          const windowStart = moment(window?.start.toDate()).tz(timeZoneId);
          const windowEnd = moment(window?.end.toDate()).tz(timeZoneId);
          if (windowStart.isBefore(earliest)) {
            earliest = windowStart;
          }
          if (windowEnd.isAfter(latest)) {
            latest = windowEnd;
          }
        });
      });

    // Add buffer
    // earliest.subtract(15, "minutes");
    // latest.add(15, "minutes");

    return { earliest, latest };
  };

  const { earliest: timelineStart, latest: timelineEnd } = findTimeBounds();

  console.log("visitStart/End: ", [visitStart, visitEnd]);
  console.log("timelineStart/End: ", [timelineStart, timelineEnd]);

  // Check if all windows are undefined or match visit times
  const shouldRender = Object.values(visit.employees).some((employee) => {
    if (!employee.windows) return false;

    return Object.values(employee.windows).some((window) => {
      const windowStart = moment(window?.start.toDate()).tz(timeZoneId);
      const windowEnd = moment(window?.end.toDate()).tz(timeZoneId);
      return !windowStart.isSame(visitStart) || !windowEnd.isSame(visitEnd);
    });
  });

  if (!shouldRender) return null;

  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: "0px",
        marginX: "-16px",
        paddingX: "16px",
        marginBottom: "48px",
        marginTop: "32px",
        zIndex: "10",
        // backgroundColor: "#F5F5F5",
      }}
    >
      <Box
        variant="outlined"
        sx={{ margin: "20px", paddingY: "5px", position: "relative" }}
      >
        {/* Main container with styling */}

        {/* Time markers */}
        <TimeMarker start={timelineStart} end={timelineEnd} />

        {/* Rows for each employee */}
        {Object.entries(visit?.employees)
          .filter(([_, employee]) => employee?.responseStatus === "accepted")
          .map(([employeeId, employeeData], index) => {
            // Use existing windows or provide a default window if none exist
            const windows = employeeData.windows || {
              "default-window": { start: visitStart, end: visitEnd },
            };

            return (
              <TimelineRow
                key={employeeId}
                index={index} // Start from 1 for employee rows
                windows={windows}
                start={timelineStart}
                end={timelineEnd}
                timeZoneId={timeZoneId}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default VisitTimelineView;
