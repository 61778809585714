import {
  Box,
  Card,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  ArrowForwardIosRounded,
  Contacts,
  WarningRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useEmployee } from "../../../providers/EmployeeProvider";
import { useSnackbar } from "../../../providers/SnackbarProvider";

import EmployeeVisitCard from "../../../components/EmployeeVisitCard";
import PushDrawer from "../../../components/PushDrawer";
import ContentHeader from "../../ContentHeader";
import VisitsNeedAction from "./VisitsNeedAction";

import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // For green checkmark
import ErrorIcon from "@mui/icons-material/Error"; // For severe symbol
import InfoIcon from "@mui/icons-material/Info";
import AvailabilityToggle from "../../../components/AvailabilityToggle";
import HomeCard from "../../../components/HomeCard";
import HomeCardHeader from "../../../components/HomeCardHeader";
import ResponsiveDialog from "../../../components/dialogs/ResponsiveDialog";
import ColumnView from "../../../components/calendar/ColumnView";

const HomePage = () => {
  const { data, setData, isServiceArea, visitsLoading, currentDayWithVisits } =
    useEmployee();
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();
  const [view, setView] = useState("list"); // This state manages which view is displayed
  const [visits, setVisits] = useState([]);
  const [calendarView, setCalendarView] = useState("week");
  const loadedVisitIds = useRef(new Set());
  const timeZoneId = data?.employee?.timeZoneId;
  const [calendarDate, setCalendarDate] = useState(moment());
  const theme = useTheme();
  const employeeId = data?.auth?.uid;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const status = data?.employee?.status || "incomplete";

  const [stars, setStars] = useState(0);

  const [showNext, setShowNext] = useState();
  const [showReview, setShowReview] = useState();
  const [showAnnouncements, setShowAnnouncements] = useState();
  const [showNeedsAction, setShowNeedsAction] = useState();
  const [showOpenVisits, setShowOpenVisits] = useState();

  const [visitsNeedsAction, setVisitsNeedsAction] = useState({});
  const [visitsNeedsReview, setVisitsNeedsReview] = useState({});
  const [openVisits, setOpenVisits] = useState({});

  // Get visits that need review or action
  useEffect(() => {
    if (data?.visits && Object.keys(data?.visits).length > 0 && timeZoneId) {
      console.log("data?.visits: ", data?.visits);

      const visitsNeedsActionMap = {};
      const visitsNeedsReviewMap = {};

      const now = moment().tz(timeZoneId);
      const threeWeeksFromNow = now.clone().add(3, "weeks");

      Object.entries(data.visits).forEach(([key, visit]) => {
        const visitStartMoment = moment(visit.start.toDate()).tz(
          visit.timeZoneId
        );

        console.log("visit: ", visit);

        console.log("visitStartMoment: ", visitStartMoment);

        const isPastVisit = visitStartMoment.isBefore(now);
        const isFutureVisit = visitStartMoment.isAfter(now);
        const isInNextTwoWeeks = visitStartMoment.isBetween(
          now,
          threeWeeksFromNow
        );

        const accepted =
          visit.employees[employeeId]?.responseStatus === "accepted";
        const needsAction =
          visit.employees[employeeId]?.responseStatus === "needsAction" &&
          isInNextTwoWeeks;
        const needsReview =
          !visit.employees[employeeId]?.rating && accepted && isPastVisit;

        if (needsAction) {
          visitsNeedsActionMap[key] = visit;
        }

        if (needsReview) {
          visitsNeedsReviewMap[key] = visit;
        }
      });

      setVisitsNeedsAction(visitsNeedsActionMap);
      setVisitsNeedsReview(visitsNeedsReviewMap);
    }
  }, [data?.visits, employeeId]);

  // Get non conflicting open visits, including 30 minute buffer
  useEffect(() => {
    if (data?.openVisits && data?.visits) {
      // Convert the scheduled visits to an array of start and end moments with 30-minute padding
      const scheduledVisits = Object.values(data.visits).map((visit) => ({
        start: moment(visit.start.toDate())
          .tz(visit.timeZoneId)
          .subtract(30, "minutes"),
        end: moment(visit.end.toDate()).tz(visit.timeZoneId).add(30, "minutes"),
      }));

      // Get the current time with the specified timezone
      const now = moment().tz(timeZoneId);

      // Filter out open visits that overlap with any scheduled visits (including 30-minute padding)
      const nonOverlappingOpenVisits = Object.entries(data.openVisits).reduce(
        (acc, [key, openVisit]) => {
          const openStart = moment(openVisit.start.toDate()).tz(timeZoneId);
          const openEnd = moment(openVisit.end.toDate()).tz(timeZoneId);

          const isNonOverlapping = !scheduledVisits.some(
            (scheduledVisit) =>
              openStart.isBefore(scheduledVisit.end) &&
              openEnd.isAfter(scheduledVisit.start)
          );

          // Add additional check to ensure the open visit is in the future
          if (isNonOverlapping && openStart.isAfter(now)) {
            acc[key] = openVisit; // Add to accumulator if it doesn't overlap and is in the future
          }

          return acc;
        },
        {}
      );

      setOpenVisits(nonOverlappingOpenVisits);
    }
  }, [data?.openVisits, data?.visits, timeZoneId]);

  const StyledCard = styled(Card)(({ theme }) => ({
    transition: theme.transitions.create(
      ["background-color", "box-shadow", "transform"],
      {
        duration: theme.transitions.duration.short,
      }
    ),
    borderRadius: "15px",
    WebkitTapHighlightColor: "transparent", // for Webkit browsers
    backgroundColor: theme.palette.background.paper,
    "&:hover, &:focus, &:active": {
      backgroundColor: theme.palette.action.hover, // Correct reference to MUI's hover color from the theme

      // Remove boxShadow if you don't want any shadow on click
      boxShadow: "none",
    },
    "&:focus": {
      outline: "none", // to remove default focus outline
    },
  }));

  function getIconSeverity(count) {
    switch (true) {
      case count === 0:
        return (
          <CheckCircleIcon
            fontSize="large"
            color={getColorSeverity(count)}
            sx={{ mx: 1 }}
          />
        );
      case count === 1:
        return (
          <InfoIcon
            fontSize="large"
            color={getColorSeverity(count)}
            sx={{ mx: 1 }}
          />
        );
      case count >= 2 && count <= 3:
        return (
          <WarningRounded
            fontSize="large"
            color={getColorSeverity(count)}
            sx={{ mx: 1 }}
          />
        );
      case count > 3:
        return (
          <ErrorIcon
            fontSize="large"
            color={getColorSeverity(count)}
            sx={{ mx: 1 }}
          />
        );
      default:
        return null;
    }
  }

  function getColorSeverity(count) {
    if (count === 0) {
      return "success"; // green for no actions needed
    } else if (count === 1) {
      return "info"; // blue for informational (1 action needed)
    } else if (count >= 2 && count <= 3) {
      return "warning"; // yellow for warning (2-3 actions needed)
    } else if (count > 3) {
      return "error"; // red for severe (more than 3 actions needed)
    } else {
      return "default"; // default color for any other case
    }
  }

  const totalVisitsNeedsAction = Object.keys(visitsNeedsAction).length;
  const totalVisitsNeedsReview = Object.keys(visitsNeedsReview).length;
  const totalOpenVisits = Object.keys(openVisits).length;

  console.log(
    "currentDayWithVisits: ",
    currentDayWithVisits?.format("MM-DD hh:mma")
  );

  // Assuming 'visits' is your map of unsorted visit objects
  const nowUnix = moment().unix(); // Current time in Unix timestamp

  // Convert visits to an array with IDs and sort by start time
  const sortedVisitsWithId = Object.entries(visits)
    .map(([id, visit]) => ({
      ...visit,
      id,
    }))
    .sort((a, b) => a.start.seconds - b.start.seconds);

  // Find the first visit that starts after the current time, now including the ID
  const nextVisit = sortedVisitsWithId.find(
    (visit) => visit.start.seconds > nowUnix
  );

  const renderActiveHome = () => {
    return (
      <>
        <>
          <ResponsiveDialog
            open={showNext}
            onClose={() => {
              setShowNext(false);
            }}
            title={"Upcoming visit"}
            width="600px"
            fullHeight={true}
          >
            {nextVisit && (
              <EmployeeVisitCard
                key={nextVisit?.id}
                visit={nextVisit}
                visitId={nextVisit?.id}
                type={"visit"}
                // handleAccept={handleAcceptVisit}
                handleDecline={() => setShowNext(false)}
                // handlePickup={handlePickupVisit}
                // loading={loading}
              />
            )}
          </ResponsiveDialog>
          <PushDrawer
            title={"Feedback"}
            width={"400px"}
            open={showReview}
            onOpen={() => setShowReview(true)}
            onClose={() => {
              setShowReview(false);
            }}
            hideBackdrop={false}
          >
            <VisitsNeedAction
              visitsProp={visitsNeedsReview}
              type={"review"}
              noResultsMessage={"There are no visits that need feedback."}
            />
          </PushDrawer>

          <PushDrawer
            title={"Needs Action"}
            width={"400px"}
            open={showNeedsAction}
            onOpen={() => setShowNeedsAction(true)}
            onClose={() => {
              setShowNeedsAction(false);
            }}
            hideBackdrop={false}
          >
            {
              <VisitsNeedAction
                visitsProp={visitsNeedsAction}
                type={"visit"}
                noResultsMessage={"There are no visits that need action."}
              />
            }
          </PushDrawer>
          <PushDrawer
            title={"Pickup visits"}
            width={"400px"}
            open={showOpenVisits}
            onOpen={() => setShowOpenVisits(true)}
            onClose={() => {
              setShowOpenVisits(false);
            }}
            hideBackdrop={false}
          >
            <VisitsNeedAction
              visitsProp={openVisits}
              type={"openVisit"}
              noResultsMessage={"There are no open visits to pick-up."}
            />
          </PushDrawer>
        </>
        <ContentHeader
          title={`Welcome ${data?.employee?.firstName}`}
        ></ContentHeader>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            {currentDayWithVisits && (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: "15px",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    overflow: "hidden",
                    minHeight: "181.914px",
                  }}
                >
                  <ColumnView
                    numberOfDays={3}
                    startDay={currentDayWithVisits.toDate()}
                    visits={data?.visits}
                    employeeId={data?.auth?.uid}
                    timeZoneId={timeZoneId}
                    truncateView={true}
                    height={isMobile ? "120px" : "500px"}
                    // availability={data?.employee?.public?.availability?.windows}
                    hideAvailability={true}
                  />
                </Box>
              </Grid>
            )}

            {/* Needs Accepting */}
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Stack gap={1}>
                <HomeCard onClick={() => setShowNeedsAction(true)}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        fontSize="22px"
                        fontWeight={"600"}
                        sx={{
                          display: "flex",
                          alignItems: "center", // Aligns the icon and text vertically
                        }}
                      >
                        {getIconSeverity(totalVisitsNeedsAction)}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontWeight="700"
                        component="div"
                        sx={{
                          color:
                            theme.palette[
                              getColorSeverity(totalVisitsNeedsAction)
                            ].main,
                          // color: theme.palette.primary.main,
                          px: 1, // This applies the padding on the X-axis (left and right)
                          minWidth: "90px",
                        }}
                      >
                        Accept
                        {totalVisitsNeedsAction > 0 &&
                          ` - ${totalVisitsNeedsAction}`}
                      </Typography>
                    </Box>
                    {totalVisitsNeedsAction > 0 && (
                      <IconButton
                        onClick={() => setShowNeedsAction(true)}
                        sx={{ py: 0 }}
                      >
                        <ArrowForwardIosRounded
                          sx={{
                            color:
                              theme.palette[
                                getColorSeverity(totalVisitsNeedsAction)
                              ].main,
                          }}
                        />
                      </IconButton>
                    )}
                  </Box>
                  {/* {totalVisitsNeedsAction > 0 && (
                <Typography paddingBottom={1} paddingX={2}>
                  You have {totalVisitsNeedsAction}{" "}
                  {totalVisitsNeedsAction === 1
                    ? "visit that needs"
                    : "visits that need"}{" "}
                  action.
                </Typography>
              )} */}
                </HomeCard>

                {/* Needs Review */}
                <HomeCard onClick={() => setShowReview(true)}>
                  <Box
                    onClick={() => setShowReview(true)}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        fontSize="32px"
                        fontWeight={"600"}
                        sx={{
                          display: "flex",
                          alignItems: "center", // Aligns the icon and text vertically
                        }}
                      >
                        {getIconSeverity(totalVisitsNeedsReview)}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontWeight="700"
                        component="div"
                        sx={{
                          color:
                            theme.palette[
                              getColorSeverity(totalVisitsNeedsReview)
                            ].main,
                          // color: theme.palette.primary.main,
                          px: 1, // This applies the padding on the X-axis (left and right)
                          minWidth: "90px",
                        }}
                      >
                        Feedback
                        {totalVisitsNeedsReview > 0 &&
                          ` - ${totalVisitsNeedsReview}`}
                      </Typography>
                    </Box>

                    {totalVisitsNeedsReview > 0 && (
                      <IconButton sx={{ py: 0 }}>
                        <ArrowForwardIosRounded
                          sx={{
                            color:
                              theme.palette[
                                getColorSeverity(totalVisitsNeedsReview)
                              ].main,
                          }}
                        />
                      </IconButton>
                    )}
                  </Box>
                  {/* {totalVisitsNeedsReview > 0 && (
                <Typography paddingBottom={1} paddingX={2}>
                  You have {totalVisitsNeedsReview}{" "}
                  {totalVisitsNeedsReview === 1
                    ? "visit that needs"
                    : "visits that need"}{" "}
                  feedback.
                </Typography>
              )} */}
                </HomeCard>

                {/* Pickup visits */}
                <HomeCard onClick={() => setShowOpenVisits(true)}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        fontSize="32px"
                        fontWeight={"600"}
                        sx={{
                          display: "flex",
                          alignItems: "center", // Aligns the icon and text vertically
                        }}
                      >
                        {totalOpenVisits === 0 ? (
                          <CheckCircleIcon
                            fontSize="large"
                            color="success"
                            sx={{ mx: 1 }}
                          />
                        ) : (
                          <InfoIcon
                            fontSize="large"
                            color="info"
                            sx={{ mx: 1 }}
                          />
                        )}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontWeight="700"
                        component="div"
                        sx={{
                          color:
                            theme.palette[
                              totalOpenVisits === 0 ? "success" : "info"
                            ].main,
                          // color: theme.palette.primary.main,
                          px: 1, // This applies the padding on the X-axis (left and right)
                          minWidth: "90px",
                        }}
                      >
                        Pickup
                        {totalOpenVisits > 0 && ` - ${totalOpenVisits}`}
                      </Typography>
                    </Box>
                    {totalOpenVisits > 0 && (
                      <IconButton
                        onClick={() => setShowOpenVisits(true)}
                        sx={{ py: 0 }}
                      >
                        <ArrowForwardIosRounded
                          sx={{
                            color:
                              theme.palette[
                                totalOpenVisits === 0 ? "success" : "info"
                              ].main,
                          }}
                        />
                      </IconButton>
                    )}
                  </Box>

                  {/* {totalOpenVisits > 0 && (
                <Typography paddingBottom={1} paddingX={2}>
                  There {totalOpenVisits === 1 ? "is" : "are"} {totalOpenVisits}{" "}
                  open visit{totalOpenVisits === 1 ? "" : "s"} available.
                </Typography>
              )} */}
                </HomeCard>

                {/* Next visit 
                <HomeCard onClick={nextVisit ? () => setShowNext(true) : null}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="700"
                      component="div"
                      sx={{
                        color: nextVisit
                          ? theme.palette.primary.main
                          : theme.palette.grayscale.dark,
                        px: 1, // This applies the padding on the X-axis (left and right)
                      }}
                    >
                      {nextVisit ? "Your next visit" : "Your schedule is empty"}
                    </Typography>
                    {nextVisit && (
                      <IconButton onClick={() => setShowNext(true)}>
                        <ArrowForwardIosRounded color="primary" />
                      </IconButton>
                    )}
                  </Box>
                  {nextVisit ? (
                    <VisitListItem
                      visit={nextVisit}
                      ref={null}
                      showChips={false}
                    />
                  ) : (
                    <Typography paddingBottom={1} paddingX={2}>
                      Share www.ourlinkedlives.com to friends, family, and
                      neighbors to encourage others to sign up and schedule with
                      you.
                    </Typography>
                  )}
                </HomeCard>
                */}

                {/* Available today */}
                <AvailabilityToggle />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  const renderIncompleteHome = () => {
    return (
      <>
        <ContentHeader title={"Welcome"}></ContentHeader>
        <HomeCard onClick={() => navigate("/employee/create-account")}>
          <HomeCardHeader
            title={"Personal Information"}
            IconComponent={Contacts}
          />
          <Typography sx={{ mt: 1 }}>
            Your profile is incomplete. Add your information to get started.
          </Typography>
        </HomeCard>
      </>
    );
  };

  return (
    <>
      {(status === "active" || status === "applicant") && renderActiveHome()}
      {status === "incomplete" && renderIncompleteHome()}
    </>
  );
};

export default HomePage;
