import {
  Box,
  Button,
  Divider,
  ListItemAvatar,
  ListItemText,
  Rating,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { db } from "../../firebase";
import AvatarStatusBadge from "../AvatarStatusBadge";
import ColorAvatar from "../ColorAvatar";

const VisitReviewList = ({
  START_LIMIT = 10,
  employeeId = undefined,
  STAR_MIN = 5,
  spacing = "normal",
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visitsData, setVisitsData] = useState([]);

  const listRef = useRef(null);

  const FETCH_LIMIT = 5;

  // Generate the array of ratings based on STAR_MIN (e.g., if STAR_MIN is 3, generate [3, 4, 5])
  const ratingArray = Array.from(
    { length: 6 - STAR_MIN },
    (_, i) => i + STAR_MIN
  );

  useEffect(() => {
    const visitQuery = [
      collection(db, "visits"),
      // where("rating", "in", ratingArray),
      where("rating", "==", 5),
      orderBy("start", "desc"),
      limit(START_LIMIT),
    ];

    // If employeeId is defined, add an array-contains query for employeeIds
    if (employeeId) {
      visitQuery.push(where("employeeArr", "array-contains", employeeId));
    }

    const q = query(...visitQuery);

    const fetchData = async () => {
      const snapshot = await getDocs(q);
      const fetchedReviews = [];
      snapshot.forEach((doc) => {
        fetchedReviews.push({ id: doc.id, ...doc.data() });
      });
      setVisitsData(fetchedReviews);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]); // Set the last visible document
      setLoading(false);
    };

    fetchData();
  }, [employeeId]);

  const fetchMore = () => {
    return new Promise((resolve, reject) => {
      if (!lastVisible || !hasMore) {
        resolve([]); // Resolve with an empty array to indicate no more items were fetched
        return;
      }

      const nextQuery = [
        collection(db, "visits"),
        // where("rating", "in", ratingArray),
        where("rating", "==", 5),
        orderBy("start", "desc"),
        startAfter(lastVisible),
        limit(FETCH_LIMIT),
      ];

      // If employeeId is defined, add an array-contains query for employeeIds
      if (employeeId) {
        nextQuery.push(where("employeeArr", "array-contains", employeeId));
      }

      const q = query(...nextQuery);

      getDocs(q)
        .then((snapshot) => {
          const newReviews = [];
          snapshot.forEach((doc) => {
            newReviews.push({ id: doc.id, ...doc.data() });
          });

          if (newReviews.length > 0) {
            setVisitsData((prev) => [...prev, ...newReviews]);
            setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          }

          if (newReviews.length < FETCH_LIMIT) {
            setHasMore(false);
          }

          resolve(newReviews); // Resolve the promise with the new reviews
        })
        .catch((error) => {
          console.error("Error fetching more reviews:", error);
          reject(error); // Reject the promise if an error occurs
        });
    });
  };

  // const handleScroll = useCallback(() => {
  //   const { scrollTop, scrollHeight, clientHeight } = listRef.current;

  //   if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !isLoading) {
  //     setIsLoading(true); // Set loading to true before fetching

  //     // Fetch more reviews if not already loading and if more items are available
  //     fetchMore().then(() => {
  //       setIsLoading(false); // Reset loading state once fetching is complete
  //     });
  //   }
  // }, [hasMore, fetchMore, isLoading]);

  function formatTimeDifference(startTimestamp, endTimestamp) {
    const startTime = moment(startTimestamp.toDate());
    const endTime = moment(endTimestamp.toDate());

    const duration = moment.duration(endTime.diff(startTime));

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    let result = "";

    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? "s" : ""}`;
      if (minutes > 0) {
        result += ` ${minutes} minute${minutes > 1 ? "s" : ""}`;
      }
    } else {
      result = `${minutes} minute${minutes > 1 ? "s" : ""}`;
    }

    return result;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          maxWidth: "600px",
          mx: { xs: 0, sm: "auto" },
        }}
      >
        {visitsData.length > 0 &&
          visitsData
            .sort((a, b) => b.start.seconds - a.start.seconds)
            .map((visit, index) => (
              <Box key={visit.id}>
                <Box sx={{ my: spacing === "large" ? "300px" : 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      mb: 1,
                    }}
                  >
                    <ColorAvatar
                      avatarUrl={visit.avatarUrl}
                      name={visit.displayName}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography variant="subtitle1" fontWeight="600">
                        {visit.displayName}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 0.6,
                        }}
                      >
                        {/* <Typography variant="body2">
                        {visit.location.city},{" "}
                        {convertStateNameToAbbreviation(visit.location.state)}
                      </Typography> */}
                        <Typography variant="body2" color="text.secondary">
                          {/* •{" "} */}
                          {moment(new Date(visit.start.seconds * 1000)).format(
                            "MMM D, YYYY"
                          )}{" "}
                          • {formatTimeDifference(visit.start, visit.end)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      my: 1,
                    }}
                  >
                    {visit.description}
                  </Typography>

                  {visit.rating && (
                    <Box
                      sx={{
                        backgroundColor: "#F5F5F5",
                        p: 1,
                        borderRadius: "15px",
                        my: 1,
                      }}
                    >
                      <Rating
                        size="small"
                        value={visit.rating}
                        precision={0.1}
                        readOnly
                      />
                      <Typography variant="body1">{visit.review}</Typography>
                    </Box>
                  )}

                  <Box
                    sx={{
                      ml: "auto",
                      display: "flex",
                      flexDirection: "row",
                      gap: "12px",
                      justifyContent: "flex-end",
                    }}
                  >
                    {Object.entries(visit.employees)
                      .filter(
                        ([, employee]) => employee.responseStatus === "accepted"
                      )
                      .map(([key, employee], index) => {
                        return (
                          <Box display="flex" justifyContent="flex-end">
                            <Stack
                              key={key}
                              direction="row"
                              gap={1}
                              alignItems="center"
                            >
                              <ListItemAvatar>
                                <AvatarStatusBadge
                                  status={employee.responseStatus}
                                  avatarUrl={
                                    visit.employees[key]?.avatarUrl ||
                                    visit?.employees[key]?.avatarUrl
                                  }
                                  displayName={visit?.employees[key]?.firstName}
                                  index={index}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={`${visit?.employees[key]?.displayName}`}
                                secondary="completed"
                              />
                            </Stack>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
                {index < visitsData.length - 1 && <Divider sx={{ my: 3 }} />}
              </Box>
            ))}
        {hasMore && (
          <Button
            variant="contained"
            size="large"
            color="success"
            onClick={() => fetchMore()}
            sx={{
              height: "60px",
              width: { xs: "100%", sm: "250px" },
              mx: "auto",
              textTransform: "none",
              my: 3,
            }}
          >
            Load more reviews
          </Button>
        )}
        {/* Skeleton loaders displayed when isLoading is true */}
        {isLoading &&
          Array.from(new Array(3)).map((_, index) => (
            <React.Fragment key={index}>
              <Divider variant="fullWidth" component="li" />
              <Box
                key={index}
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "row",
                  boxSizing: "border-box",
                  gap: 2,
                }}
              >
                <Skeleton variant="circular" width={56} height={56} />
                <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                  <Skeleton variant="text" width="90%" />
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="30%" />
                </Box>
              </Box>
            </React.Fragment>
          ))}
      </Box>
    </>
  );
};

export default VisitReviewList;
