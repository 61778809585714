import { Email, Share, Textsms } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useMember } from "../providers/MemberProvider";
import { useSnackbar } from "../providers/SnackbarProvider";
import CopyUrlButton from "./buttons/CopyUrlButton";

const ShareFriends = () => {
  const { data } = useMember();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Your referral message or link
  const referralLink = `https://www.ourlinkedlives.com/member/signup?referral=${data.auth.uid}`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    showSnackbar("Referral link copied to clipboard", "success");
  };

  return (
    <DialogContent>
      <Stack
        spacing={2}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          component="img"
          sx={{
            objectFit: "contain",
            height: 200,
            // display: "cover",
            maxWidth: 400,
            overflow: "hidden",
            width: "100%",
          }}
          src={
            "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/public%2Fgroup%20happy%20seniors.png?alt=media&token=edcc32e2-3eaf-4a55-a793-7f686c5b389e&_gl=1*d18vy3*_ga*NDk2MDkwNzkuMTY4MDYzODAzMg..*_ga_CW55HF8NVT*MTY5ODc0MjA3OS44MS4xLjE2OTg3NDIwODMuNTYuMC4w"
          }
          alt={"Alt"}
        />
        <Box sx={{display: "flex", flexDirection: "column", gap: 2,}}>
          {/* <Typography variant="h6">
            Gift (and get) a free hour
          </Typography> */}
          <Typography variant="body1" fontSize={18}>
            {/* Share the joy! Encourage a friend to give our services a try and you'll both receive a free hour off your next visit. */}
            Share the joy! If you have a friend in mind that would love our
            service, we would love to connect. We've created a personal sharing
            link for you to use.
          </Typography>
          <CopyUrlButton
            url={referralLink}
            extraText="your unique referral link"
          />
          <Typography variant="body1">
            You can also share over email, text, or social media! 
            {/* They can also call us directly, just make sure they mention your name. */}
          </Typography>
        </Box>
        <Stack
          spacing={1}
          direction={isMobile ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Button
            fullWidth
            variant="outlined"
            startIcon={<Email />}
            onClick={() => {
              window.location.href = `mailto:?subject=Try Linked Lives for Household Help&body=${encodeURIComponent(
                "I've been using this excellent service called Linked Lives to help with all kinds of tasks. They're specifically tailored to seniors and our needs. You can get started or learn more here: " +
                  referralLink
              )}`;
            }}
          >
            Email
          </Button>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<Textsms />}
            onClick={() => {
              window.location.href = `sms:?&body=${encodeURIComponent(
                "I've been using this excellent service called Linked Lives to help with all kinds of tasks. They're specifically tailored to seniors and our needs. You can get started or learn more here: " +
                  referralLink
              )}`;
            }}
          >
            Text
          </Button>
          {/* <Button
            fullWidth
            variant="outlined"
            startIcon={<ContentCopy />}
            onClick={handleCopyLink}
          >
            Copy
          </Button> */}
          <Button
            fullWidth
            variant="outlined"
            startIcon={<Share />}
            onClick={() => {
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  referralLink
                )}`,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            Share
          </Button>
        </Stack>
        <Typography variant="body1" mb={2}>
          Thanks for your help spreading the word!
        </Typography>

        {/* <Typography variant="caption">Unlimited uses</Typography> */}
      </Stack>
    </DialogContent>
  );
};

export default ShareFriends;
