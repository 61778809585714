import React from "react";

import FAQSection from "../../../pages/public/sections/FAQSection";
import ContentHeader from "../../ContentHeader";

const FAQTab = () => {
  return (
    <>
      <ContentHeader title={"FAQ"} />
      <FAQSection />
    </>
  );
};

export default FAQTab;
