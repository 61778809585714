import {
  Box,
  Card,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  useMediaQuery,
  Typography,
  Avatar,
  Skeleton,
} from "@mui/material";
import {
  List,
  ViewDayRounded,
  ViewWeekRounded,
  CalendarViewWeek,
  CalendarViewMonth,
  CalendarMonthRounded,
  CalendarTodayRounded,
} from "@mui/icons-material";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useMember } from "../../../providers/MemberProvider";
import { useSnackbar } from "../../../providers/SnackbarProvider";

import WaitlistCard from "../../../components/WaitlistCard";
import ContentHeader from "../../ContentHeader";
import MemberCalendar from "../MemberCalendar";
import VisitList from "../../../components/VisitList";
import VisitListItem from "../../../components/VisitListItem";
import alexAvatar from "../../../assets/alex-avatar.png";

const HomePage = () => {
  const { data, setData, isServiceArea, visitsLoading } = useMember();
  const { showSnackbar } = useSnackbar();
  const [view, setView] = useState("list"); // This state manages which view is displayed
  const [visits, setVisits] = useState([]);
  const [calendarView, setCalendarView] = useState("week");
  const loadedVisitIds = useRef(new Set());
  const timeZoneId = data.member.timeZoneId;
  const [calendarDate, setCalendarDate] = useState(moment());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleViewToggle = (event, newView) => {
    if (newView !== null) {
      setView(newView); // set main view based on button

      // Depending on what button was pressed, set the calendar view appropriately
      if (newView === "week" || newView === "month") {
        setCalendarView(newView); // This changes the calendar's internal view
      }
    }
  };

  // load VISITS for MEMBER
  useEffect(() => {
    if (!timeZoneId) return;
    if (!data?.visits || Object.keys(data.visits).length == 0) {
      console.log("No visits loaded");
      loadedVisitIds.current.clear();
      setVisits([]);
      return;
    }

    // const currentVisitIds = new Set(Object.keys(data.visits));
    const currentVisitIds = new Set(
      Object.keys(data.visits).filter(
        (id) => data.visits[id].status === "confirmed"
      )
    );

    const idsToAdd = [...currentVisitIds].filter(
      (id) => !loadedVisitIds.current.has(id)
    );
    console.log("VISITS idsToAdd: ", idsToAdd);

    const idsToRemove = new Set(
      [...loadedVisitIds.current].filter((id) => !currentVisitIds.has(id))
    );

    console.log("VISITS idsToRemove: ", idsToRemove);

    // Update the ref with the current set of IDs
    loadedVisitIds.current = currentVisitIds;

    const newVisits = idsToAdd
      .filter((id) => data.visits[id].status === "confirmed")
      .map((id) => {
        const visit = data.visits[id];
        return {
          id: id,
          start: moment(visit.start?.toDate()).tz(timeZoneId).toDate(),
          end: moment(visit.end?.toDate()).tz(timeZoneId).toDate(),
          type: "visit",
          title: "Visit",
          employees: visit.employees,
        };
      });

    setVisits(
      (prevVisits) =>
        // Iterate over the previous visits and construct a new array
        prevVisits.reduce(
          (acc, visit) => {
            // If the visit isn't in the removal set, keep it
            if (!idsToRemove.has(visit.id)) {
              acc.push(visit);
            }
            return acc;
          },
          [...newVisits]
        ) // Start with the new visits
    );
  }, [data?.visits]);

  const renderWelcome = () => {
    return (
      <>
        <Card
          variant="outlined"
          sx={{
            p: 2,
            mb: 1,
            width: "100%",
            minHeight: "400px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ maxWidth: "600px" }}>
            <Typography variant="h6" gutterBottom>
              {Object.keys(data?.visits).length === 0 && isServiceArea === true
                ? "Book your First Visit"
                : "Visits On-Demand"}
            </Typography>
            {data?.member?.location === undefined && (
              <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                This is where you'll find all your scheduled visits, past and
                future. See details of upcoming visits, including date, time,
                and who will be assisting you. Cancel, reschedule, or leave
                feedback at your leisure! To get started and schedule your first
                visit, please return to the homepage and enter your address.
              </Typography>
            )}
            {isServiceArea === false && (
              <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                This section is where you'll typically see all your scheduled
                visits, both past and future, including detailed information
                about upcoming appointments such as date, time, and the assigned
                helper. However, we're still growing and currently do not offer
                service in your area. We're actively working to expand our
                reach, so please keep an eye on us!
              </Typography>
            )}
            {Object.keys(data?.visits).length === 0 &&
              isServiceArea === true && (
                <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                  To book a visit, click the blue button in the lower right. You
                  will then be able to view all your scheduled visits, past and
                  future. See details of upcoming visits, including date, time,
                  and who will be assisting you. Cancel, reschedule, or leave
                  feedback at your leisure!
                </Typography>
              )}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src={alexAvatar}
                alt="Alex Rodriguez"
                sx={{ width: 64, height: 64 }}
              />
              <Box sx={{ ml: 2 }}>
                <Typography variant="body1">Alex Rodriguez</Typography>
                <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                  Owner, Linked Lives
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </>
    );
  };

  return (
    <>
      <ContentHeader title={"Visits"}>
        <ToggleButtonGroup
          value={view}
          exclusive
          color="primary"
          onChange={handleViewToggle}
          aria-label="View type"
          sx={{ bgcolor: "white" }}
        >
          <ToggleButton value="list" aria-label="list view">
            {isMobile ? <ViewDayRounded /> : "List"}
          </ToggleButton>
          <ToggleButton value="week" aria-label="week view">
            {/* changed value to "week" */}
            {isMobile ? <ViewWeekRounded /> : "Week"}
          </ToggleButton>
          <ToggleButton value="month" aria-label="month view">
            {/* changed value to "month" */}
            {isMobile ? <CalendarTodayRounded /> : "Month"}
          </ToggleButton>
        </ToggleButtonGroup>
      </ContentHeader>
      {(data?.member?.location === undefined ||
        isServiceArea === false ||
        Object.keys(data?.visits).length === 0) &&
        renderWelcome()}
      <>
        {/* Conditional rendering of components based on view */}
        {view === "list" && (
          <VisitList
            visits={data?.visits || {}}
            localEmployees={data?.employees || {}}
          />
        )}

        {(view === "week" || view === "month") && (
          <Box
            sx={{
              m: -2,
              borderRadius: { xs: 0, sm: "15px", overflow: "hidden" },
            }}
          >
            <MemberCalendar
              visits={visits}
              setVisits={setVisits}
              timeZoneId={timeZoneId}
              view={calendarView}
              setView={setCalendarView}
              calendarDate={calendarDate}
              setCalendarDate={setCalendarDate}
            />
          </Box>
        )}
        {/* Replace with your actual calendar component */}
      </>
    </>
  );
};

export default HomePage;
