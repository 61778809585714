import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  CircularProgress,
  TextField,
  Button,
  DialogContent,
  DialogActions,
  ListItemButton,
  Avatar,
  ListItemText,
  IconButton,
} from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useMember } from "../providers/MemberProvider";
import { Close } from "@mui/icons-material";
import MemberSearchBar from "./MemberTab/MemberSearchBar";
import EmployeeSearchBar from "./manageEmployees/EmployeeSearchBar";
import { applyReferralDiscount } from "../services/memberServices";

const AdSources = ({
  memberDisplayName = "",
  memberId = "",
  handleSubmit = () => {},
}) => {
  const { data } = useMember();
  const [values, setValues] = useState({
    adSources: [],
    additionalNotes: "",
  });
  const [memberReferral, setMemberReferral] = useState({
    referralUid: "",
    referralName: "",
    referralAvatar: "",
  });
  const [employeeReferral, setEmployeeReferral] = useState({
    referralUid: "",
    referralName: "",
    referralAvatar: "",
  });
  const [adSources, setAdSources] = useState([]);
  const [loading, setLoading] = useState(false);
  const zipCode = data?.member?.location?.zipCode;
  const userId = data?.auth?.uid;

  useEffect(() => {
    const fetchAdSources = async () => {
      try {
        const docRef = doc(db, "public", "adSources");
        const docSnap = await getDoc(docRef);
        let adSources = [
          "Friend",
          "Employee",
          "Newspaper",
          "Facebook",
          "Google Ads",
          "Google Search",
        ]; // Static values you want to include
        if (docSnap.exists()) {
          const matchedAdSources = docSnap.data()[zipCode] || [];
          adSources = Array.from(new Set([...adSources, ...matchedAdSources]));
        }
        setAdSources(adSources);
      } catch (error) {
        console.error("Error fetching ad sources:", error);
      }
    };

    fetchAdSources();
  }, [zipCode]);

  const handleAdSourceChange = (event) => {
    const value = event.target.name;
    const checked = event.target.checked;

    setValues((prevValues) => {
      const newAdSources = checked
        ? [...prevValues.adSources, value]
        : prevValues.adSources.filter((item) => item !== value);

      return { ...prevValues, adSources: newAdSources };
    });
  };

  const handleMemberReferralChange = (member) => {
    setMemberReferral({
      referralUid: member.id,
      referralName: `${member.firstName} ${member.lastName}`,
      referralAvatar: member.avatarUrl,
    });
  };

  const handleEmployeeReferralChange = (employee) => {
    setEmployeeReferral({
      referralUid: employee.id,
      referralName: `${employee.firstName} ${employee.lastName}`,
      referralAvatar: employee.avatarUrl,
    });
  };

  const handleReferralClear = (type) => {
    if (type === "member") {
      setMemberReferral({
        referralUid: "",
        referralName: "",
        referralAvatar: "",
      });
    } else if (type === "employee") {
      setEmployeeReferral({
        referralUid: "",
        referralName: "",
        referralAvatar: "",
      });
    }
  };

  const renderReferral = (referral, type) => {
    console.log("referral, type: ", [referral, type]);
    return (
      <ListItemButton
        key={referral.referralUid}
        sx={{
          borderRadius: "15px",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          boxSizing: "border-box",
        }}
      >
        <Avatar sx={{ marginRight: 2 }} src={referral.referralAvatar}></Avatar>
        <ListItemText primary={referral.referralName} />
        {/* <Chip label={member.status} /> */}
        <IconButton
          onClick={() => handleReferralClear(type)}
          sx={{ marginRight: 2 }}
          onMouseUp={(event) => event.stopPropagation()}
        >
          <Close />
        </IconButton>
      </ListItemButton>
    );
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      // Assuming 'members > private > data' is the correct path in Firestore
      const docRef = doc(db, "members", userId, "private", "data");
      await setDoc(
        docRef,
        {
          adSources: values.adSources,
          additionalNotes: values.additionalNotes,
        },
        { merge: true }
      );

      applyReferralDiscount({
        memberReferralUid: memberReferral.referralUid,
        memberReferralName: memberReferral.referralName,
        employeeReferralUid: employeeReferral.referralUid,
        employeeReferralName: employeeReferral.referralName,
        userId: memberId,
        userName: memberDisplayName,
      });

      handleSubmit();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    setLoading(false);
  };

  return (
    <>
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          How did you hear about us?
        </Typography>
        {adSources.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid container>
            {adSources.map((source) => (
              <Grid item xs={6} key={source}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.adSources.includes(source)}
                      onChange={handleAdSourceChange}
                      name={source}
                      sx={{ p: 0.5, pl: 2 }}
                    />
                  }
                  label={source}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {values.adSources.includes("Friend") && (
          <>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Which friend referred you?
            </Typography>
            {!memberReferral.referralUid ? (
              <MemberSearchBar onResultSelect={handleMemberReferralChange} />
            ) : (
              renderReferral(memberReferral, "member")
            )}
          </>
        )}
        {values.adSources.includes("Employee") && (
          <>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Which employee referred you?
            </Typography>
            {!employeeReferral.referralUid ? (
              <EmployeeSearchBar
                onEmployeeSelect={handleEmployeeReferralChange}
              />
            ) : (
              renderReferral(employeeReferral, "employee")
            )}
          </>
        )}
        <TextField
          fullWidth
          label="Additional Notes"
          variant="standard"
          name="additionalNotes"
          value={values.additionalNotes}
          onChange={(event) =>
            setValues({ ...values, additionalNotes: event.target.value })
          }
          multiline
          minRows={1}
          style={{ marginTop: "20px" }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={loading}
          sx={{
            minWidth: { xs: "100%", sm: "100%" },
            height: { xs: "60px", sm: "56px" },
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

export default AdSources;
