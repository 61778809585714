import {
  AccessTime,
  Call,
  DirectionsOutlined,
  Edit,
  EventRepeatOutlined,
  EventRounded,
  FileCopyOutlined,
  GroupOutlined,
  LocationOnOutlined,
  Notes,
  Person,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { useEmployee } from "../providers/EmployeeProvider";
import { useSnackbar } from "../providers/SnackbarProvider";
import AcceptVisitButton from "./AcceptVisitButton";
import AdjustTimeWindow from "./AdjustTimeWindow";
import AvatarStatusBadge from "./AvatarStatusBadge";
import ColorAvatar from "./ColorAvatar";
import DeclineVisitButton from "./DeclineVisitButton";
import ExpirationChip from "./ExpirationChip";
import PickupVisitButton from "./PickupVisitButton";
import PushDrawer from "./PushDrawer";
import ReviewVisitEmployee from "./ReviewVisitEmployee";
import VisitTimelineView from "./VisitTimelineView";
import ResponsiveDialog from "./dialogs/ResponsiveDialog";

const FETCH_TIMEOUT = 3 * 1000; // 3 seconds, adjust as needed
const MAX_RETRY_ATTEMPTS = 3; // Maximum number of retry attempts

const EmployeeVisitCard = ({
  visit,
  visitId,
  type,
  handleDecline = () => {},
  handleAccept = () => {},
  handlePickup = () => {},
}) => {
  const { data } = useEmployee();
  const { showSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [openEditTime, setOpenEditTime] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [fetchPrivateData, setFetchPrivateData] = useState();
  const [privateData, setPrivateData] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const currentEmployeeId = data?.auth?.uid;
  const timeZoneId = data?.employee.timeZoneId;
  const member = data?.members ? data?.members[visit?.member] : undefined;

  useEffect(() => {
    if (!visit) return;
    let retries = 0;

    const fetchPrivateData = async () => {
      setFetchPrivateData(true);
      try {
        console.log("Fetching private data...");

        const privateDataRef = doc(db, "visits", visitId, "private", "data");

        const privateDataSnap = await Promise.race([
          getDoc(privateDataRef),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Timeout")), FETCH_TIMEOUT)
          ),
        ]);

        if (privateDataSnap.exists()) {
          setPrivateData(privateDataSnap.data());
          console.log("Private Data: ", privateDataSnap.data());
        } else {
          console.log("No private data found");
        }
      } catch (error) {
        console.error("Error fetching private data:", error);
        if (retries < MAX_RETRY_ATTEMPTS) {
          retries++;
          console.log(`Retrying fetch... Attempt ${retries}`);
          fetchPrivateData(); // Retry fetching
        } else {
          showSnackbar("Loading failed, please close and refresh", "error");
        }
      } finally {
        setFetchPrivateData(false);
      }
    };

    if (visitId && visit?.employeeArr?.includes(currentEmployeeId)) {
      fetchPrivateData();
    }
  }, [visitId, visit]);

  // Return if no visit set
  if (
    !visit ||
    !visitId ||
    !data.members ||
    Object.keys(data.members).length === 0
  )
    return;

  const handleReviewOpen = () => {
    setShowReview(true);
  };

  const handleReviewClose = () => {
    setShowReview(false);
  };

  // Public address part
  const visitAddressPublic = `${visit?.location?.city}, ${visit?.location?.state} ${visit?.location?.zipCode}`;

  // Private address part with privacy filter
  let visitAddressPrivate = privateData?.address?.line1
    ? `${privateData.address.line1}${
        privateData.address.line2 ? ", " + privateData.address.line2 : ""
      }, `
    : "";

  // Full address combining private and public parts
  const visitAddress = `${visitAddressPrivate}, ${visitAddressPublic}`;

  const isPastEvent = moment().isAfter(moment(visit?.start?.toDate()));

  // After the visit but before midnight
  const isEditable = moment().isBetween(
    moment(visit?.start?.toDate()).tz(visit?.timeZoneId).startOf("day"),
    moment(visit?.start?.toDate()).tz(visit?.timeZoneId).endOf("day"),
    null,
    "()"
  );

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const isReviewed =
    typeof visit?.employees?.[currentEmployeeId]?.rating === "number" &&
    !isNaN(visit?.employees?.[currentEmployeeId]?.rating);

  const handleAddressClick = (address) => {
    let url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;

    // For mobile devices, try to open in native maps app
    if (isMobile) {
      if (navigator.userAgent.indexOf("Android") !== -1) {
        url = `geo:0,0?q=${encodeURIComponent(address)}`;
        window.location.href = url; // Change the current window location
      } else if (
        navigator.userAgent.indexOf("iPhone") !== -1 ||
        navigator.userAgent.indexOf("iPad") !== -1
      ) {
        url = `maps://maps.google.com/maps?daddr=${encodeURIComponent(
          address
        )}&amp;ll=`;
        window.location.href = url; // Change the current window location
      } else {
        window.open(url); // Fallback for other mobile devices
      }
    } else {
      window.open(url, "_blank"); // For non-mobile devices, open in a new tab
    }
  };

  const handlePhoneClick = (phoneNumber) => {
    if (!privateData?.phone) return; // Disable button if phone number is not available

    const formattedNumber = phoneNumber?.replace(/[^+0-9]/g, "") || 5555555555; // removing any non-numeric/non-plus characters
    const telUrl = `tel:${formattedNumber}`;
    window.location.href = telUrl;
    console.log(telUrl);
  };

  const handleEditTime = () => {
    setOpenEditTime(true);
  };

  const handleCopy = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        // Handle success
        console.log("Copy success");
        showSnackbar("Copied to clipboard.", "success");
      })
      .catch((err) => {
        // Handle the error
        console.error("Failed to copy text: ", err);
        showSnackbar("Failed to copy to clipboard.", "error");
      });
  };

  const getEmployeeInfo = (employeeId, visitId) => {
    // Check if the employee's data exists
    const employee = data?.employees?.[employeeId];

    if (employee) {
      // Return actual data if present
      return {
        avatarUrl: employee.avatarUrl,
        firstName: employee.firstName,
        lastName: employee.lastName,
        displayName: `${employee.firstName} ${employee.lastName.charAt(0)}.`,
      };
    } else {
      // Fallback data if the employee's information is not found
      return {
        // avatarUrl: "path/to/your/default/avatar.png", // default avatar image path
        displayName: visit?.employees[employeeId].displayName,
        avatarUrl: visit?.employees[employeeId].avatarUrl,
      };
    }
  };

  const generateOpenPositionPlaceholders = (count) => {
    const placeholders = [];
    for (let i = 0; i < count; i++) {
      placeholders.push({
        displayName: "Open Position",
        status: "waiting",
        avatarUrl: "/path/to/default/avatar.png", // replace with your actual default avatar path
        responseStatus: "needsAction",
      });
    }
    return placeholders;
  };

  // Render different buttons based on the event time and status
  const renderActionButtons = () => {
    const responseStatus =
      visit?.employees?.[currentEmployeeId]?.responseStatus;

    console.log("responseStatus: ", responseStatus);

    const acceptButtonStyles = {
      width: { xs: "50%", sm: "auto" },
      height: { xs: "60px", sm: "auto" },
      backgroundColor:
        responseStatus === "accepted" ? "lightblue" : "transparent",
    };

    const declineButtonStyles = {
      width: { xs: "50%", sm: "auto" },
      height: { xs: "60px", sm: "auto" },
      backgroundColor:
        responseStatus === "declined" ? "lightblue" : "transparent",
    };

    return (
      <DialogActions
        sx={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <ButtonGroup
          fullWidth
          sx={{
            width: { xs: "100%", sm: "auto" },
            height: { xs: "60px", sm: "auto" },
          }}
        >
          <DeclineVisitButton
            responseStatus={responseStatus}
            visit={visit}
            visitId={visitId}
            visits={data?.visits}
            currentEmployeeId={currentEmployeeId}
            customStyles={declineButtonStyles}
            handleDecline={handleDecline}
            loading={loading}
            setLoading={setLoading}
          />
          <AcceptVisitButton
            responseStatus={responseStatus}
            visit={visit}
            visitId={visitId}
            visits={data?.visits}
            currentEmployeeId={currentEmployeeId}
            customStyles={acceptButtonStyles}
            handleAccept={handleAccept}
            loading={loading}
            setLoading={setLoading}
          />
        </ButtonGroup>
      </DialogActions>
    );
  };

  const renderEmployeePositions = (employees, isOpenVisit, employeesNeeded) => {
    let positions = [];

    // If there are actual employees, we render them.
    if (employees) {
      positions = Object.entries(employees)
        .filter(
          ([, employee]) =>
            employee.responseStatus === "needsAction" ||
            employee.responseStatus === "accepted"
        )
        .map(([key, values], index) => {
          const { avatarUrl, firstName, lastName, displayName } =
            getEmployeeInfo(key);
          return (
            <Stack key={key} direction="row" gap={1} alignItems="center">
              <ListItemAvatar>
                <AvatarStatusBadge
                  status={values.responseStatus}
                  avatarUrl={avatarUrl}
                  displayName={firstName}
                  index={index}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${displayName}${
                  key === currentEmployeeId ? " (you)" : ""
                }`}
                secondary={
                  values.responseStatus === "needsAction"
                    ? "needs action"
                    : values.responseStatus
                }
              />
            </Stack>
          );
        });
    }

    // If the visit is open, we add placeholders for the open positions.
    if (isOpenVisit && !isPastEvent) {
      positions = positions.concat(
        generateOpenPositionPlaceholders(employeesNeeded).map(
          (placeholder, index) => (
            <Stack
              key={`open_position_${index}`}
              direction="row"
              gap={1}
              alignItems="center"
            >
              <ListItemAvatar>
                <AvatarStatusBadge
                  status={placeholder.status}
                  avatarUrl={placeholder.avatarUrl} // your actual default avatar path
                />
              </ListItemAvatar>
              <ListItemText
                primary={placeholder.displayName}
                secondary={placeholder.status}
              />
            </Stack>
          )
        )
      );
    }

    return positions;
  };

  const saveWindows = async (localWindows) => {
    setLoading(true);
    console.log("Saving windows: ", localWindows);
    try {
      // Convert localWindows from Moment objects to Firestore Timestamps
      const windowsToSave = {};
      Object.keys(localWindows).forEach((windowId) => {
        windowsToSave[windowId] = {
          start: Timestamp.fromDate(localWindows[windowId].start.toDate()),
          end: Timestamp.fromDate(localWindows[windowId].end.toDate()),
        };
      });

      // Path to the specific document
      const docRef = doc(db, "visits", visitId);

      // Prepare the update object
      const updateData = {
        [`employees.${currentEmployeeId}.windows`]: windowsToSave,
      };

      // Update the document
      await updateDoc(docRef, updateData);
      console.log("Windows saved successfully");
    } catch (error) {
      console.error("Error saving windows: ", error);
    } finally {
      setLoading(false);
      setOpenEditTime(false);
    }
  };

  return (
    <>
      {/* Review the visit  */}

      <ResponsiveDialog
        title={`Visit Feedback`}
        open={showReview}
        onClose={handleReviewClose}
        width="600px"
        fullHeight={true}
        desktopAnchor={"right"}
        showBackdrop={false}
      >
        <ReviewVisitEmployee
          visit={visit}
          visitId={visitId}
          member={member}
          employeeId={currentEmployeeId}
          handleClose={handleReviewClose}
        />
      </ResponsiveDialog>

      {/* Edit the times  */}
      <ResponsiveDialog
        title={"Edit your hours"}
        open={openEditTime}
        onClose={() => setOpenEditTime(false)}
        width={"600px"}
        fullHeight={true}
        anchor={"right"}
      >
        <AdjustTimeWindow
          onSave={saveWindows}
          windows={
            visit?.employees[currentEmployeeId]?.windows ?? {
              "window-1": {
                start: visit?.start,
                end: visit?.end,
              },
            }
          }
          timeZoneId={visit?.timeZoneId}
          name={visit?.displayName}
          loading={loading}
        />
      </ResponsiveDialog>

      <DialogContent>
        {/* Visit detail list  */}
        <List sx={{ marginTop: "-24px" }}>
          <ListItem sx={{ borderRadius: "15px", px: 0 }}>
            <ListItemIcon>
              <EventRounded color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography variant="h6">
                    {formatDate(moment(visit?.start?.toDate()).toDate())}
                  </Typography>
                  <ExpirationChip
                    showTooltip={true}
                    expirationDate={visit?.expiration}
                    timeZoneId={timeZoneId}
                  />
                </>
              }
            />
          </ListItem>

          <Tooltip
            disableHoverListener={isEditable}
            title={"Adjustments can only be made during the visit day."}
            enterTouchDelay={0}
            arrow
            placement="top"
          >
            <ListItemButton
              onClick={() => isEditable && handleEditTime()}
              sx={{
                borderRadius: "15px",
                px: 0,
                // pointerEvents: isEditable ? "auto" : "none", // Disable pointer events if it's a past event
              }}
            >
              <ListItemIcon>
                <AccessTime color="primary" />
              </ListItemIcon>
              <ListItemText
                sx={
                  isEditable
                    ? {
                        borderRadius: "15px",
                        backgroundColor: "grey.100",
                        paddingY: 1,
                        pl: 2,
                      }
                    : {}
                }
                secondary={
                  <Typography variant="body1">
                    {visit?.employees[currentEmployeeId]?.windows
                      ? Object.values(
                          visit?.employees[currentEmployeeId].windows
                        ).map((window, index) => (
                          <React.Fragment key={index}>
                            {moment(window.start.toDate())
                              .tz(timeZoneId)
                              .format("h:mm a")}{" "}
                            -{" "}
                            {moment(window.end.toDate())
                              .tz(timeZoneId)
                              .format("h:mm a")}
                            <br />
                          </React.Fragment>
                        ))
                      : `${moment(visit?.start?.toDate())
                          .tz(timeZoneId)
                          .format("h:mm a")} - ${moment(visit?.end?.toDate())
                          .tz(timeZoneId)
                          .format("h:mm a")}`}
                  </Typography>
                }
              />
              <ListItemSecondaryAction sx={{ pr: isEditable ? 2 : 0 }}>
                <IconButton
                  edge="end"
                  disabled={!isEditable}
                  onClick={(e) => {
                    e.stopPropagation(); // Stop the ListItemButton's onClick from being triggered
                    handleEditTime();
                  }}
                >
                  <Edit />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItemButton>
          </Tooltip>

          {visit?.recurrence?.frequency > 0 && (
            <ListItem sx={{ px: 0 }}>
              <ListItemIcon>
                <EventRepeatOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    {visit?.recurrence.frequency > 1
                      ? `Every ${visit?.recurrence.frequency}-weeks on ${moment(
                          visit?.start?.toDate()
                        ).format("dddd")}`
                      : `Every week on ${moment(visit?.start?.toDate()).format(
                          "dddd"
                        )}`}
                  </Typography>
                }
              />
            </ListItem>
          )}
          <ListItemButton
            onClick={() => handlePhoneClick(privateData?.phone)}
            sx={{ borderRadius: "15px", px: 0 }}
          >
            <ListItemIcon>
              <Person color="primary" />
            </ListItemIcon>
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              sx={{ marginRight: 4 }}
            >
              <ColorAvatar
                name={`${member?.firstName} ${member?.lastName}`}
                avatarUrl={
                  data?.members[visit?.member]?.avatarUrl || visit?.avatarUrl
                }
              />

              <ListItemText
                primary={`${member?.firstName} ${member?.lastName}`}
                secondary={
                  fetchPrivateData ? (
                    <Skeleton variant="text" />
                  ) : (
                    privateData?.phone
                  )
                }
              />
              {privateData?.phone && ( // Render IconButton only if phone number exists
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation(); // Stop the ListItem's onClick from being triggered
                      handleCopy(privateData?.phone);
                    }}
                  >
                    <Call />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </Stack>
          </ListItemButton>
          <ListItemButton
            onClick={() => handleAddressClick(visitAddress)}
            sx={{ borderRadius: "15px", px: 0 }}
          >
            <ListItemIcon>
              <LocationOnOutlined color="primary" />
            </ListItemIcon>
            <ListItemText
              sx={{ marginRight: 4 }}
              primary={
                fetchPrivateData ? (
                  <Skeleton variant="text" />
                ) : (
                  <>
                    {visitAddressPrivate}
                    {visitAddressPublic}
                  </>
                )
              }
            />

            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={(e) => {
                  e.stopPropagation(); // Stop the ListItem's onClick from being triggered
                  handleCopy(visitAddress);
                }}
              >
                <FileCopyOutlined />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemButton>
          {privateData?.address?.directions && (
            <ListItem sx={{ paddingX: "0px" }}>
              <ListItemIcon >
                <DirectionsOutlined color="primary" />
              </ListItemIcon>
              <ListItemText
                sx={{ marginRight: 4 }}
                primary={privateData?.address?.directions}
              />
            </ListItem>
          )}
          <ListItem sx={{ px: 0 }}>
            <ListItemIcon>
              <Notes color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ wordBreak: "break-word" }}>
                  {visit?.description || "No visit notes"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem sx={{ px: 0 }}>
            <ListItemIcon>
              <GroupOutlined color="primary" />
            </ListItemIcon>

            <Stack direction="column" spacing={0}>
              {renderEmployeePositions(
                visit?.employees,
                visit?.isOpenVisit,
                visit?.employeesNeeded
              )}
            </Stack>
          </ListItem>
        </List>
        <VisitTimelineView visit={visit} />
      </DialogContent>

      {/* Actions for past visit  */}
      {isPastEvent ? (
        !isReviewed && (
          <DialogActions
            sx={{
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
          >
            {/* Case 2: Past not reviewed */}
            <Button
              sx={{
                width: { xs: "100%", sm: "auto" },
                height: { xs: "60px", sm: "auto" },
              }}
              disableElevation={!isMobile}
              onClick={handleReviewOpen}
              variant="contained"
              color="primary"
            >
              Feedback
            </Button>
          </DialogActions>
        )
      ) : type === "openVisit" ? (
        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          {/* Case 3: Present open visit */}
          <ButtonGroup fullWidth sx={{ height: { xs: "60px", sm: "auto" } }}>
            <PickupVisitButton
              visit={visit}
              visitId={visitId}
              openVisits={data?.openVisits}
              currentEmployeeId={currentEmployeeId}
              customStyles={{
                width: { xs: "100%", sm: "auto" },
                height: { xs: "60px", sm: "auto" },
              }}
              handlePickup={handlePickup}
              loading={loading}
              setLoading={setLoading}
            />
          </ButtonGroup>
        </DialogActions>
      ) : (
        // Case 4: Present visit
        visit?.employees[currentEmployeeId] != null && renderActionButtons()
      )}

      {/* <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        style={{ position: "absolute", top: 16, right: 16 }}
      >
        <MoreVert />
      </IconButton>

       <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
         <MenuItem
              onClick={handleMenuClose}
              color="danger"
              variant="contained"
            >
              {" "}
              < ListItemIcon sx={{ minWidth: "48px" }}>
                <Edit />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              < ListItemIcon sx={{ minWidth: "48px" }}>
                <HistoryRounded />
              </ListItemIcon>
              <ListItemText primary="Reschedule" />
            </MenuItem>
        <MenuItem
          onClick={() => {
            setShowConfirmDialog(true);
            handleMenuClose(); // Close the menu after cancellation
          }}
        >
          <ListItemIcon sx={{ minWidth: "48px" }}>
            <DeleteForever />
          </ListItemIcon>
          <ListItemText primary="Cancel visit" />
        </MenuItem>
      </Menu> */}
    </>
  );
};

export default EmployeeVisitCard;
